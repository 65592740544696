import * as go from 'gojs';
import { templateFn, CalloutData } from '@/bridge/types/diagramModel';
import { CalloutCategory } from '@/bridge/enums/partCategories';
import { locToGoPoint, pointToNodeLoc, toGoSize } from '@/bridge/util/shared';
import { formatDatetime } from '@/util/date';
import { CommentStatus } from '@/types/comments';
import store from '@/store';
import { DiagramCreateOptions } from '@/bridge/types/diagramOptions';
import { DiagramMode } from '@/bridge/enums/diagramOptions';

type ColorDetails = {
  name: string;
  hex: string;
};

// Colour name and hex from Quasar's colour palette
export const calloutColor: Record<CommentStatus|'myCallout', ColorDetails> = {
  myCallout: {
    name: 'cyan',
    hex: '#00bcd4',
  },
  active: {
    name: 'blue',
    hex: '#2196f3',
  },
  approved: {
    name: 'orange',
    hex: '#ff9800',
  },
};

export const calloutSize = 34;

const $ = go.GraphObject.make;

function dragComputation(part: go.Part, pt: go.Point) {
  const { diagram } = part;
  if (diagram === null) return pt;
  // compute the area inside the viewport
  const v = diagram.viewportBounds.copy();
  if (diagram.padding instanceof go.Margin) {
    v.subtractMargin(diagram.padding);
  }
  // get the bounds of the part being dragged
  const bnd = part.actualBounds;
  const loc = part.location;
  // now limit the location appropriately
  const l = v.x + (loc.x - bnd.x);
  const r = v.right - (bnd.right - loc.x);
  const t = v.y + (loc.y - bnd.y);
  const b = v.bottom - (bnd.bottom - loc.y);
  if (l <= pt.x && pt.x <= r && t <= pt.y && pt.y <= b) return pt;
  const p = pt.copy();
  p.x = Math.max(l, Math.min(p.x, r));
  p.y = Math.max(t, Math.min(p.y, b));
  return p;
}

function calloutTemplate(options: DiagramCreateOptions) {
  return $(go.Node, 'Spot',
    {
      locationSpot: go.Spot.Center,
      toolTip: $('ToolTip',
        $(go.Panel, 'Vertical',
          { padding: 10 },
          $(go.TextBlock, { margin: 4, text: 'Callout Added By' }),
          $(go.TextBlock, { margin: 4, font: 'normal bold 14px sans-serif' },
            new go.Binding('text', 'createdBy')),
          $(go.TextBlock, { margin: 4, font: 'normal 14px sans-serif' },
            new go.Binding('text', 'createdAt', (timestamp: number) => formatDatetime(timestamp))))),
      selectionAdornmentTemplate: $(go.Adornment, 'Spot',
        $(go.Shape, 'Circle',
          {
            width: calloutSize,
            height: calloutSize,
            fill: null,
            stroke: '#3498db',
            strokeWidth: 2,
          }),
        $(go.Placeholder)),
      dragComputation,
    },
    new go.Binding('zOrder', 'zIndex').makeTwoWay(),
    new go.Binding('desiredSize', 'size', toGoSize),
    new go.Binding('location', 'loc', locToGoPoint).makeTwoWay(pointToNodeLoc),
    new go.Binding('visible', 'status', (status: CommentStatus) => {
      if (options.mode !== DiagramMode.REVIEW) {
        return false;
      }
      return status !== 'approved';
    }),
    $(go.Shape, 'Circle',
      {
        stroke: null,
        cursor: 'pointer',
      },
      new go.Binding('fill', '', (data: CalloutData) => {
        if (data.status === 'approved') {
          return calloutColor.approved.hex;
        }
        const user = store.getters['profileModule/user'];
        if (data.createdBy === `${user.forename} ${user.surname}`) {
          return calloutColor.myCallout.hex;
        }
        return calloutColor.active.hex;
      })),
    $(go.TextBlock,
      {
        font: 'bold normal 12px sans-serif',
        stroke: 'white',
      },
      new go.Binding('text', 'label').makeTwoWay()));
}

const addTemplate: templateFn = (diagram: go.Diagram, options: DiagramCreateOptions) => {
  diagram.nodeTemplateMap.add(CalloutCategory.DEFAULT, calloutTemplate(options));
};

export default {
  addTemplate,
};
