
























import Vue from 'vue';
import Btn from '@/components/buttons/Btn.vue';
import ApiService from '@/services/api.service';

export default Vue.extend({
  name: 'UnsavedChangesModal',
  components: { Btn },
  data() {
    return {
      modal: false,
      nextFn: null as null|(() => void),
    };
  },
  methods: {
    async releaseLock() {
      const { diagramId, projectId, versionId } = this.$route.params;
      let url = `/project/${projectId}/diagrams/${diagramId}/unlock`;
      if (versionId) {
        url = `${url}?versionId=${versionId}`;
      }

      try {
        await ApiService.put(url);
      } catch (e) {
        console.log('could not release lock');
      }
    },
    open(nextFn?: () => void) {
      this.modal = true;
      this.nextFn = nextFn || null;
    },
    leave() {
      this.$store.dispatch('diagramModule/clear');
      this.releaseLock();
      window.onbeforeunload = null;

      if (this.nextFn) {
        this.nextFn();
      }
    },
  },
});
