<template>
  <q-item
    :dense="dense"
    class="cursor-inherit details-card-row q-pl-none"
  >
    <q-item-section v-if="hasLeftSlot" class="text-bold col-5" top>
      <slot name="left"></slot>
    </q-item-section>
    <q-item-section v-if="hasCenterSlot" top>
      <slot name="center"></slot>
    </q-item-section>
    <q-item-section v-if="hasRightSlot" class="text-center" top>
      <slot name="right"></slot>
    </q-item-section>
  </q-item>
</template>

<script>
export default {
  name: 'DetailsCardRow',
  props: {
    dense: Boolean,
  },
  computed: {
    hasLeftSlot() {
      return !!this.$slots.left;
    },
    hasCenterSlot() {
      return !!this.$slots.center;
    },
    hasRightSlot() {
      return !!this.$slots.right;
    },
  },
};
</script>

<style>
.details-card-row {
  min-height: 25px;
}
</style>
