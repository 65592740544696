







import { Diagram } from 'gojs';
import Vue, { PropType } from 'vue';
import { initLegend, destroyLegend, addLegendModel } from '@/bridge/legend/initLegend';
import { diagramEvents } from '@/bridge/events/diagramEvents';
import PageSpinner from '@/components/PageSpinner.vue';
import { DiagramMode } from '@/bridge/enums/diagramOptions';

export default Vue.extend({
  name: 'DiagramLegend',
  components: { PageSpinner },
  props: {
    mode: {
      type: Number as PropType<DiagramMode>,
      default: DiagramMode.VIEW,
    },
    readonly: Boolean,
    cssStyle: {
      type: Object,
      default() {
        return { width: '100%', height: '60vh' };
      },
    },
    legendDivId: {
      type: String,
      default: 'legendCanvas',
    },
    diagramDivId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      legend: null as null|Diagram,
    };
  },
  methods: {
    updateLegend() {
      if (this.legend) {
        addLegendModel(this.legend, this.diagramDivId, this.mode);
      }
    },
  },
  async mounted() {
    setTimeout(async () => {
      this.loading = false;
      this.legend = await initLegend(this.legendDivId, this.diagramDivId, this.mode);

      this.$emit('loaded');

      diagramEvents.$on(diagramEvents.UPDATE_LEGEND_MODEL, this.updateLegend);
    }, 100);
  },
  async beforeDestroy() {
    if (this.legend) {
      diagramEvents.$off(diagramEvents.UPDATE_LEGEND_MODEL, this.updateLegend);

      await destroyLegend(this.legend);
      this.legend = null;
      this.$emit('destroyed');
    }
  },
});
