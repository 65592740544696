













































import Vue, { PropType } from 'vue';
import { Resource } from '@/types/resource';
import { DiagramType } from '@/bridge/enums/diagramOptions';
import ViewResource from '@/views/resources/ViewResource.vue';
import Btn from '@/components/buttons/Btn.vue';
import AppModal from '@/components/AppModal.vue';
import ApiService from '@/services/api.service';
import ApiError from '@/components/ApiError.vue';
import ListDiagrams from '@/views/diagram/ListDiagrams.vue';

enum Tabs {
  Asset = 'asset',
  Bits = 'bits',
  Davs = 'davs',
}

export default Vue.extend({
  name: 'ViewResourceModal',
  components: { ListDiagrams, ApiError, AppModal, Btn, ViewResource },
  props: {
    readonly: Boolean,
    resource: {
      type: Object as PropType<Resource>,
      required: false,
    },
    resourceId: {
      type: String,
      required: false,
    },
  },
  computed: {
    title(): string {
      if (this.resource) {
        return `View ${this.resource.type}`;
      }
      return 'View';
    },
  },
  data() {
    return {
      error: '',
      tabs: Tabs,
      selectedTab: Tabs.Asset,
      diagramType: DiagramType,
      selectedResource: null as null|Resource,
    };
  },
  methods: {
    async open() {
      if (!this.resource && !this.resourceId) {
        return;
      }

      this.error = '';

      if (this.resource) {
        this.selectedResource = this.resource;
      } else {
        try {
          this.selectedResource = await ApiService.get(`/project/${this.$route.params.projectId}/assets/${this.resourceId}`);
        } catch {
          this.error = 'Could not load this resource. Please try again later.';
        }
      }
      (this.$refs.modal as any).open();
    },
  },
});
