<template>
  <q-btn
    flat
    dense
    no-caps
    :size="size"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <q-tooltip v-if="tooltip && !$attrs.disable">
      {{tooltip}}
    </q-tooltip>
  </q-btn>
</template>

<script>
export default {
  name: 'DiagramToolbarBtn',
  props: {
    tooltip: {
      type: String,
      required: false,
    },
    size: {
      type: String,
      default: 'xs',
    },
  },
};
</script>
