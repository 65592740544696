import Vue from 'vue';

export default Vue.extend({
  props: {
    disable: Boolean,
    field: {
      type: Object,
      required: true,
    },
  },
});
