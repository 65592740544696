import * as go from 'gojs';
import { AllDiagramSettings } from '@/bridge/types/diagramModel';
import { defaultLayerLabelHeight } from '@/bridge/poolLayout/layerTemplate';

const $ = go.GraphObject.make;

export default function gridTemplate(modelData: AllDiagramSettings) {
  const gridCellSize = new go.Size(modelData.diagram.gridWidth, modelData.diagram.gridHeight);

  return $(
    go.Panel,
    'Grid',
    {
      gridCellSize,
      // start grid after the layer label
      gridOrigin: new go.Point(
        defaultLayerLabelHeight + 1,
        0,
      ),
      visible: modelData.diagram.gridVisible,
    },
    $(go.Shape, 'LineH', { stroke: modelData.diagram.gridColor }),
    $(go.Shape, 'LineV', { stroke: modelData.diagram.gridColor }),
  );
}
