<template>
  <app-modal ref="modal" title="Are you sure?" persistent>
    <div>
      <p v-if="!isField" class="text-italic">
        This will remove the form and all its contents.
      </p>
      <small class="text-caption">This action cannot be undone</small>
    </div>

    <template #footer>
      <q-space/>
      <Btn flat label="Cancel" class="q-mr-md" @click="cancel"/>
      <Btn color="primary" label="Confirm" @click="confirm"/>
    </template>
  </app-modal>
</template>

<script>
import Btn from '@/components/buttons/Btn.vue';
import AppModal from '@/components/AppModal.vue';

export default {
  name: 'ConfirmRemoveForm',
  components: {
    AppModal,
    Btn,
  },
  props: {
    isField: Boolean,
  },
  data() {
    return {
      details: {},
    };
  },
  methods: {
    open(details) {
      this.details = details;
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
      this.details = {};
    },
    confirm() {
      this.$emit('confirm', this.details);
      this.close();
    },
    cancel() {
      this.$emit('cancel', this.details);
      this.close();
    },
  },
};
</script>
