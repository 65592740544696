






























import { Diagram } from 'gojs';
import Vue from 'vue';
import DiagramToolbarBtn from '@/views/diagram/toolbar/DiagramToolbarBtn.vue';
import DiagramOverview from '@/views/diagram/toolbar/DiagramOverview.vue';

export default Vue.extend({
  name: 'DiagramZoomSlider',
  components: { DiagramOverview, DiagramToolbarBtn },
  computed: {
    zoomDisplayValue(): string {
      return `${Math.floor(this.zoomModel * 100)}%`;
    },
  },
  data() {
    return {
      diagram: null as Diagram|null,
      zoomOptions: {
        max: 4,
        min: 0.05,
        step: 0.01,
      },
      zoomModel: 1,
    };
  },
  methods: {
    zoomToFit() {
      if (this.diagram) {
        this.diagram.zoomToFit();
        this.zoomModel = this.diagram.scale;
      }
    },
    onZoom(scale: number) {
      if (this.diagram) {
        this.diagram.scale = scale;
      }
    },
  },
  mounted() {
    this.diagram = Diagram.fromDiv(this.$store.getters['diagramModule/diagramDivId']);
    if (this.diagram) {
      this.zoomModel = this.diagram.scale;
    }
  },
});
