








import { Diagram } from 'gojs';
import Vue, { PropType } from 'vue';
import { DiagramCheckpoint } from '@/types/diagram';
import { DiagramMode, DiagramType } from '@/bridge/enums/diagramOptions';
import { initCanvas, destroyCanvas } from '@/bridge/base/initCanvas';

export default Vue.extend({
  name: 'DiagramCanvas',
  props: {
    reviewMode: Boolean,
    readonly: Boolean,
    checkpoint: {
      type: Object as PropType<DiagramCheckpoint>,
      required: true,
    },
    diagramDivId: {
      type: String,
      default: 'diagramDiv',
    },
    cssStyle: {
      type: Object,
      default() {
        return { width: '100%', height: '100%' };
      },
    },
    diagramType: {
      type: String as PropType<DiagramType>,
      default: DiagramType.BIT,
    },
  },
  data() {
    return {
      error: '',
      loading: false,
      diagram: {} as Diagram,
    };
  },
  async mounted() {
    let mode: DiagramMode = DiagramMode.MODEL;
    if (this.reviewMode) {
      mode = DiagramMode.REVIEW;
    }
    if (this.readonly) {
      mode = DiagramMode.VIEW;
    }
    this.diagram = await initCanvas(
      {
        mode,
        id: this.$route.params.diagramId,
        type: this.diagramType,
        selector: this.diagramDivId,
      },
      this.checkpoint,
    );

    this.$emit('loaded');
  },
  async beforeDestroy() {
    this.$emit('destroyed');
    await destroyCanvas(this.diagramDivId);
  },
});
