





































































import Vue, { PropType } from 'vue';
import { CanvasSelection } from '@/bridge/types/canvasSelection';
import { ElementData, LinkData } from '@/bridge/types/diagramModel';
import { Resource, ResourceType } from '@/types/resource';
import SelectAsset from '@/views/assets/SelectAsset.vue';
import ApiService from '@/services/api.service';
import Btn from '@/components/buttons/Btn.vue';
import { NodeCategory, LinkCategory } from '@/bridge/enums/partCategories';
import { getConnectorAssets } from '@/views/bit/linkDrawnUtils';
import ViewResourceModal from '@/views/resources/ViewResourceModal.vue';

export default Vue.extend({
  name: 'PartAsset',
  components: {
    Btn,
    SelectAsset,
    ViewResourceModal,
  },
  props: {
    readonly: Boolean,
    isDav: Boolean,
    selected: {
      type: Object as PropType<CanvasSelection>,
      required: true,
    },
  },
  computed: {
    resourceType(): ResourceType {
      if (this.isElement) {
        return ResourceType.ASSET;
      }
      if (this.partData.category === LinkCategory.CONNECTION) {
        return ResourceType.CONNECTION;
      }
      return ResourceType.DEPENDENCY;
    },
    isElement(): boolean {
      return this.selected.selection.every((p) => p.category === NodeCategory.ELEMENT);
    },
    emptyMessage(): string {
      const [first] = this.selected.selection;
      let msg = `This ${first.category} doesn't have an asset`;
      if (this.isElement) {
        return msg;
      }
      if (!this.extraParams.assetFrom) {
        msg = `${msg}, (the From element is empty)`;
      }
      if (!this.extraParams.assetTo) {
        msg = `${msg}, (the To element is empty)`;
      }
      return msg;
    },
    layerFilter(): string {
      if (this.isElement) {
        return `${(this.partData as ElementData).group}`;
      }
      return '';
    },
    extraParams(): Record<string, any> {
      if (this.isElement) {
        return {};
      }
      const assets = getConnectorAssets(this.selected.selection[0]);
      return {
        assetFrom: assets.from,
        assetTo: assets.to,
      };
    },
    partData(): ElementData|LinkData {
      return this.selected.selection[0].data;
    },
    partAsset(): string {
      const [part] = this.selected.selection;

      if (!part || !part.data) {
        return '';
      }

      if (part.data.assetId) {
        return part.data.assetId;
      }

      return '';
    },
    linkHasAssets(): boolean {
      return (this.extraParams.assetFrom !== '' && this.extraParams.assetTo !== '');
    },
  },
  data() {
    return {
      asset: null as null|Resource,
      error: '',
      loading: false,
    };
  },
  watch: {
    // eslint-disable-next-line func-names
    'selected.selection': function () {
      this.setPart();
    },
  },
  methods: {
    async removeAsset() {
      this.loading = true;
      this.$emit('save');
      this.asset = null;
      this.loading = false;
    },
    async updateAsset() {
      if (this.asset) {
        await this.loadAsset(this.asset.id);
        this.addAsset(false);
      }
    },
    addAsset(emitLinkDrawn = true) {
      this.loading = true;
      this.$emit('save', {
        emitLinkDrawn,
        asset: this.asset,
      });
      this.loading = false;
    },
    canSelectAsset(): boolean {
      // this function will compute if the resource can have an asset
      // the cases we want to hide the select asset dropdown is when
      // either of the From or To doesn't have an Asset attached, and we
      // select a connection.
      if (this.isElement) {
        return this.partData && !this.partAsset;
      }
      return this.linkHasAssets;
    },
    async assetSelected(selected: { id: string; }|null) {
      if (!selected) {
        this.asset = null;
        return;
      }
      await this.loadAsset(selected.id);
    },
    async assetReplaced(selected: { id: string; }|null) {
      if (!selected) {
        return;
      }
      try {
        await this.loadAsset(selected.id);
      } catch (e: any) {
        this.error = e.message();
      }

      this.addAsset(true);
    },
    async setPart() {
      this.asset = null;

      if (this.partAsset) {
        await this.loadAsset(this.partAsset);
      }
    },
    async loadAsset(id: string) {
      this.loading = true;
      this.error = '';

      try {
        this.asset = await ApiService.get(`/project/${this.$route.params.projectId}/assets/${id}`);
      } catch {
        this.error = 'Could not load this asset. Please try again later';
      }
      this.loading = false;
    },
  },
  async created() {
    await this.setPart();
  },
});
