import * as go from 'gojs';
import { diagramEvents } from '@/bridge/events/diagramEvents';
import { NodeCategory } from '@/bridge/enums/partCategories';
import { reLayoutDiagram } from '@/bridge/util/shared';
import { addLinkDrawnEvent } from '@/bridge/events/LinkDrawnEvent';

export type NodeResizedEvent = {
  part: go.Part;
  width: number;
  height: number;
}

export function addBitEvents(diagram: go.Diagram) {
  addLinkDrawnEvent(diagram);

  diagram.addDiagramListener('SelectionMoved', (e) => {
    reLayoutDiagram(e.diagram);
  });

  diagram.addDiagramListener('PartResized', (e) => {
    const { category } = e.subject.part?.data;
    if (category !== NodeCategory.LAYER && category !== NodeCategory.ROOT) {
      diagramEvents.$emit(diagramEvents.PART_RESIZED, {
        part: e.subject.part,
        width: e.subject.width,
        height: e.subject.height,
      } as NodeResizedEvent);
    }
  });

  diagram.mouseDrop = (e) => {
    if (!e.diagram.selection.all((n) => n instanceof go.Group)) {
      e.diagram.currentTool.doCancel();
      (e.diagram.toolManager.draggingTool as any).clearGuidelines();
    }
    reLayoutDiagram(e.diagram);
  };
}
