

























































































































import Vue, { PropType } from 'vue';
import { Diagram } from 'gojs';
import { DiagramModel } from '@/types/diagram';
import { printSizeDetails, ExportOptions, PrintSize } from '@/bridge/print/printUtils';
import { AllDiagramSettings, NodePreset } from '@/bridge/types/diagramModel';
import { diagramEvents } from '@/bridge/events/diagramEvents';
import { isShape } from '@/bridge/settings/shapeSettings';
import { getDefaultModelData } from '@/bridge/settings/modelData';
import Btn from '@/components/buttons/Btn.vue';
import FieldCheckbox from '@/components/fields/FieldCheckbox.vue';
import { DiagramType } from '@/bridge/enums/diagramOptions';

type PrintSizeSelectOption = { label: string; value: PrintSize; description: string; };

export default Vue.extend({
  name: 'ExportDiagramOptions',
  props: {
    diagramDivId: {
      type: String,
      required: true,
    },
    diagramType: {
      type: String as PropType<DiagramType>,
      default: DiagramType.BIT,
    },
  },
  components: {
    Btn,
    FieldCheckbox,
  },
  computed: {
    printSizeSelectOptions(): PrintSizeSelectOption[] {
      return Object
        .keys(printSizeDetails)
        .filter((ps) => ps !== 'SVG')
        .map((ps) => {
          const tmp = printSizeDetails[ps as PrintSize];
          return {
            label: ps,
            value: ps as PrintSize,
            description: `${tmp.width} x ${tmp.height} @300dpi`,
          };
        });
    },
    canSelectPrintSize(): boolean {
      return ['png', 'jpeg', 'pdf'].includes(this.exportOptions.fileType);
    },
    fileTypeOptions(): string[] {
      const opt = [
        'pdf',
        'png',
        'jpeg',
        'svg',
        'json',
      ];
      if (this.diagramType === DiagramType.BIT) {
        return opt;
      }
      return opt.slice(0, opt.length - 1);
    },
  },
  data() {
    const tmp = printSizeDetails.A4;

    return {
      printSize: {
        label: 'A4',
        value: 'A4',
        description: `${tmp.width} x ${tmp.height} @300dpi`,
      },
      diagramDetails: null as null | DiagramModel,
      nodePresets: null as null | Record<string, NodePreset>,
      diagramSettings: null as null | AllDiagramSettings,
      loading: false,
      exportOptions: {
        shapes: true,
        legend: true,
        connections: true,
        dependencies: true,
        label: true,
        code: false,
        status: true,
        description: false,
        presets: true,
        defaultLayerColor: false,
        diagramDivId: '',
        legendDivId: '',
        printSize: 'A4',
        fileType: 'pdf',
      } as ExportOptions,
      error: '',
      diagram: null as null | Diagram,
    };
  },
  methods: {
    onPrintSizeSelect(e: PrintSizeSelectOption) {
      this.exportOptions.printSize = e.value;
    },
    toggleLinks(type: 'connection' | 'dependency') {
      if (!this.diagram) return;

      const md = this.diagram.model.modelData as AllDiagramSettings;
      const conn = { ...md[type] };
      const show = this.exportOptions[type === 'connection' ? 'connections' : 'dependencies'];
      conn.visible = show;
      this.diagram.model.set(md, type, conn);

      if (this.diagramSettings) {
        this.diagramSettings[type].visible = show;
      }
    },
    toggleShapes() {
      if (!this.diagram) return;

      this.diagram.nodes
        .filter((n) => isShape(n.category))
        .each((s) => s.visible = this.exportOptions.shapes);
    },
    toggleLegend() {
      if (this.diagram) {
        this.diagram.requestUpdate();
      }
      this.$emit('toggleLegend', this.exportOptions.legend);
    },
    togglePresets() {
      if (!this.diagram) return;

      const md = this.diagram.model.modelData as AllDiagramSettings;
      if (this.exportOptions.presets) {
        this.diagram.model.set(md, 'nodePresets', this.nodePresets);
      } else {
        this.diagram.model.set(md, 'nodePresets', {});
      }
      diagramEvents.$emit(diagramEvents.UPDATE_LEGEND_MODEL);
    },
    toggleDefaultLayerColor() {
      if (!this.diagram) return;

      if (this.exportOptions.defaultLayerColor) {
        const md = {
          ...this.diagramSettings,
          layers: getDefaultModelData().layers,
          nodePresets: {},
        };
        if (this.exportOptions.presets) {
          md.nodePresets = { ...this.nodePresets };
        }
        this.diagram.model.modelData = md;
      } else if (this.exportOptions.presets) {
        this.diagram.model.modelData = { ...this.diagramSettings };
      } else {
        this.diagram.model.modelData = {
          ...this.diagramSettings,
          nodePresets: {},
        };
      }

      diagramEvents.$emit(diagramEvents.UPDATE_LEGEND_MODEL);
    },
    emitExport() {
      this.loading = true;
      this.$emit('export', this.exportOptions);
    },
  },
  created() {
    this.diagram = Diagram.fromDiv(this.diagramDivId);
    this.diagramDetails = this.$store.getters['diagramModule/details'];
    // Cache the setting and presets so we can toggle them
    if (this.diagramDetails) {
      this.diagramSettings = { ...this.diagramDetails.version.checkpoint.model.modelData };

      if (!this.diagramSettings.connection.visible) {
        this.exportOptions.connections = false;
      }
      if (!this.diagramSettings.dependency.visible) {
        this.exportOptions.dependencies = false;
      }
      if (this.diagramSettings.nodePresets) {
        this.nodePresets = { ...this.diagramSettings.nodePresets };
      }
    }
  },
});
