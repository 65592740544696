import * as go from 'gojs';
import EventBus from '@/util/EventBus';
import {
  LayerIndex,
  LayerData,
  DavCandidateAsset,
  ElementData,
  DavCandidateConnector,
  LinkData,
} from '@/bridge/types/diagramModel';
import { reLayoutDiagram, isTopLevelCategory } from '@/bridge/util/shared';
import { NodeCategory, LinkCategory } from '@/bridge/enums/partCategories';
import { MinLength, MinBreadth } from '@/bridge/poolLayout/PoolLayout';

type DrawDavOptions = {
  mode: 'compressed'|'expanded';
  elementWidth: number;
  fontSize: number;
  zoom: number;
}

type LayerBounds = Record<LayerIndex, go.Rect>;

const layers: LayerIndex[] = [0, 1, 2, 3, 4, 5];

function getLayerBounds(diagram: go.Diagram, key: LayerIndex): go.Rect {
  const layer = diagram.findNodeForKey(key) as go.Group;
  const { size, loc } = layer.data as LayerData;

  return new go.Rect(loc.x, loc.y, size.width, size.height);
}

function getDrawOptions(): DrawDavOptions {
  return {
    mode: 'compressed',
    elementWidth: 140,
    fontSize: 16,
    zoom: 1,
  };
}

function is(data: ElementData) {
  function compare(prop: keyof ElementData) {
    return {
      below(...els: ElementData[]) {
        return els.every((d) => {
          if (prop === 'loc') {
            return data.loc.y > d.loc.y;
          }
          if (prop === 'group') {
            return data.group > d.group;
          }
          return false;
        });
      },
      above(...els: ElementData[]) {
        return els.every((d) => {
          if (prop === 'loc') {
            return data.loc.y < d.loc.y;
          }
          if (prop === 'group') {
            return data.group < d.group;
          }
          return false;
        });
      },
      belowOrAbove(...els: ElementData[]) {
        return this.below(...els) || this.above(...els);
      },
    };
  }

  function layerEquality() {
    return {
      equal(...els: ElementData[]) {
        return els.every((d) => data.group === d.group);
      },
      differentY(...els: ElementData[]) {
        return new Set(els.map((d) => d.group)).size === [data, ...els].length;
      },
    };
  }

  function locEquality() {
    return {
      equalY(...els: ElementData[]) {
        return els.every((d) => data.loc.y === d.loc.y);
      },
      differentY(...els: ElementData[]) {
        return new Set(els.map((d) => d.group)).size === [data, ...els].length;
      },
    };
  }

  return {
    loc: {
      ...compare('loc'),
      ...locEquality(),
    },
    layer: {
      ...compare('group'),
      ...layerEquality(),
    },
  };
}

function defaultLayerBounds() {
  const lb = {} as LayerBounds;
  layers.forEach((layer) => {
    lb[layer] = new go.Rect();
  });

  return lb;
}

function sameLayer(...data: ElementData[]) {
  return data.every((d) => d.group === data[0].group);
}

function differentLayers(...data: ElementData[]) {
  return new Set(data.map((d) => d.group)).size === data.length;
}

export function lockLayers(d: go.Diagram) {
  d.nodes
    .each((n) => {
      if (isTopLevelCategory(n.category)) {
        n.copyable = false;
        n.deletable = false;
        n.movable = false;
        n.resizable = false;
      }
    });
}

function lockElement(p: go.Part) {
  if (p.category === NodeCategory.ELEMENT) {
    p.copyable = false;
    p.deletable = false;
    p.movable = false;
    p.resizable = false;
  }
}

function lockLink(l: go.Link) {
  l.copyable = false;
  (l as any).canShift = false;
  l.deletable = false;
  l.movable = false;
  l.resegmentable = false;
}

export function lockParts(d: go.Diagram) {
  d.nodes
    .filter((p) => !isTopLevelCategory(p.category))
    .each(lockElement);

  d.links.each(lockLink);
}

export class Dav extends EventBus {
  // THE FIRST IDS ARE USED BY LAYERS
  private NODE_ID_OFFSET = 7;

  private layerBounds: Record<LayerIndex, go.Rect> = defaultLayerBounds();
  private nodes: ElementData[] = [];
  private links: LinkData[] = [];

  diagram: go.Diagram;
  drawOptions: DrawDavOptions = getDrawOptions();
  isModified = false;

  constructor(diagram: go.Diagram, drawOptions?: DrawDavOptions) {
    super();

    if (drawOptions) {
      this.drawOptions = drawOptions;
    }

    this.diagram = diagram;
    this.diagram.skipsUndoManager = true;

    layers.forEach((layer) => {
      this.layerBounds[layer] = getLayerBounds(this.diagram, layer);
    });

    lockLayers(this.diagram);
  }

  private get nodeWidth() {
    return this.diagram.grid.gridCellSize.width * 12;
  }

  private get nodeHeight() {
    return this.diagram.grid.gridCellSize.height * 4;
  }

  private get vGap() {
    return this.diagram.grid.gridCellSize.height * 2;
  }

  private get hGap() {
    return this.diagram.grid.gridCellSize.width * 2;
  }

  get davAssets(): ElementData[] {
    return this.nodes;
  }

  get davConnectors(): LinkData[] {
    return this.links;
  }

  get lastNode(): ElementData|undefined {
    return this.nodes[this.nodes.length - 1];
  }

  private createNodeData(asset: DavCandidateAsset): ElementData {
    return {
      id: this.NODE_ID_OFFSET + this.nodes.length,
      label: asset.label,
      assetId: asset.id,
      davId: asset.davId,
      group: asset.layer,
      preset: null,
      category: NodeCategory.ELEMENT,
      zIndex: '1',
      size: {
        width: this.nodeWidth,
        height: this.nodeHeight,
      },
      loc: {
        x: 0,
        y: 0,
      },
    };
  }

  private createLinkData(connector: DavCandidateConnector, to: ElementData): LinkData {
    const from = this.lastNode as ElementData;

    return {
      // Make sure it's a negative number
      // to avoid conflicts with node keys
      id: (this.links.length + 1) * -1,
      assetId: connector.id,
      from: from.id,
      to: to.id,
      davId: connector.davId,
      label: connector.label,
      type: connector.type,
      category: LinkCategory.CONNECTION,
    };
  }

  private setInitialLocation(data: ElementData) {
    const lb = this.layerBounds[data.group];
    const prev = this.nodes[this.nodes.indexOf(data) - 1];

    data.size.width = this.nodeWidth;
    data.size.height = this.nodeHeight;

    if (!prev) {
      // Add the first node
      data.loc.x = lb.left + this.hGap;
      data.loc.y = lb.bottom - this.nodeHeight - this.vGap;
      return;
    }

    if (prev.group === data.group) {
      // Add nodes on same layers O-O
      data.loc.x = prev.loc.x + this.nodeWidth + this.hGap;
      data.loc.y = prev.loc.y;
      return;
    }

    // Add nodes on different layers O-B
    data.loc.x = prev.loc.x;
    data.loc.y = lb.bottom - this.nodeHeight - this.vGap;
  }

  private addNode(node: ElementData, link?: LinkData) {
    this.nodes.push(node);

    const len = this.nodes.length;
    this.setInitialLocation(node);

    if (link) {
      this.links.push(link);
    }

    if (len <= 2) return;

    // The count is done in reverse order from the end.
    const first = this.nodes[len - 1];
    const second = this.nodes[len - 2];
    const third = this.nodes[len - 3];

    if (len === 3) {
      if (sameLayer(first, second, third)) {
        this.groupDoubleUp(first, second);
      }

      // Ex: B-O-B ... S-O-B ... H-B-S
      const doubleUp = first.group < second.group && third.group < second.group;
      // Ex: O-B-O ... O-S-B ... B-H-S
      const doubleDown = first.group > second.group && third.group > second.group;
      if (doubleUp || doubleDown) {
        this.groupDoubleDown(first, second, third);
      }
    }

    if (len > 3) {
      const fourth = this.nodes[len - 4];

      if (sameLayer(first, second, third, fourth) && this.drawOptions.mode === 'compressed') {
        this.groupDoubleDown(first, second);
        if (is(second).loc.below(third)) {
          this.move(first, 'up');
        }
        return;
      }

      if (sameLayer(first, second, third)) {
        // B-O-O-O
        if (is(fourth).layer.below(third)) {
          this.groupDoubleUp(first, second);
          return;
        }
        // O-B-B-B
        if (is(fourth).layer.above(third)) {
          this.doubleSize(third);
          this.doubleSize(second);
          this.move(second, 'up');
          return;
        }
      }

      // B-B-A-B
      if (sameLayer(first, third, fourth) && differentLayers(first, second)) {
        this.doubleSize(second);
        this.move(first, 'right');
        return;
      }

      if (
        differentLayers(first, second)
        && is(second).layer.belowOrAbove(first, third)
      ) {
        // EX: H-H-B-A (NOT S-S-H-H)
        this.doubleSize(second);
        this.move(first, 'right');
        return;
      }

      if (sameLayer(second, third, fourth) && is(first).layer.belowOrAbove(second)) {
        // B-B-B-A
        if (
          is(fourth).loc.equalY(second)
          && is(third).loc.above(second)
          && is(first).loc.below(second)
        ) {
          return;
        }

        // A-A-A-A-O
        if (
          is(fourth).loc.equalY(second)
          && is(third).loc.below(second)
          && is(first).loc.above(second)
        ) {
          return;
        }

        // B-B-B-O or O-O-O-O-B or O-B-B-B-A
        this.groupDoubleDown(first, second);
      }
    }
  }

  private groupDoubleDown(...nodes: ElementData[]) {
    const [first, second] = nodes;
    const lb = this.layerBounds[first.group];

    this.doubleSize(second);

    first.loc.x = second.loc.x + this.nodeWidth + this.hGap;
    first.loc.y = lb.bottom - this.nodeHeight - this.vGap;
  }

  private groupDoubleUp(...nodes: ElementData[]) {
    const [first, second] = nodes;

    this.doubleSize(second);
    this.move(second, 'up', 'left');
    this.move(first, 'left');
  }

  private move(data: ElementData, ...where: ('up'|'down'|'left'|'right')[]) {
    const { nodeWidth, nodeHeight } = this;

    const direction = where.shift();

    switch (direction) {
      case 'up':
        data.loc.y -= (nodeHeight + this.vGap);
        break;
      case 'down':
        data.loc.y += (nodeHeight + this.vGap);
        break;
      case 'left':
        data.loc.x -= (nodeWidth + this.hGap);
        break;
      case 'right':
        data.loc.x += (nodeWidth + this.hGap);
        break;
      default:
        break;
    }
    if (where.length) {
      this.move(data, ...where);
    }
  }

  private doubleSize(e: ElementData) {
    e.size.width = this.nodeWidth * 2 + this.hGap;
    return this;
  }

  // Load existing DAVs
  async loadData(nodes: ElementData[], links: LinkData[]) {
    this.clear();
    this.nodes = nodes;
    this.links = links;

    this.updateModel();
  }

  // When the nodes are removed we need to re-assign ids
  // to avoid conflicts or more complex id generation algorithms
  async draw(nodes: ElementData[], links: LinkData[]) {
    this.clear();
    nodes.forEach((node, index) => {
      node.id = index + this.NODE_ID_OFFSET;
      const link = links[index - 1];
      if (link) {
        link.from = node.id - 1;
        link.to = node.id;
        link.id = (index + 1) * -1;
      }
      this.addNode(node, link);
    });

    this.updateModel();
  }

  private updateModel() {
    this.diagram.model.addNodeDataCollection(this.nodes);
    (this.diagram.model as go.GraphLinksModel).addLinkDataCollection(this.links);
    reLayoutDiagram(this.diagram);

    if (this.nodes.length) {
      const n = this.diagram.findNodeForKey(this.lastNode?.id);
      if (n) this.diagram.scrollToRect(n.actualBounds);
    }

    lockParts(this.diagram);
    this.isModified = true;
  }

  add(asset: DavCandidateAsset, connector?: DavCandidateConnector) {
    const node = this.createNodeData(asset);
    let link;
    if (connector && this.nodes.length) {
      link = this.createLinkData(connector, node);
    }

    this.addNode(node, link);

    const len = this.nodes.length;

    if (this.nodes[len - 1]) {
      this.diagram.model.addNodeData(this.nodes[len - 1]);
    }

    if (len >= 2) {
      const l = this.links[this.links.length - 1];
      if (l) {
        (this.diagram.model as go.GraphLinksModel).addLinkData(l);
      }
    }

    // We need to update the last 4 nodes
    // in case their location/size changed
    for (let i = 1; i <= 6; i += 1) {
      const data = this.nodes[len - i];
      if (data) {
        const n = this.diagram.findNodeForKey(data.id);
        if (n) {
          this.diagram.model.set(n.data, 'loc', data.loc);
          this.diagram.model.set(n.data, 'size', data.size);
          n.updateTargetBindings();
        }
      }
    }

    reLayoutDiagram(this.diagram);

    if (this.nodes.length) {
      const lastNode = this.diagram.findNodeForKey(this.lastNode?.id);
      if (lastNode) this.diagram.scrollToRect(lastNode.actualBounds);
    }

    lockParts(this.diagram);
    this.isModified = true;
  }

  clear() {
    this.nodes = [];
    this.links = [];

    const els = this.diagram.nodes.filter((n) => n.data.category === NodeCategory.ELEMENT);
    this.diagram.removeParts(els, false);

    this.isModified = true;
  }

  findSequence(data: ElementData): ElementData[] {
    if (!data.davId) return [data];

    function searchLeftRight(davId: string, index: number, nodes: ElementData[]) {
      const acc: any[] = [];
      let i;

      // Search left
      i = index - 1;
      while (nodes[i] && nodes[i].davId === davId) {
        acc.push(nodes[i]);

        i -= 1;
      }

      // Search right
      i = index + 1;
      while (nodes[i] && nodes[i].davId === davId) {
        acc.push(nodes[i]);

        i += 1;
      }

      return acc;
    }

    return [data, ...searchLeftRight(data.davId, this.nodes.indexOf(data), this.nodes)];
  }

  highlight(data: ElementData) {
    const node = this.diagram.findNodeForKey(data.id);
    if (node) {
      node.isHighlighted = true;
    }
  }

  highlightSequence(data: ElementData) {
    // find sub-dav-elements
    const relatedEls = this.findSequence(data);

    if (!relatedEls.length) {
      return;
    }

    relatedEls.forEach(this.highlight);
  }

  private updateLayerSize() {
    if (!this.nodes.length) {
      this.diagram
        .findNodesByExample({ category: NodeCategory.LAYER })
        .each((l) => {
          this.diagram.model.set(l.data, 'size', {
            width: MinLength,
            height: MinBreadth,
          });
        });
    } else {
      if (!this.lastNode) return;

      const lb = getLayerBounds(this.diagram, this.lastNode.group);
      if (!lb) {
        return;
      }
      const nodeRight = this.lastNode.loc.x + this.lastNode.size.width + this.vGap;

      if (lb.right > MinLength && nodeRight > MinLength) {
        this.diagram
          .findNodesByExample({ category: NodeCategory.LAYER })
          .each((l) => {
            this.diagram.model.set(l.data, 'size', {
              width: nodeRight,
              height: l.data.height,
            });
          });
      }
    }
  }

  unlinkSubDav(data: ElementData) {
    if (!data.davId) return;

    this.findSequence(data).forEach((a) => a.davId = '');
  }

  async removeFrom(data: ElementData, direction: 'left'|'right' = 'right') {
    this.unlinkSubDav(data);
    const index = this.nodes.indexOf(data);

    if (direction === 'right') {
      this.nodes = this.nodes.slice(0, index);
      this.links = this.links.slice(0, index - 1);
    } else {
      this.nodes = this.nodes.slice(index + 1);
      this.links = this.links.slice(index + 1);
    }

    await this.draw(this.nodes, this.links);
    this.updateLayerSize();
  }

  async removeLast() {
    if (this.lastNode) {
      this.unlinkSubDav(this.lastNode);
      await this.removeFrom(this.lastNode, 'right');
    }
  }
}
