import * as go from 'gojs';
import { NodeCategory } from '@/bridge/enums/partCategories';

/* eslint-disable */
function excludeLayers(p: go.Part): boolean {
  const { category } = p.data;
  p.isSelected = false;
  return category !== NodeCategory.LAYER;
}

export default class RealtimeDragSelectingTool extends go.DragSelectingTool {
  _originalSelection: go.Set<go.Part> | null = null;

  _temporarySelection: go.Set<go.Part> | null = null;

  /**
   * Remember the original collection of selected Parts.
   */
  doActivate() {
    go.DragSelectingTool.prototype.doActivate.call(this);
    // keep a copy of the original Set of selected Parts
    this._originalSelection = this.diagram.selection.copy();
    // these Part.isSelected may have been temporarily modified
    this._temporarySelection = new go.Set();
    this.diagram.raiseDiagramEvent('ChangingSelection');
  }

  /**
   * Release any references to selected Parts.
   */
  doDeactivate() {
    this.diagram.raiseDiagramEvent('ChangedSelection');
    this._originalSelection = null;
    this._temporarySelection = null;
    go.DragSelectingTool.prototype.doDeactivate.call(this);
  }

  /**
   * Restore the selection which may have been modified during a drag.
   */
  doCancel() {
    const orig = this._originalSelection;
    if (orig && this._temporarySelection) {
      orig.each((p) => {
        p.isSelected = true;
      });

      this._temporarySelection.each((p) => {
        if (!orig.contains(p)) p.isSelected = false;
      });
    }
    go.DragSelectingTool.prototype.doCancel.call(this);
  }

  doMouseMove() {
    if (this.isActive) {
      go.DragSelectingTool.prototype.doMouseMove.call(this);
      this.selectInRect(this.computeBoxBounds());
    }
  }

  doKeyDown() {
    if (this.isActive) {
      go.DragSelectingTool.prototype.doKeyDown.call(this);
      this.selectInRect(this.computeBoxBounds());
    }
  }

  doKeyUp() {
    if (this.isActive) {
      go.DragSelectingTool.prototype.doKeyUp.call(this);
      this.selectInRect(this.computeBoxBounds());
    }
  }

  selectInRect(r: go.Rect) {
    const diagram = this.diagram as go.Diagram;
    const orig = this._originalSelection;
    const temp = this._temporarySelection;

    if (!diagram || !orig || !temp) return;

    const e = diagram.lastInput;
    // @ts-ignore
    const found = diagram.findPartsIn(
      r,
      this.isPartialInclusion,
      true,
      new go.Set<any>(),
    );
    if (e.control || e.meta) {
      // toggle or deselect
      if (e.shift) {
        // deselect only
        temp.each((p) => {
          if (!found.contains(p)) p.isSelected = orig.contains(p);
        });
        found.filter(excludeLayers).each((p) => {
          p.isSelected = false;
          temp.add(p);
        });
      } else {
        // toggle selectedness of parts based on _originalSelection
        temp.each((p) => {
          if (!found.contains(p)) p.isSelected = orig.contains(p);
        });
        found.filter(excludeLayers).each((p) => {
          p.isSelected = !orig.contains(p);
          temp.add(p);
        });
      }
    } else if (e.shift) {
      // extend selection only
      temp.each((p) => {
        if (!found.contains(p)) p.isSelected = orig.contains(p);
      });
      found.filter(excludeLayers).each((p) => {
        p.isSelected = true;
        temp.add(p);
      });
    } else {
      // select found parts, and unselect all other previously selected parts
      temp.each((p) => {
        if (!found.contains(p)) p.isSelected = false;
      });
      orig.each((p) => {
        if (!found.contains(p)) p.isSelected = false;
      });
      found.filter(excludeLayers).each((p) => {
        p.isSelected = true;
        temp.add(p);
      });
    }
  }

  canStart() {
    if (!this.isEnabled) return false;
    const diagram = this.diagram as go.Diagram;

    if (diagram === null || !diagram.allowSelect) return false;
    const e = diagram.lastInput;
    // require left button & that it has moved far enough away from the mouse down point,
    // so it isn't a click
    if (!e.left) return false;
    // don't include the following checks when this tool is running modally
    if (diagram.currentTool !== this) {
      if (!this.isBeyondDragSize()) return false;
      // must wait for "delay" milliseconds before that tool can run
      if (e.timestamp - diagram.firstInput.timestamp < this.delay) return false;
    }
    return true;
  }
}
/* eslint-disable */
