import * as go from 'gojs';
import { AllDiagramSettings } from '@/bridge/types/diagramModel';
import { getNodeTextStyle } from '@/bridge/node/textStyle';
import { layerColor } from '@/bridge/settings/layerSettings';
import { elementColor } from '@/bridge/settings/elementSettings';

const $ = go.GraphObject.make;

export function legendNodeTemplate(desiredSize: go.Size, isLayer = false) {
  let colorResolver = elementColor;
  if (isLayer) {
    colorResolver = layerColor;
  }

  return $(go.Part, 'Auto',
    $(go.Panel, 'Auto',
      { desiredSize },
      new go.Binding(
        'background',
        '',
        (md: AllDiagramSettings, p: go.Part) => colorResolver(p.part as go.Part).borderColor,
      ).ofModel(),
      $(
        go.TextBlock,
        getNodeTextStyle(),
        { margin: 2 },
        new go.Binding(
          'background',
          '',
          (md: AllDiagramSettings, p: go.Part) => colorResolver(p.part as go.Part).backgroundColor,
        ).ofModel(),
        new go.Binding(
          'stroke',
          '',
          (md: AllDiagramSettings, p: go.Part) => colorResolver(p.part as go.Part).textColor,
        ).ofModel(),
      )));
}
