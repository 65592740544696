
































import Vue, { PropType } from 'vue';
import { DavCandidateAsset, DavCandidateConnector } from '@/bridge/types/diagramModel';
import { ResourceType } from '@/types/resource';
import ApiService from '@/services/api.service';
import Btn from '@/components/buttons/Btn.vue';
import ListTooltip from '@/components/list/ListTooltip.vue';

async function createConnector(
  url: string,
  from: DavCandidateAsset,
  to: DavCandidateAsset,
): Promise<DavCandidateConnector|null> {
  // Same or adjacent layers
  const canCreateConnection = from.layer === to.layer
    || from.layer + 1 === to.layer
    || from.layer - 1 === to.layer;

  const connectorData = {
    label: `${from.label} is connected with ${to.label}`,
    description: '',
    from: from.id,
    to: to.id,
    type: ResourceType.CONNECTION,
  };

  if (!canCreateConnection) {
    connectorData.label = `${from.label} is dependent on ${to.label}`;
    connectorData.type = ResourceType.DEPENDENCY;
  }

  try {
    const id = await ApiService.post(url);

    return ApiService.get(`${url}/${id}`);
  } catch {
    return null;
  }
}

export default Vue.extend({
  name: 'SelectCandidateConnector',
  components: {
    Btn,
    ListTooltip,
  },
  props: {
    selectedAssets: {
      type: Array as PropType<DavCandidateAsset[]>,
      required: true,
    },
    selectedCandidate: {
      type: Object as PropType<DavCandidateAsset>,
      required: true,
    },
  },
  methods: {
    onSelectConnector(connector: DavCandidateConnector|null) {
      this.$emit('select', connector);
    },
  },
  async created() {
    const { links } = this.selectedCandidate;

    if (this.selectedAssets.length && links && !links.length) {
      const connector = await createConnector(
        `/project/${this.$route.params.projectId}/asset`,
        this.selectedAssets[this.selectedAssets.length - 1],
        this.selectedCandidate,
      );

      this.onSelectConnector(connector);
    }
  },
});
