import * as go from 'gojs';
import { NodeCategory } from '@/bridge/enums/partCategories';
import commonGroupStyle from '@/bridge/poolLayout/commonGroupStyle';
import PoolLayout from '@/bridge/poolLayout/PoolLayout';
import { AllDiagramSettings, templateFn } from '@/bridge/types/diagramModel';

const $ = go.GraphObject.make;

export function getDiagramPadding(md: AllDiagramSettings) {
  const { gridWidth, gridHeight, rulerVisible } = md.diagram;
  if (!rulerVisible) {
    return 0;
  }

  let w = gridWidth;
  let h = gridHeight;

  if (gridWidth < 20) {
    w = 2 * gridWidth;
  }
  if (gridHeight < 20) {
    h = 2 * gridHeight;
  }

  return new go.Margin(h, w);
}

function poolGroupTemplate(modelData: AllDiagramSettings) {
  return $(
    go.Group,
    'Auto',
    commonGroupStyle(),
    {
      padding: getDiagramPadding(modelData),
      layout: $(PoolLayout, { spacing: new go.Size(0, 0) }), // no space between lanes
      selectionAdorned: false,
    },
    $(go.Placeholder, { column: 1 }),
  );
}

const addTemplate: templateFn = (diagram: go.Diagram, options, modelData) => {
  diagram.groupTemplateMap.add(NodeCategory.ROOT, poolGroupTemplate(modelData));
};

export default {
  addTemplate,
};
