





























































































import Vue, { PropType } from 'vue';
import { settingsService } from '@/services/preferencesService';
import { getDefaultModelData } from '@/bridge/settings/modelData';
import { getDiagramFromDiv } from '@/bridge/util/shared';
import { clearDeletedPresets } from '@/bridge/bit/partCustomisation';
import { AllDiagramSettings as AllSettings, NodePreset } from '@/bridge/types/diagramModel';
import Btn from '@/components/buttons/Btn.vue';
import AppModal from '@/components/AppModal.vue';
import AllDiagramSettings from '@/components/diagram/AllDiagramSettings.vue';
import FieldCheckbox from '@/components/fields/FieldCheckbox.vue';
import ListNodePresets from '@/components/diagram/presets/ListNodePresets.vue';
import DiagramToolbarBtn from '@/views/diagram/toolbar/DiagramToolbarBtn.vue';
import { DiagramType } from '@/bridge/enums/diagramOptions';
import UseProjectSettingsWarning from '@/views/diagram/UseProjectSettingsWarning.vue';

export default Vue.extend({
  name: 'DiagramSettingsForm',
  components: {
    UseProjectSettingsWarning,
    DiagramToolbarBtn,
    ListNodePresets,
    FieldCheckbox,
    Btn,
    AppModal,
    AllDiagramSettings,
  },
  props: {
    diagramType: {
      type: String as PropType<DiagramType>,
      default: DiagramType.BIT,
    },
    settings: {
      type: Object as PropType<AllSettings>,
      required: true,
    },
    projectSettings: {
      type: Object as PropType<AllSettings>,
      required: true,
    },
  },
  data() {
    return {
      tab: 'diagram',
      model: {} as AllSettings,
      prefService: {} as ReturnType<typeof settingsService>,
      deletedPresets: [] as string[],
    };
  },
  methods: {
    async open() {
      await this.setDefaultModel();
      (this.$refs.modal as any).open();
    },
    openConfirmModal() {
      if (this.$refs.useProjectSettingsWarning) {
        (this.$refs.useProjectSettingsWarning as any).open();
      }
    },
    async onSubmit() {
      if (this.prefService.settingsChanged(this.model)) {
        this.model.meta.hasLocalSettings = true;
      }

      this.$emit('submit', this.model);
      (this.$refs.modal as any).close();
      this.clearDeletedPresets();
    },
    async setDefaultModel() {
      this.model = { ...this.settings };
      this.prefService = settingsService(this.settings);
    },
    onClose() {
      this.model = {} as AllSettings;
      this.prefService = {} as ReturnType<typeof settingsService>;
    },
    sync() {
      this.model = { ...this.projectSettings };
    },
    updatePresets(e: Record<string, NodePreset>) {
      this.model.nodePresets = e;
    },
    onDeletePreset(e: { id: string, presets: Record<string, NodePreset>}) {
      this.deletedPresets.push(e.id);
      this.model.nodePresets = e.presets;
    },
    clearDeletedPresets() {
      const d = getDiagramFromDiv(this.$store.state.diagramModule.diagramDivId);
      if (!d) return;

      clearDeletedPresets(d, this.deletedPresets);
      this.deletedPresets = [];
    },
    updatePreferences(e: AllSettings) {
      this.model = e;
    },
    resetSettings(useProjectSettings: boolean) {
      let m: AllSettings;
      if (useProjectSettings) {
        m = { ...this.projectSettings };
      } else {
        m = {
          ...getDefaultModelData(),
          nodePresets: this.model.nodePresets,
        };
      }

      m.nextNodeId = this.model.nextNodeId;
      m.nextLinkId = this.model.nextLinkId;
      m.meta = this.model.meta;

      this.model = { ...m };
      this.onSubmit();
    },
  },
});
