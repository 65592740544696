import * as go from 'gojs';
import {
  ExportDiagramData,
  getScale,
  getExportedParts,
  createDownloadLink,
  printSizeDetails,
} from '@/bridge/print/printUtils';

const svgPrintSize = 'SVG';
const { width, height, partition } = printSizeDetails[svgPrintSize];

function getPartition(data: ExportDiagramData, isLegend = false) {
  // Get the partition for the legend;
  if (isLegend) {
    return partition.legend;
  }

  // Get partition for a diagram when the legend is also exported;
  if (data.exportOptions.legend) {
    return partition.diagram;
  }

  // Span the entire width;
  return 1;
}

function exportSvg(data: ExportDiagramData, isLegend = false) {
  const { diagram, legend } = data;
  const goDiagram = isLegend ? legend : diagram;
  const p = getPartition(data, isLegend);

  const imageOptions: any = {
    size: new go.Size(width * p, width),
    maxSize: new go.Size(width * p, width),
    scale: getScale(goDiagram, svgPrintSize, p),
  };

  if (!isLegend) {
    imageOptions.parts = getExportedParts(data);

    return diagram.makeSvg(imageOptions);
  }

  return legend.makeSvg(imageOptions);
}

function addLabelSvg(label: string, coordinates: any) {
  const newText = document.createElementNS('http://www.w3.org/2000/svg', 'text');

  newText.setAttributeNS(null, 'x', coordinates.x);
  newText.setAttributeNS(null, 'y', coordinates.y);

  const textNode = document.createTextNode(label);

  newText.appendChild(textNode);

  return newText;
}

function addDiagramLabel(label: string, coordinates: any, finalSvg: any, fontsize: number) {
  const newText = addLabelSvg(label, coordinates);

  newText.setAttribute('style', `font-size: ${fontsize}px; font-family:"DIN Next LT Pro", Arial, sans-serif;font-weight: bold;`);
  finalSvg.appendChild(newText);
}

function addDiagramNumber(label: string, coordinates: any, finalSvg: any, fontSize: number) {
  const newText = addLabelSvg(label, coordinates);

  newText.setAttribute('style', `font-size: ${fontSize}px; font-family:"DIN Next LT Pro", Arial, sans-serif;`);
  finalSvg.appendChild(newText);
}

function addDiagramToSvg(diagramSvg: any, finalSvg: any, xOffset = 0, yOffset = 100) {
  const svgNodes: any = Array.from(diagramSvg.childNodes);

  svgNodes[0].setAttribute('style', `transform: translate(${xOffset}px, ${yOffset}px)`);

  for (let i = 0; i < svgNodes.length; i += 1) {
    finalSvg.appendChild(svgNodes[i]);
  }
}

function prepareForExport(data: ExportDiagramData) {
  const finalSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');

  finalSvg.setAttribute('style', `width: ${width}px; height: ${height}px;`);
  finalSvg.setAttribute('viewBox', `0 0 ${width} ${height}`);

  const labelFontSize = 25;
  const codeFontSize = 14;
  const leftOffset = 10;
  let diagramTopOffset = 50;
  const gutter = 20;

  if (data.exportOptions.label) {
    addDiagramLabel(
      data.diagramDetails.label,
      { x: leftOffset, y: labelFontSize },
      finalSvg,
      labelFontSize,
    );
  }

  if (data.exportOptions.code && data.diagramDetails.code) {
    diagramTopOffset += codeFontSize + gutter;
    addDiagramNumber(
      `Diagram Code: ${data.diagramDetails.code}`,
      { x: leftOffset, y: labelFontSize + codeFontSize + gutter },
      finalSvg,
      codeFontSize,
    );
  }
  addDiagramToSvg(
    exportSvg(data),
    finalSvg,
    leftOffset,
    diagramTopOffset,
  );

  if (data.exportOptions.legend) {
    addDiagramToSvg(
      exportSvg(data, true),
      finalSvg,
      Math.ceil(width * getPartition(data) + 2 * gutter),
      diagramTopOffset,
    );
  }

  return finalSvg;
}

function serializeSvg(svg: any) {
  const serializer = new XMLSerializer();
  let source = serializer.serializeToString(svg);

  // add name spaces.
  if (!source.match(/^<svg[^>]+xmlns="http:\/\/www\.w3\.org\/2000\/svg"/)) {
    source = source.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"');
  }
  if (!source.match(/^<svg[^>]+"http:\/\/www\.w3\.org\/1999\/xlink"/)) {
    source = source.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
  }

  // add xml declaration
  source = `<?xml version="1.0" standalone="no"?>\r\n${source}`;

  return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(source)}`;
}

export function downloadSvg(data: ExportDiagramData) {
  const svg = serializeSvg(prepareForExport(data));
  createDownloadLink(`${data.diagramDetails.label}.svg`, svg);
}
