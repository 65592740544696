<template>
  <FieldNumeric
    :value="field.value || 0"
    @input="$emit('input', $event)"
    :disable="disable"
    :label="field.label"
    :min-value="field.minValue"
    :max-value="field.maxValue"
    :decimal-places="field.decimalPlaces"
    :required="field.required"
  />
</template>

<script>
import FieldNumeric from '@/components/fields/FieldNumeric.vue';
import formInputPreviewMixin from '@/components/formInputPreview/formInputPreviewMixin';

export default {
  name: 'FormInputPreviewText',
  mixins: [formInputPreviewMixin],
  components: {
    FieldNumeric,
  },
};
</script>
