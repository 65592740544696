import * as go from 'gojs';
import { diagramEvents } from '@/bridge/events/diagramEvents';
import autoSave from '@/bridge/base/AutoSave';
import { CalloutCategory, NodeCategory } from '@/bridge/enums/partCategories';
import { CommentStatus, UpdateCalloutData } from '@/types/comments';
import { isTopLevelCategory } from '@/bridge/util/shared';

export function addCalloutEvents(diagram: go.Diagram) {
  diagramEvents.$on(diagramEvents.SELECT_CALLOUT, (commentId: string) => {
    diagram.select(diagram.findNodesByExample({ commentId }).first());
  });

  diagramEvents.$on(diagramEvents.DELETE_CALLOUT, (commentId: string) => {
    const oldSkips = diagram.skipsUndoManager;
    diagram.skipsUndoManager = true;
    const node = diagram.findNodesByExample({ commentId }).first();
    if (node) {
      diagram.remove(node);
      autoSave.emitSave();
    }
    diagram.skipsUndoManager = oldSkips;
  });

  diagramEvents.$on(diagramEvents.TOGGLE_APPROVED_CALLOUTS, (showResolved: boolean) => {
    const oldSkips = diagram.skipsUndoManager;
    diagram.skipsUndoManager = true;
    const nodes = diagram.findNodesByExample({
      category: CalloutCategory.DEFAULT,
    });
    if (nodes.count) {
      nodes.each((n) => {
        if (n.data.status as CommentStatus === 'approved') {
          n.visible = showResolved;
        }
      });
    }
    diagram.skipsUndoManager = oldSkips;
  });

  diagramEvents.$on(diagramEvents.UPDATE_CALLOUT, (data: UpdateCalloutData) => {
    const node = diagram.findNodesByExample({
      commentId: data.commentId,
      category: CalloutCategory.DEFAULT,
    }).first();
    if (node) {
      diagram.commit(() => {
        Object.keys(data).forEach((key) => {
          diagram.model.set(node.data, key, data[key as keyof UpdateCalloutData]);
        });
      }, null);

      autoSave.emitSave();
    }
  });

  diagramEvents.$on(diagramEvents.NODE_DRAG, () => {
    diagram.nodes.each((n) => {
      if (isTopLevelCategory(n.category) || n.category === NodeCategory.ELEMENT) {
        return;
      }
      n.layerName = 'Grid';
    });
  });
}
