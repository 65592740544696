






import Vue, { PropType } from 'vue';
import { DiagramStatus } from '@/bridge/enums/diagramOptions';
import { diagramStatusColor } from '@/components/diagramStatus/diagramStatusUtils';

export default Vue.extend({
  name: 'DiagramStatusText',
  props: {
    diagramStatus: {
      type: String as PropType<DiagramStatus>,
      required: true,
    },
  },
  computed: {
    color(): string {
      return diagramStatusColor(this.diagramStatus);
    },
  },
});
