import * as go from 'gojs';
import {
  ExportDiagramData,
  getScale,
  getExportedParts,
  createDownloadLink,
  printSizeDetails,
} from '@/bridge/print/printUtils';

type ConcatenateImageOptions = {
  diagramImg: HTMLImageElement | null;
  legendImg?: HTMLImageElement | null;
  logoImg?: HTMLImageElement | null;
}

export async function getImageFromDiagram(
  data: ExportDiagramData,
): Promise<HTMLImageElement|null> {
  const { diagram, exportOptions } = data;
  const { width, height, partition } = printSizeDetails[exportOptions.printSize];

  const image = diagram.makeImage({
    background: 'white',
    size: new go.Size(width * partition.diagram, height),
    maxSize: new go.Size(width * partition.diagram, height),
    scale: getScale(diagram, exportOptions.printSize, partition.diagram),
    parts: getExportedParts(data),
  });

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(image);
    }, 300);
  });
}

export async function getImageFromLegend(
  data: ExportDiagramData,
): Promise<HTMLImageElement|null> {
  const { legend, exportOptions } = data;
  const { width, height, partition } = printSizeDetails[exportOptions.printSize];

  const image = legend.makeImage({
    background: 'white',
    size: new go.Size(width * partition.legend, height),
    maxSize: new go.Size(width * partition.legend, height),
    scale: getScale(legend, exportOptions.printSize, partition.legend),
  });

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(image);
    }, 300);
  });
}

export async function concatenateImages(data: ExportDiagramData, images: ConcatenateImageOptions) {
  const { exportOptions, diagramDetails } = data;
  const { width, height, partition } = printSizeDetails[exportOptions.printSize];
  const {
    diagramImg,
    legendImg,
    logoImg,
  } = images;

  if (!diagramImg) throw new Error('Diagram image is required');

  const canvas = document.createElement('canvas');

  canvas.width = width;
  canvas.height = height;

  const ctx = canvas.getContext('2d');
  if (ctx) {
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    // Reset fill style
    ctx.fillStyle = 'black';
    const headerHeight = 150;
    const labelFontSize = 45;
    const diagramNumberFontSize = 30;
    const diagramDescriptionFontSize = 22;
    const gutter = 45;
    let topOffset = headerHeight + gutter;
    let leftOffset = 5;

    ctx.font = `bold ${labelFontSize}pt DIN Next LT Pro`;

    if (logoImg) {
      leftOffset = 300 + 2 * gutter;
      ctx.drawImage(logoImg, gutter, gutter);
      ctx.fillText(diagramDetails.label, leftOffset, headerHeight - labelFontSize);
    } else {
      topOffset = gutter * 2;
      ctx.fillText(diagramDetails.label, leftOffset, topOffset - 10);
    }

    if (exportOptions.code && diagramDetails.code) {
      topOffset *= 1.5;
      ctx.font = `normal ${diagramNumberFontSize}pt DIN Next LT Pro`;

      ctx.fillText(`Diagram Number: ${diagramDetails.code}`, leftOffset, topOffset);
    }

    if (exportOptions.description && diagramDetails.description) {
      topOffset *= 1.5;
      ctx.font = `normal ${diagramDescriptionFontSize}pt DIN Next LT Pro`;

      ctx.fillText(`${diagramDetails.description}`, leftOffset, topOffset);
    }

    ctx.drawImage(diagramImg, leftOffset, topOffset + 10, diagramImg.width, canvas.height - gutter);

    if (legendImg) {
      const legendX = canvas.width * partition.diagram;

      ctx.drawImage(legendImg, legendX, topOffset + 10, legendImg.width, canvas.height - gutter);
    }
  }

  return canvas.toDataURL(exportOptions.fileType === 'jpeg' ? 'image/jpeg' : 'image/png');
}

export async function createImage(data: ExportDiagramData) {
  const { exportOptions } = data;
  const diagram = go.Diagram.fromDiv(exportOptions.diagramDivId);
  const legend = go.Diagram.fromDiv(exportOptions.legendDivId);

  if (!diagram) return '';

  const diagramImg = await getImageFromDiagram(data);

  if (!legend) {
    return concatenateImages(data, { diagramImg });
  }

  const legendImg = await getImageFromLegend(data);

  return concatenateImages(data, { diagramImg, legendImg });
}

export async function downloadImage(data: ExportDiagramData) {
  const image = await createImage(data);

  if (!image) return;

  const { diagramDetails, exportOptions } = data;

  fetch(image)
    .then((res) => res.blob())
    .then((blob) => {
      const url = URL.createObjectURL(blob);
      createDownloadLink(`${diagramDetails.label}.${exportOptions.fileType}`, url);
      URL.revokeObjectURL(url);
    });
}
