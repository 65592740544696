import * as go from 'gojs';
import LaneResizingTool from '@/bridge/tools/LaneResizingTool';
import LinkReshapingTool from '@/bridge/tools/LinkReshapingTool';
import GuidedDraggingTool from '@/bridge/tools/GuidedDraggingTool';
import dragSelectionTemplate from '@/bridge/base/dragSelectionTemplate';
import gridTemplate from '@/bridge/base/gridTemplate';
import { AllDiagramSettings, CalloutData } from '@/bridge/types/diagramModel';
import { reLayoutDiagram } from '@/bridge/util/shared';
import { DrawCommandHandler } from '@/bridge/tools/DrawCommandHandler';
import { ReviewModeCommandHandler } from '@/bridge/tools/ReviewModeCommandHandler';
import { CalloutCategory } from '@/bridge/enums/partCategories';
import ApiService from '@/services/api.service';
import { diagramEvents } from '@/bridge/events/diagramEvents';
import { AddCalloutData } from '@/types/comments';
import notify from '@/util/notify';

function getNextCalloutLabel(diagram: go.Diagram) {
  const tmp: number[] = [];
  const callouts = diagram.findNodesByExample({ category: CalloutCategory.DEFAULT });

  if (!callouts.count) {
    return 1;
  }

  callouts.each((n) => tmp.push(n.data.label));

  if (!tmp.length) {
    return 1;
  }
  tmp.sort((a, b) => a - b);

  let label = 1;
  for (let i = 0; i < tmp.length; i += 1) {
    if (tmp[i] <= label) {
      label += 1;
    }
  }

  return label;
}

export function getViewOptions(isReadOnly = true) {
  return {
    isReadOnly,
    scrollMode: go.Diagram.DocumentScroll,
    contentAlignment: go.Spot.TopLeft,
    initialAutoScale: go.Diagram.UniformToFill,
    'animationManager.isEnabled': false,
  };
}

export function getReviewOptions(modelData: AllDiagramSettings) {
  return {
    ...getViewOptions(false),
    allowLink: false,
    allowReshape: false,
    allowDragOut: false,
    maxSelectionCount: 1,
    'undoManager.isEnabled': true,
    'toolManager.hoverDelay': 100,
    commandHandler: go.GraphObject.make(ReviewModeCommandHandler),
    scrollMargin: new go.Margin(0, 100, 100, 0),
    grid: gridTemplate(modelData),
    ObjectSingleClicked(e: go.DiagramEvent) {
      if (e.diagram && (e.diagram as any)._addCallout) {
        const details: AddCalloutData|null = (e.diagram as any)._addCallout;
        const pt = e.diagram.lastInput.documentPoint;

        if (!details) return;

        const calloutSize = 30;

        const oldSkips = e.diagram.skipsUndoManager;
        e.diagram.skipsUndoManager = true;

        const nextCalloutLabel = getNextCalloutLabel(e.diagram);
        const c = {
          visible: true,
          commentId: details.commentId,
          status: details.status,
          createdBy: details.createdBy,
          createdAt: details.createdAt,
          label: nextCalloutLabel,
          size: {
            width: calloutSize,
            height: calloutSize,
          },
          loc: {
            x: pt.x,
            y: pt.y,
          },
          category: CalloutCategory.DEFAULT,
        } as Partial<CalloutData>;

        // if the callout is coming from a guest user
        // then we can't call the auto save endpoint
        // we need to send the callout object with the update
        let url = `/project/${details.projectId}/diagrams/${details.diagramId}/versions/comments`;
        if (details.isGuest) {
          url = `/org/${details.orgId}/shared/diagrams/${details.diagramId}/versions/comments`;
        }

        // Add the callout on the diagram
        e.diagram.skipsUndoManager = oldSkips;

        // The callout was added - we can now update the comment and save the diagram
        ApiService.put(
          url,
          {
            callout: nextCalloutLabel,
            versionId: details.versionId,
            id: details.commentId,
            calloutBody: c,
          },
        )
          .then(() => {
            e.diagram.model.addNodeData(c);
            diagramEvents.$emit(diagramEvents.CALLOUT_CREATED);
          })
          .catch(() => {
            notify.danger('Could not create a callout for the comment');
          })
          .finally(() => {
            (e.diagram as any)._addCallout = null;
            document.body.classList.remove('cursor-add-callout');
          });
      }
    },
    SelectionDeleted(e: go.DiagramEvent) {
      const it = e.subject.iterator as go.Iterator<go.Part>;
      const part = it.first();

      if (part && part.category === CalloutCategory.DEFAULT) {
        diagramEvents.$emit(diagramEvents.CALLOUT_DELETED, part);
      }
    },
  };
}

export function getModelOptions(modelData: AllDiagramSettings) {
  return {
    ...getViewOptions(false),
    allowDragOut: true,
    'undoManager.isEnabled': true,
    scrollMargin: new go.Margin(0, 100, 100, 0),
    // default tools
    linkReshapingTool: LinkReshapingTool,
    draggingTool: GuidedDraggingTool,
    resizingTool: LaneResizingTool,
    // a clipboard copied node is pasted into the original node's group (i.e. layer).
    'draggingTool.horizontalGuidelineColor': modelData.diagram.guidesColor,
    'draggingTool.verticalGuidelineColor': modelData.diagram.guidesColor,
    'draggingTool.centerGuidelineColor': modelData.diagram.guidesColor,
    'draggingTool.guidelineWidth': modelData.diagram.guidesVisible ? 1 : 0,
    'draggingTool.isGridSnapEnabled': modelData.diagram.gridSnap,
    'resizingTool.isGridSnapEnabled': modelData.diagram.gridSnap,
    'toolManager.dragSelectingTool': dragSelectionTemplate(),
    'toolManager.hoverDelay': 100,
    commandHandler: go.GraphObject.make(DrawCommandHandler),
    'commandHandler.copiesGroupKey': true,
    grid: gridTemplate(modelData),
    mouseDrop(e: go.InputEvent) {
      e.diagram.currentTool.doCancel();
      (e.diagram.toolManager.draggingTool as any).clearGuidelines();
      reLayoutDiagram(e.diagram);
    },
  };
}

export function getDavModelOptions() {
  return {
    ...getViewOptions(false),
    scrollMargin: new go.Margin(0, 100, 100, 0),
    'undoManager.isEnabled': true,
    'toolManager.hoverDelay': 100,

    allowLink: false,
    allowReshape: false,
    allowDragOut: false,
    maxSelectionCount: 1,
    commandHandler: go.GraphObject.make(ReviewModeCommandHandler),
  };
}
