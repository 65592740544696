<template>
  <FieldTime
    :value="field.value"
    @input="$emit('input', $event)"
    :label="field.label"
    :disable="disable"
    :required="field.required"
  />
</template>

<script>
import FieldTime from '@/components/fields/FieldTime.vue';
import formInputPreviewMixin from '@/components/formInputPreview/formInputPreviewMixin';

export default {
  name: 'FormInputPreviewTime',
  mixins: [formInputPreviewMixin],
  components: {
    FieldTime,
  },
};
</script>
