import * as go from 'gojs';
import { DiagramModel } from '@/types/diagram';
import { ExportOptions, ExportDiagramData } from '@/bridge/print/printUtils';
import { downloadSvg } from '@/bridge/print/downloadSvg';
import { downloadPDF } from '@/bridge/print/downloadPdf';
import { downloadJson } from '@/bridge/print/downloadJson';
import { downloadImage } from '@/bridge/print/downloadImage';

export async function downloadDiagram(exportOptions: ExportOptions, diagramDetails: DiagramModel) {
  const diagram = go.Diagram.fromDiv(exportOptions.diagramDivId) as go.Diagram;
  const legend = go.Diagram.fromDiv(exportOptions.legendDivId) as go.Diagram;
  if (!diagram) {
    throw new Error('Could not get the diagram');
  }
  if (exportOptions.legend && !legend) {
    throw new Error('Could not get the legend');
  }

  const data: ExportDiagramData = {
    legend,
    diagram,
    exportOptions,
    diagramDetails,
  };

  return new Promise((resolve) => {
    setTimeout(async () => {
      switch (exportOptions.fileType) {
        case 'svg':
          await downloadSvg(data);
          break;
        case 'pdf':
          await downloadPDF(data);
          break;
        case 'json':
          downloadJson(data);
          break;
        default:
          await downloadImage(data);
          break;
      }

      resolve(true);
    }, 100);
  });
}
