import * as go from 'gojs';
import { AllDiagramSettings } from '@/bridge/types/diagramModel';

const $ = go.GraphObject.make;

export default function commonLinkingToolInit(
  tool: go.LinkingTool | go.RelinkingTool,
  md: AllDiagramSettings,
) {
  tool.temporaryLink = $(
    go.Link, { layerName: 'Tool' },
    $(
      go.Shape,
      {
        stroke: md.connection.borderColor,
        strokeWidth: md.connection.borderWidth,
      },
    ),
  );

  (tool.temporaryFromPort as go.Shape).fill = md.diagram.portHoverColor;
  (tool.temporaryFromPort as go.Shape).stroke = null;
  (tool.temporaryToPort as go.Shape).fill = md.diagram.portHoverColor;
  (tool.temporaryToPort as go.Shape).stroke = null;
}
