import * as go from 'gojs';

export class ReviewModeCommandHandler extends go.CommandHandler {
  // Normally, you can disable the UndoManager in the diagram options,
  // but there is a strange behavior if it is disabled.
  // When you click on a Part, `diagram.isModified` is set to true
  // which incorrectly implies that the model changed.
  public undo() {
    this.stopCommand();
  }

  public redo() {
    this.stopCommand();
  }

  public copySelection() {
    this.stopCommand();
  }

  public pasteSelection() {
    this.stopCommand();
  }

  public cutSelection() {
    this.stopCommand();
  }
}
