import type { Diagram } from 'gojs';
import { templateFn } from '@/bridge/types/diagramModel';
import { DiagramType } from '@/bridge/enums/diagramOptions';
import poolGroupTemplate from '@/bridge/poolLayout/poolGroupTemplate';
import layerTemplate from '@/bridge/poolLayout/layerTemplate';
import connection from '@/bridge/connection/connection';
import dependency from '@/bridge/dependency/dependency';
import element from '@/bridge/element/element';
import shapes from '@/bridge/shapes/shapes';
import guides from '@/bridge/guides/guide';
import callout from '@/bridge/shapes/callout';

export const addTemplates: templateFn = (diagram: Diagram, options, modelData) => {
  poolGroupTemplate.addTemplate(diagram, options, modelData);
  layerTemplate.addTemplate(diagram, options, modelData);
  element.addTemplate(diagram, options, modelData);
  connection.addTemplate(diagram, options, modelData);

  if (options.type === DiagramType.BIT) {
    dependency.addTemplate(diagram, options, modelData);
    shapes.addTemplate(diagram, options, modelData);
    guides.addTemplate(diagram, options, modelData);
    callout.addTemplate(diagram, options, modelData);
  }
};
