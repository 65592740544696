






































































import Vue, { PropType } from 'vue';
import { DiagramModel } from '@/types/diagram';
import { CanvasSelection } from '@/bridge/types/canvasSelection';
import { NodePreset, AllDiagramSettings, NodeWithSettings } from '@/bridge/types/diagramModel';
import { isShape } from '@/bridge/settings/shapeSettings';
import { NodeCategory, LinkCategory, ShapeCategory } from '@/bridge/enums/partCategories';
import { diagramEvents } from '@/bridge/events/diagramEvents';
import Btn from '@/components/buttons/Btn.vue';
import PartPreset from '@/views/bit/bitTabs/PartPreset.vue';
import LabelSettings from '@/components/diagram/LabelSettings.vue';
import AddPreset from '@/components/diagram/presets/AddPreset.vue';
import SizeSettings from '@/components/diagram/SizeSettings.vue';
import { NodeResizedEvent } from '@/bridge/events/addBitEvents';

export default Vue.extend({
  name: 'PartCustomisation',
  components: { SizeSettings, PartPreset, Btn, AddPreset, LabelSettings },
  props: {
    isDav: Boolean,
    selected: {
      type: Object as PropType<CanvasSelection>,
      required: true,
    },
  },
  computed: {
    diagramDivId(): string {
      return this.$store.state.diagramModule.diagramDivId;
    },
    diagramDetails(): DiagramModel|null {
      return this.$store.state.diagramModule.details;
    },
    settings(): AllDiagramSettings|null {
      if (!this.diagramDetails) return null;

      return this.$store.getters['diagramModule/settings'];
    },
    selection(): any[] {
      return this.selected.selection;
    },
    selectedPreset(): NodePreset|null {
      if (this.hasPreset && this.settings) {
        return this.settings.nodePresets[this.selection[0].data.preset];
      }
      return null;
    },
    hasPreset(): boolean {
      const [first] = this.selection;

      if (!first.data.preset) return false;

      return this.selection
        .every((p) => !!p.data.preset && p.data.preset === first.data.preset);
    },
    isElement(): boolean {
      return this.selection.every((p) => p.category === NodeCategory.ELEMENT);
    },
    isTextNode(): boolean {
      return this.selection.every((p) => p.category === ShapeCategory.TEXT);
    },
    isShape(): boolean {
      return this.selection.every((p) => isShape(p.category));
    },
    isConnection(): boolean {
      return this.selection.every((p) => p.category === LinkCategory.CONNECTION);
    },
    isDependency(): boolean {
      return this.selection.every((p) => p.category === LinkCategory.DEPENDENCY);
    },
    isLink(): boolean {
      return this.isConnection || this.isDependency;
    },
    hasAsset(): boolean {
      return this.selection.some((p) => !!p.data.assetId);
    },
    isMultipleSelection(): boolean {
      return this.selection.length > 1;
    },
    labelFieldType(): string {
      if (this.isMultipleSelection || this.isLink || this.hasAsset) return '';
      if (this.isTextNode) return 'textarea';

      return 'input';
    },
  },
  data() {
    return {
      error: '',
      loading: false,
      partSize: {
        width: 0,
        height: 0,
      },
      selectedLabel: '',
      sizeWillUpdate: {
        showInfo: false,
        message: '',
      },
    };
  },
  watch: {
    selected(newVal: CanvasSelection) {
      this.setData(newVal);
    },
  },
  methods: {
    setData(selected: CanvasSelection) {
      if (this.isElement || this.isShape) {
        const partData = selected.selection[0].data as NodeWithSettings<any>;
        this.partSize.width = partData.size.width;
        this.partSize.height = partData.size.height;

        this.selectedLabel = selected.selection[0].data.label;
      }
    },
    onLabelUpdate(value: string) {
      this.$emit('update', { value, key: 'label' });
    },
    onSizeUpdate() {
      this.$emit('update', {
        key: 'size',
        value: this.partSize,
      });
    },
    onPresetUpdate(value: number|null) {
      this.$emit('update', { value, key: 'preset' });
    },
    checkSize() {
      if (!this.isElement) {
        return;
      }

      const [node] = this.selected.selection;
      const { diagram } = node;
      if (!diagram) {
        return;
      }

      const layer = diagram.findNodeForKey(node.data.group);
      if (!layer || !layer.part) {
        return;
      }
      const xOffset = Math.abs(node.location.x - layer.location.x);
      const yOffset = Math.abs(node.location.y - layer.location.y);

      const { width: layerWidth, height: layerHeight } = layer.part.resizeObject;
      const { gridWidth, gridHeight } = (diagram.model.modelData as AllDiagramSettings).diagram;
      const tooTall = yOffset + gridHeight + this.partSize.height > layerHeight;
      const tooWide = xOffset + gridWidth + this.partSize.width > layerWidth;
      this.sizeWillUpdate.showInfo = tooWide || tooTall;

      if (tooTall && tooWide) {
        this.sizeWillUpdate.message = 'The node will be wider & taller than the layer';
      } else if (tooWide) {
        this.sizeWillUpdate.message = 'The node will be wider than the layer';
      } else if (tooTall) {
        this.sizeWillUpdate.message = 'The node will be taller than the layer';
      }
    },
    onPartResized({ part, width, height }: NodeResizedEvent) {
      if (this.isMultipleSelection) {
        return;
      }

      const [selected] = this.selection;
      if (selected && part.key === selected.key) {
        this.partSize.width = width;
        this.partSize.height = height;
      }
    },
  },
  async created() {
    this.setData(this.selected);

    diagramEvents.$on(diagramEvents.PART_RESIZED, this.onPartResized);
  },
  destroyed() {
    diagramEvents.$off(diagramEvents.PART_RESIZED, this.onPartResized);
  },
});
