<template>
  <FieldDescription
    :value="field.value || ''"
    @input="$emit('input', $event)"
    :label="field.label"
    :disable="disable"
    :required="field.required"
  />
</template>

<script>
import FieldDescription from '@/components/fields/FieldDescription.vue';
import formInputPreviewMixin from '@/components/formInputPreview/formInputPreviewMixin';

export default {
  name: 'FormInputPreviewTextarea',
  mixins: [formInputPreviewMixin],
  components: {
    FieldDescription,
  },
};
</script>
