import * as go from 'gojs';
import { NodeCategory } from '@/bridge/enums/partCategories';
import dependency from '@/bridge/dependency/dependency';
import { SelectionType, CanvasSelection } from '@/bridge/types/canvasSelection';
import { diagramEvents } from '@/bridge/events/diagramEvents';

function excludeTopLevelParts(p: go.Part) {
  return p.category !== NodeCategory.LAYER && p.category !== NodeCategory.ROOT;
}

function removeDependencyAdornments(diagram: go.Diagram) {
  const adornmentCategory = 'createDependency';

  diagram.nodes.each((p) => {
    if (p.category === NodeCategory.ELEMENT) {
      const ad = p.findAdornment(adornmentCategory);
      if (ad) {
        p.removeAdornment(adornmentCategory);
      }
    }
  });
}

export function emitSelectionChanged(diagram: go.Diagram) {
  if ((diagram as any)._addCallout) return;

  const selection = diagram.selection
    .copy()
    .filter(excludeTopLevelParts)
    .toArray();
  const onlyLinks = selection.every((p) => p.data && p.data.to && p.data.from);
  let selectionType: SelectionType;

  if (onlyLinks) {
    selectionType = SelectionType.LINK;
  } else {
    selectionType = SelectionType.NODE;
  }

  setTimeout(() => {
    diagramEvents.$emit(
      diagramEvents.SELECTION_CHANGED,
      {
        selection,
        type: selectionType,
      } as CanvasSelection,
    );
  }, 100);
}

export function addChangedSelectionEvent(diagram: go.Diagram) {
  diagram.addDiagramListener('ChangedSelection', (e) => {
    removeDependencyAdornments(diagram);
    emitSelectionChanged(e.diagram);
    dependency.maybeShowCreateDependencyBtn(diagram);
  });
}
