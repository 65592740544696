<template>
    <q-select
      dense
      outlined
      :value="field.value || ''"
      @input="$emit('input', $event)"
      :options="field.options"
      :disable="disable"
      :label="field.label"
      :required="field.required"
      class="q-mb-md"
      map-options
      emit-value
    >
      <template #no-option>
        <q-item>
          <q-item-section>
            <q-item-label>No options</q-item-label>
          </q-item-section>
        </q-item>
      </template>
    </q-select>
</template>

<script>
import formInputPreviewMixin from '@/components/formInputPreview/formInputPreviewMixin';

export default {
  name: 'FormInputPreviewSelect',
  mixins: [formInputPreviewMixin],
};
</script>
