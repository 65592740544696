import * as go from 'gojs';
import { layerUtils } from '@/bridge/base/layerInfo';

export function stayInGroup(part: go.Part, pt: go.Point, gridPt: go.Point) {
  if (!part.diagram) {
    return gridPt;
  }

  if (part.diagram.lastInput.shift) {
    part.diagram.currentTool.doCancel();
  }

  // don't constrain top-level nodes
  const grp = part.containingGroup;
  if (grp === null || !layerUtils.isLayer(part.data.layer)) return gridPt;
  // try to stay within the background Shape of the Group
  const back = grp.resizeObject;
  if (back === null || !grp.placeholder) return gridPt;

  const p1 = back.getDocumentPoint(go.Spot.TopLeft);
  const p2 = back.getDocumentPoint(go.Spot.BottomRight);
  const b = part.actualBounds;
  const loc = part.location;

  // find the padding inside the group's placeholder that is around the member parts
  const m = grp.placeholder.padding as go.Margin;
  // now limit the location appropriately
  // eslint-disable-next-line
  const x =
    Math.max(p1.x + m.left, Math.min(gridPt.x, p2.x - m.right - b.width - 1))
    + (loc.x - b.x);
  // eslint-disable-next-line
  const y =
    Math.max(p1.y + m.top, Math.min(gridPt.y, p2.y - m.bottom - b.height - 1))
    + (loc.y - b.y);
  return new go.Point(x, y);
}
