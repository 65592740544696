import * as go from 'gojs';
import { NodeSize, templateFn } from '@/bridge/types/diagramModel';
import { DiagramCreateOptions } from '@/bridge/types/diagramOptions';
import { DiagramMode, DiagramType } from '@/bridge/enums/diagramOptions';
import { NodeCategory } from '@/bridge/enums/partCategories';
import { makePort } from '@/bridge/element/makePort';
import { nodeStyle } from '@/bridge/node/nodeStyle';
import { stayInGroup } from '@/bridge/element/stayInGroup';
import { elementColor, defaultElementSize } from '@/bridge/settings/elementSettings';
import { getPartModelData } from '@/bridge/settings/common';
import { toNodeSize, toGoSize } from '@/bridge/util/shared';
import { getNodeTextStyle } from '@/bridge/node/textStyle';

const $ = go.GraphObject.make;

const MinElementWidth = 85;
const MinElementHeight = 25;

function addPorts(options: DiagramCreateOptions) {
  if (options.type === DiagramType.DAV || options.mode !== DiagramMode.MODEL) {
    return [];
  }
  return [
    makePort('T', go.Spot.Top, go.Spot.TopSide, true, true),
    makePort('L', go.Spot.Left, go.Spot.LeftSide, true, true),
    makePort('R', go.Spot.Right, go.Spot.RightSide, true, true),
    makePort('B', go.Spot.Bottom, go.Spot.BottomSide, true, true),
  ];
}

function assetIndicatorTemplate() {
  return $(
    go.Shape,
    'Circle',
    {
      fill: '#fece11',
      stroke: '#ff9330',
      cursor: 'pointer',
      desiredSize: new go.Size(8, 8),
      alignment: new go.Spot(0, 0, 5, 5),
    },
    // @TODO - get the asset details from the modelData
    new go.Binding('visible', '', (data) => !data.assetId),
  );
}

function rectangleTemplate() {
  return $(
    go.Shape,
    'Rectangle',
    {
      fill: '#333',
      strokeWidth: 2,
      stroke: 'transparent',
      // Two nodes can be connected with a connection and a dependency
      toLinkableDuplicates: true,
      fromLinkableDuplicates: true,
    },
    new go.Binding('fill', 'isHighlighted', (is: boolean, p: go.Part) => {
      if (is) {
        if (p.part && (p.part as any).$highlightFill) {
          return (p.part as any).$highlightFill;
        }

        return p.part?.diagram?.model.modelData.diagram.highlightColor || 'green';
      }

      return elementColor(p.part as go.Part).backgroundColor;
    }).ofObject(),
    new go.Binding('stroke', 'isHighlighted', (is: boolean, p: go.Part) => {
      if (is) {
        if (p.part && (p.part as any).$highlightStroke) {
          return (p.part as any).$highlightStroke;
        }
        return 'black';
      }

      return elementColor(p.part as go.Part).borderColor;
    }).ofObject(),
    new go.Binding('opacity', '', (_, p: go.Part) => {
      if (p.diagram) {
        return (p.diagram as any)._isAnchored ? 0.7 : 1;
      }
      return 1;
    }).ofObject(),
  );
}

function textTemplate() {
  return $(go.TextBlock, getNodeTextStyle());
}

function getMinSize(size: go.Size|NodeSize, p: go.Panel): NodeSize {
  const { gridWidth, gridHeight } = getPartModelData(p.part as go.Part).diagram;
  return {
    width: Math.max(gridWidth, MinElementWidth),
    height: Math.max(gridHeight, MinElementHeight),
  };
}

function elementTemplate(diagram: go.Diagram, options: DiagramCreateOptions) {
  return $(
    go.Node,
    'Table',
    nodeStyle(diagram, options),
    {
      dragComputation: stayInGroup,
      click(e) {
        e.diagram.clearHighlighteds();
      },
    },
    $(
      go.Panel,
      'Auto',
      {
        portId: '',
        name: 'SHAPE',
        cursor: options.mode === DiagramMode.MODEL ? 'pointer' : 'normal',
        desiredSize: new go.Size(defaultElementSize.width, defaultElementSize.height),
      },
      new go.Binding('minSize', 'size', (s: go.Size, p: go.Panel) => {
        const ms = getMinSize(s, p);
        return new go.Size(ms.width, ms.height);
      }).makeTwoWay((s: go.Size, p: go.Panel) => getMinSize(s, p)),
      new go.Binding('desiredSize', 'size', toGoSize).makeTwoWay(toNodeSize),
      rectangleTemplate(),
      assetIndicatorTemplate(),
      textTemplate(),
    ),
    addPorts(options),
  );
}

const addTemplate: templateFn = (diagram: go.Diagram, options) => {
  diagram.nodeTemplateMap.add(NodeCategory.ELEMENT, elementTemplate(diagram, options));
};

export function isElement(category: NodeCategory) {
  return category === NodeCategory.ELEMENT;
}

export default {
  addTemplate,
};
