import * as go from 'gojs';

const $ = go.GraphObject.make;

export function legendTextTemplate(desiredSize: go.Size) {
  return $(go.Part, 'Vertical',
    $(
      go.TextBlock,
      {
        desiredSize,
        stroke: '#29292b',
        textAlign: 'center',
        font: 'normal normal 14px DIN Next LT Pro',
      },
      new go.Binding('text', 'label'),
      new go.Binding('margin', 'margin'),
      new go.Binding('font', 'font'),
    ));
}
