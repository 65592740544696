<template>
  <div class="q-mb-md bordered">
    <q-item dense class="bg-grey-2 q-mb-sm">
      <q-item-section side v-if="canDrag">
        <Btn flat icon="menu"/>
      </q-item-section>
      <q-item-section>
        <div class="cursor-pointer">
          {{ group.label }}
          <template v-if="canEdit">
            <q-icon name="edit" size="1rem" color="grey-6"/>
            <q-popup-edit
              v-model="groupLabel"
              buttons
              autofocus
              cover
              @save="onLabelEdit"
              @show="setModel"
              @hide="groupLabel = ''"
            >
              <q-input
                dense
                autofocus
                v-model="groupLabel"
              />
              <small class="text-caption text-italic">
                To update the label, hit <strong>Enter</strong> or
                click on the <strong>Set</strong> button
              </small>
            </q-popup-edit>
          </template>
        </div>

      </q-item-section>
      <q-item-section side v-if="canDelete">
        <DeleteFormElement @delete="$emit('remove')"/>
      </q-item-section>
    </q-item>
    <slot></slot>
  </div>
</template>

<script>
import { labelMaxLen } from '@/validation/label';
import Btn from '@/components/buttons/Btn.vue';
import DeleteFormElement from '@/views/forms/DeleteFormElement.vue';

export default {
  name: 'FormGroupListItem',
  components: {
    DeleteFormElement,
    Btn,
  },
  props: {
    canDrag: Boolean,
    canEdit: Boolean,
    canDelete: Boolean,
    group: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      groupLabel: '',
    };
  },
  methods: {
    setModel() {
      this.groupLabel = this.group.label;
    },
    onLabelEdit() {
      if (!this.groupLabel || this.groupLabel.length > labelMaxLen) {
        return;
      }

      if (this.groupLabel === this.group.label) {
        return;
      }

      this.$emit('edit', { ...this.group, label: this.groupLabel });
    },
  },
};
</script>
