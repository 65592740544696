// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as go from 'gojs';

function ConnectionLink() {
  go.Link.call(this);
}
go.Diagram.inherit(ConnectionLink, go.Link);

/**
 * @this {ConnectionLink}
 * Turn options off
 */
ConnectionLink.prototype.turnOffOptions = function () {
  this.routing = go.Link.None;
  this.adjusting = go.Link.None;
  if (this.canReshape()) {
    this.reshapable = false;
  }
  this.canShift = false;
  return this;
};

/**
 * @this {ConnectionLink}
 * Turn options on
 */
ConnectionLink.prototype.turnOnOptions = function () {
  this.routing = go.Link.AvoidsNodes;
  if (!this.canReshape()) {
    this.reshapable = true;
  }
  this.canShift = true;
  return this;
};

/**
 * getFromToBounds
 * @param {ConnectionLink} link
 * @return {{fromBounds: go.Rect, toBounds: go.Rect}}
 */
function getFromToBounds(link) {
  // const toBounds = new go.Rect(
  //   link.toNode.getDocumentPoint(go.Spot.TopLeft),
  //   link.toNode.getDocumentPoint(go.Spot.TopRight),
  // );
  // const fromBounds = new go.Rect(
  //   link.fromNode.getDocumentPoint(go.Spot.Left),
  //   link.fromNode.getDocumentPoint(go.Spot.Right),
  // );

  const toBounds = link.toNode.actualBounds.copy();
  const fromBounds = link.fromNode.actualBounds.copy();

  return { fromBounds, toBounds };
}

/**
 * normalizePosition
 * Because links snap to grid, we should never have a connection
 * right on the left side (0) or on the right side (1)
 * GoJS gets confused if we use 0 or 1 because it doesn't know if the link should
 * be positioned on the vertical edge or the horizontal edge of the grid
 * @param {number} x
 * @return {number}
 */
function normalizePosition(x) {
  if (x === 0) {
    return 0.0001;
  }
  if (x === 1) {
    return 0.9999;
  }
  return x;
}

/**
 * getOverlapCenter - get the coordinate of the center of two overlapping nodes
 * @param {go.Rect} fromBounds
 * @param {go.Rect} toBounds
 * @param {boolean} rightSide - if the overlap occurs on the right side
 * @return {number} - the coordinate (X)
 */
function getOverlapCenter(fromBounds, toBounds, rightSide) {
  // How much these nodes overlap
  // the connection will be in the middle of overlapping section
  if (rightSide) {
    return (toBounds.right - fromBounds.left) / 2;
  }
  return (fromBounds.right - toBounds.left) / 2;
}

/**
 * getHorizontalOverlapCenter
 * @param {go.Rect} fromBounds
 * @param {go.Rect} toBounds
 * @return {number} - the coordinate (X)
 */
function getHorizontalOverlapCenter(fromBounds, toBounds) {
  // How much these nodes overlap
  // the connection will be in the middle of overlapping section
  if (fromBounds.top >= toBounds.top) {
    return (toBounds.bottom - fromBounds.top) / 2;
  }
  return (fromBounds.bottom - toBounds.top) / 2;
}

/**
 * hasOverlap - check if the nodes overlap
 * @param {go.Rect} fromBounds
 * @param {go.Rect} toBounds
 * @return {boolean}
 */
function hasOverlap(fromBounds, toBounds) {
  // no overlap
  if (toBounds.right <= fromBounds.left || fromBounds.right <= toBounds.left) {
    return false;
  }
  return true;
}

function hasHorizontalOverlap(fromBounds, toBounds) {
  if (fromBounds.bottom >= toBounds.top && fromBounds.top <= toBounds.bottom) {
    return true;
  }
  return false;
}

/**
 * setLinkPoints
 * @param {go.Link} link
 * @param {go.Rect} fromBounds
 * @param {go.Rect} toBounds
 * @param {go.Spot} fromSpotX
 * @param {go.Spot} toSpotX
 */
function setLinkPoints(link, fromBounds, toBounds, fromSpotX, toSpotX) {
  // the link is drawn upwards
  if (fromBounds.top >= toBounds.top) {
    link.fromSpot = new go.Spot(fromSpotX, 0, 0, 0);
    link.toSpot = new go.Spot(toSpotX, 1, 0, 0);
  } else {
    link.fromSpot = new go.Spot(fromSpotX, 1, 0, 0);
    link.toSpot = new go.Spot(toSpotX, 0, 0, 0);
  }
}

/**
 * setLinkPoints
 * @param {go.Link} link
 * @param {go.Rect} fromBounds
 * @param {go.Rect} toBounds
 * @param {go.Spot} fromSpotY
 * @param {go.Spot} toSpotY
 */
function setHorizontalLinkPoints(link, fromBounds, toBounds, fromSpotY, toSpotY) {
  if (fromBounds.left >= toBounds.right) {
    link.fromSpot = new go.Spot(0, fromSpotY, 0, 0);
    link.toSpot = new go.Spot(1, toSpotY, 0, 0);
  } else {
    link.fromSpot = new go.Spot(1, fromSpotY, 0, 0);
    link.toSpot = new go.Spot(0, toSpotY, 0, 0);
  }
}

/**
 * computePointsBelow
 * If the fromNode's width is greater or equal to toNode's width,
 * toNode is the reference node and the connection will be centered
 * relative to this node.
 * @param {go.Rect} fromBounds
 * @param {go.Rect} toBounds
 * @param {ConnectionLink} link
 */
function computePointsBelow(fromBounds, toBounds, link) {
  let fromSpotX;
  let toSpotX;

  // the narrower element is on the left side
  if (fromBounds.left >= toBounds.centerX) {
    // How much these nodes overlap?
    // The connection will be in the middle of overlapping section
    const overlapCenter = getOverlapCenter(toBounds, fromBounds);
    fromSpotX = normalizePosition(overlapCenter / fromBounds.width);
    toSpotX = 1 - normalizePosition(overlapCenter / toBounds.width);
  } else if (fromBounds.right <= toBounds.centerX) { // the narrower element is on the right side
    const overlapCenter = getOverlapCenter(toBounds, fromBounds, true);

    fromSpotX = 1 - normalizePosition(overlapCenter / fromBounds.width);
    toSpotX = normalizePosition(overlapCenter / toBounds.width);
  } else {
    // The center of toNode is inside fromNode
    fromSpotX = normalizePosition((toBounds.centerX - fromBounds.left) / fromBounds.width);
    toSpotX = 0.5;
  }

  setLinkPoints(link, fromBounds, toBounds, fromSpotX, toSpotX);
}

/**
 * computePointsBelow
 * If the fromNode's width is greater or equal to toNode's width,
 * toNode is the reference node and the connection will be centered
 * relative to this node.
 * @param {go.Rect} fromBounds
 * @param {go.Rect} toBounds
 * @param {ConnectionLink} link
 */
function computePointsTo(fromBounds, toBounds, link) {
  let fromSpotY;
  let toSpotY;

  if (fromBounds.top >= toBounds.top && fromBounds.top <= toBounds.bottom) {
    const overlapCenter = getHorizontalOverlapCenter(fromBounds, toBounds);
    fromSpotY = normalizePosition(overlapCenter / fromBounds.height);
    toSpotY = 1 - normalizePosition(overlapCenter / toBounds.height);
  } else if (fromBounds.top < toBounds.top && fromBounds.bottom <= toBounds.bottom) {
    const overlapCenter = getHorizontalOverlapCenter(toBounds, fromBounds);
    fromSpotY = 1 - normalizePosition(overlapCenter / fromBounds.height);
    toSpotY = normalizePosition(overlapCenter / toBounds.height);
  } else {
    fromSpotY = normalizePosition((toBounds.centerY - fromBounds.top) / fromBounds.height);
    toSpotY = 0.5;
  }

  setHorizontalLinkPoints(link, fromBounds, toBounds, fromSpotY, toSpotY);
}

function computePointsFrom(fromBounds, toBounds, link) {
  let fromSpotY;
  let toSpotY;

  if (fromBounds.top < toBounds.top && fromBounds.bottom <= toBounds.bottom) {
    const overlapCenter = getHorizontalOverlapCenter(fromBounds, toBounds);
    fromSpotY = 1 - normalizePosition(overlapCenter / fromBounds.height);
    toSpotY = normalizePosition(overlapCenter / toBounds.height);
  } else if (toBounds.top <= fromBounds.top && toBounds.bottom <= fromBounds.bottom) {
    const overlapCenter = getHorizontalOverlapCenter(toBounds, fromBounds);
    fromSpotY = normalizePosition(overlapCenter / fromBounds.height);
    toSpotY = 1 - normalizePosition(overlapCenter / toBounds.height);
  } else {
    fromSpotY = 0.5;
    toSpotY = normalizePosition((fromBounds.centerY - toBounds.top) / toBounds.height);
  }

  setHorizontalLinkPoints(link, fromBounds, toBounds, fromSpotY, toSpotY);
}

/**
 * computePointsAbove
 * If the toNode's width is greater or equal to fromNode's width,
 * fromNode is the reference node and the connection will be centered
 * relative to this node.
 * @param {go.Rect} fromBounds
 * @param {go.Rect} toBounds
 * @param {ConnectionLink} link
 */
function computePointsAbove(fromBounds, toBounds, link) {
  let fromSpotX;
  let toSpotX;

  // To node is on the left side
  if (toBounds.left >= fromBounds.centerX) {
    const overlapCenter = getOverlapCenter(fromBounds, toBounds);
    fromSpotX = 1 - normalizePosition(overlapCenter / fromBounds.width);
    toSpotX = normalizePosition(overlapCenter / toBounds.width);
  } else if (toBounds.right <= fromBounds.centerX) { // To node is on the right side
    const overlapCenter = getOverlapCenter(fromBounds, toBounds, true);
    fromSpotX = normalizePosition(overlapCenter / fromBounds.width);
    toSpotX = 1 - normalizePosition(overlapCenter / toBounds.width);
  } else {
    toSpotX = normalizePosition((fromBounds.centerX - toBounds.left) / toBounds.width);
    fromSpotX = 0.5;
  }

  setLinkPoints(link, fromBounds, toBounds, fromSpotX, toSpotX);
}

/**
 * @this {ConnectionLink}
 * @return {boolean}
 */
/* eslint func-names: ["error", "never"] */
ConnectionLink.prototype.computePoints = function () {
  if (!this.fromNode || !this.toNode) {
    this.turnOnOptions();
    return go.Link.prototype.computePoints.call(this);
  }

  // The nodes have the same width. The connection will be from center to center
  const equalWidth = this.fromNode.actualBounds.width === this.toNode.actualBounds.width;
  const equalHeight = this.fromNode.actualBounds.height === this.toNode.actualBounds.height;
  // From node is wider than to node
  const fromWider = this.fromNode.actualBounds.width > this.toNode.actualBounds.width;
  const fromTaller = this.fromNode.actualBounds.height > this.toNode.actualBounds.height;

  const { fromBounds, toBounds } = getFromToBounds(this);

  if (!hasOverlap(fromBounds, toBounds)) {
    if (hasHorizontalOverlap(fromBounds, toBounds)) {
      if (fromTaller || equalHeight) {
        computePointsTo(fromBounds, toBounds, this);
      } else {
        computePointsFrom(fromBounds, toBounds, this);
      }

      this.turnOffOptions();
    } else {
      this.turnOnOptions();
    }
    return go.Link.prototype.computePoints.call(this);
  }

  this.turnOffOptions();

  if (fromWider || equalWidth) {
    // Example - Ownership element is wider or has the same with as the business element
    computePointsBelow(fromBounds, toBounds, this);
  } else {
    // The business element is wider than the ownership element
    computePointsAbove(fromBounds, toBounds, this);
  }

  return go.Link.prototype.computePoints.call(this);
};

export default ConnectionLink;
/* eslint-enable */
