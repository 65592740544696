

























































































import Vue, { PropType } from 'vue';
import { NodePreset, AllDiagramSettings } from '@/bridge/types/diagramModel';
import { DiagramModel } from '@/types/diagram';
import { TextAlign, VerticalAlignment } from '@/bridge/enums/textStyle';
import { getFontString } from '@/bridge/settings/common';

export default Vue.extend({
  name: 'PartPreset',
  props: {
    preset: {
      type: Object as PropType<NodePreset>,
      required: true,
    },
  },
  computed: {
    textAlignIcon(): string {
      const { textStyle } = this.preset;
      if (!textStyle) return '';

      if (textStyle.textAlign === TextAlign.LEFT) {
        return 'format_align_left';
      }

      if (textStyle.textAlign === TextAlign.RIGHT) {
        return 'format_align_right';
      }

      return 'format_align_center';
    },
    verticalAlignment(): string {
      const { textStyle } = this.preset;
      if (!textStyle) return '';

      if (textStyle.verticalAlignment === VerticalAlignment.TOP) {
        return 'Top';
      }

      if (textStyle.verticalAlignment === VerticalAlignment.BOTTOM) {
        return 'Bottom';
      }

      return 'Center';
    },
    fontString(): string {
      const { textStyle } = this.preset;
      if (!textStyle) return '';
      return getFontString(textStyle);
    },
    diagramDivId(): string {
      return this.$store.state.diagramModule.diagramDivId;
    },
    diagramDetails(): DiagramModel|null {
      return this.$store.state.diagramModule.details;
    },
    settings(): AllDiagramSettings|null {
      if (!this.diagramDetails) return null;

      return this.$store.getters['diagramModule/settings'];
    },
  },
});
