import { jsPDF } from 'jspdf';
import { ExportDiagramData, createDownloadLink } from '@/bridge/print/printUtils';
import { createImage } from '@/bridge/print/downloadImage';

export async function downloadPDF(data: ExportDiagramData) {
  const { diagramDetails, exportOptions } = data;
  // eslint-disable-next-line new-cap
  const doc = new jsPDF({
    unit: 'px',
    orientation: 'landscape',
    format: exportOptions.printSize.toLowerCase(),
  });

  const width = doc.internal.pageSize.getWidth();
  const height = doc.internal.pageSize.getHeight();

  const image = await createImage(data);

  if (!image) return;

  doc.addImage(image, 'PNG', 20, 20, width - 40, height - 40, undefined, 'FAST');

  const pdfLabel = `${diagramDetails.label}.pdf`;

  const { msSaveOrOpenBlob } = (window.navigator as any);
  if (msSaveOrOpenBlob) {
    msSaveOrOpenBlob(doc.output('blob'), pdfLabel);
  } else {
    createDownloadLink(pdfLabel, doc.output('datauristring'));
  }
}
