import * as go from 'gojs';
import { getTextBindings } from '@/bridge/util/textBindings';

// The default style for nodes
export function getNodeTextStyle() {
  return [
    {
      stretch: go.GraphObject.Fill,
      wrap: go.TextBlock.WrapFit,
      overflow: go.TextBlock.OverflowEllipsis,
    },
    getTextBindings(),
  ];
}
