import * as go from 'gojs';
import { pointListParse, pointListStringify } from '@/bridge/util/shared';
import { AllDiagramSettings } from '@/bridge/types/diagramModel';
import { LinkCategory } from '@/bridge/enums/partCategories';
import { DiagramCreateOptions } from '@/bridge/types/diagramOptions';
import { DiagramMode, DiagramType } from '@/bridge/enums/diagramOptions';
import { getPartModelData } from '@/bridge/settings/common';

const $ = go.GraphObject.make;

export function addPreferencesBindings(category: LinkCategory, extraInitializers: any[] = []) {
  return [
    ...extraInitializers,
    // Dependency circle
    new go.Binding('fill', '', (m: AllDiagramSettings, l: go.Part) => {
      if (l.part?.isHighlighted) {
        return m.diagram.highlightColor;
      }
      return m[category].borderColor;
    }).ofModel(),
    new go.Binding('strokeWidth', '', (m: AllDiagramSettings) => m[category].borderWidth).ofModel(),
  ];
}

function linkArrow(category: LinkCategory, extraInitializers: any[] = []) {
  return $(
    go.Shape,
    { toArrow: 'OpenTriangle' },
    addPreferencesBindings(category, extraInitializers),
  );
}

function linkLine(category: LinkCategory, extraInitializers: any[]) {
  return $(
    go.Shape,
    { isPanelMain: true },
    addPreferencesBindings(category, extraInitializers),
  );
}

export default class LinkTemplateBuilder {
  private defaultInitializers: any[];

  private parts: any[];

  public linkInstance: any;
  public isDependency: boolean;
  public diagramCreateOptions: DiagramCreateOptions;

  constructor(
    linkClass: any,
    options: DiagramCreateOptions,
    isDependency = false,
  ) {
    this.linkInstance = linkClass;
    this.isDependency = isDependency;
    this.diagramCreateOptions = options;

    this.defaultInitializers = [
      {
        pickable: options.mode !== DiagramMode.VIEW,
        reshapable: true,
        resegmentable: false,
        routing: go.Link.AvoidsNodes,
        mouseEnter: (e: go.InputEvent, link: go.GraphObject) => {
          const { mode, type } = this.diagramCreateOptions;
          if (mode === DiagramMode.VIEW || type === DiagramType.DAV) {
            return;
          }
          const category = this.isDependency ? LinkCategory.DEPENDENCY : LinkCategory.CONNECTION;

          const shape: go.GraphObject = (link as go.Link).elt(0);
          const md = getPartModelData(link as go.Part);
          (shape as go.Shape).stroke = md[category].hoverColor;
        },
        mouseLeave(e: go.InputEvent, link: go.GraphObject) {
          const shape: go.GraphObject = (link as go.Link).elt(0);
          (shape as go.Shape).stroke = 'transparent';
        },
      },
      new go.Binding('points', 'points', pointListParse).makeTwoWay(pointListStringify),
      new go.Binding('fromSpot', 'fromSpot', go.Spot.parse).makeTwoWay(go.Spot.stringify),
      new go.Binding('toSpot', 'toSpot', go.Spot.parse).makeTwoWay(go.Spot.stringify),
    ];

    // The highlight helper is always the fist element
    // Used by mouseEnter/mouseLeave
    this.parts = [
      $(
        go.Shape,
        {
          isPanelMain: true,
          stroke: 'transparent',
        },
        new go.Binding('strokeWidth', '', (obj) => {
          const md = getPartModelData(obj.part);
          if (!md) return 2;
          const category = isDependency ? LinkCategory.DEPENDENCY : LinkCategory.DEPENDENCY;
          const { borderWidth } = md[category];
          if (borderWidth > 4) {
            return borderWidth * 2;
          }
          return borderWidth * 4;
        }).ofObject(),
      ),
    ];
  }

  addOptions(extraInitializers: any[]): LinkTemplateBuilder {
    this.defaultInitializers = [
      ...this.defaultInitializers,
      ...extraInitializers,
    ];
    return this;
  }

  addParts(parts: any[]): LinkTemplateBuilder {
    this.parts = [...this.parts, ...parts];
    return this;
  }

  addLine(category: LinkCategory, extraInitializers: any[] = []): LinkTemplateBuilder {
    this.parts.push(linkLine(category, extraInitializers));
    return this;
  }

  addArrow(category: LinkCategory, extraInitializers: any[] = []): LinkTemplateBuilder {
    this.parts.push(linkArrow(category, extraInitializers));
    return this;
  }

  get() {
    return $(this.linkInstance, this.defaultInitializers, this.parts);
  }
}
