










import Vue, { PropType } from 'vue';
import { ResourceType } from '@/types/resource';
import DeleteModal from '@/components/DeleteModal.vue';

export default Vue.extend({
  name: 'DeleteAssetData',
  components: { DeleteModal },
  props: {
    resourceType: {
      type: String as PropType<ResourceType>,
      required: true,
    },
    modellingStateId: {
      type: String,
      required: true,
    },
  },
  computed: {
    url(): string {
      const { projectId, resourceId } = this.$route.params;
      return `/project/${projectId}/asset/${resourceId}/data/remove`;
    },
  },
});
