import * as go from 'gojs';
import LinkShiftingTool from '@/bridge/tools/LinkShiftingTool';
import temporaryLink from '@/bridge/connection/temporaryLink';
import connectionValidation from '@/bridge/connection/connectionValidation';
import ConnectionLink from '@/bridge/connection/ConnectionLink';
import LinkTemplateBuilder from '@/bridge/link/LinkTemplateBuilder';
import { DiagramMode, DiagramType } from '@/bridge/enums/diagramOptions';
import { LinkCategory } from '@/bridge/enums/partCategories';
import { AllDiagramSettings, templateFn } from '@/bridge/types/diagramModel';
import { DiagramCreateOptions } from '@/bridge/types/diagramOptions';
import { ResourceType } from '@/types/resource';

const $ = go.GraphObject.make;

function arrowTemplate(options: DiagramCreateOptions) {
  return $(
    go.Shape,
    { toArrow: 'OpenTriangle' },
    new go.Binding('stroke', '', (m: AllDiagramSettings, l: go.Part) => {
      if (options.type === DiagramType.DAV && l.part?.data.type === ResourceType.DEPENDENCY) {
        return 'red';
      }
      return m.connection.borderColor;
    }).ofModel(),
    new go.Binding('strokeWidth', '', (m: AllDiagramSettings) => m.connection.borderWidth).ofModel(),
    new go.Binding('visible', '', (m: AllDiagramSettings) => {
      if (options.type === DiagramType.DAV) {
        return true;
      }
      return m.connection.showDirection;
    }).ofModel(),
  );
}

function addValidation(diagram: go.Diagram) {
  diagram.toolManager.linkingTool.linkValidation = connectionValidation;
  diagram.toolManager.relinkingTool.linkValidation = connectionValidation;
}

function addTools(diagram: go.Diagram, md: AllDiagramSettings) {
  temporaryLink(diagram.toolManager.linkingTool, md);
  temporaryLink(diagram.toolManager.relinkingTool, md);

  diagram.toolManager.mouseDownTools.add($(LinkShiftingTool as any));
}

// This template will be used initially, then the `LinkDrawn` event will switch
// the category to use the other template. This way we can make use of LinkShiftingTool
function addTemporaryConnectionTemplate(diagram: go.Diagram, options: DiagramCreateOptions) {
  const builder = new LinkTemplateBuilder(ConnectionLink, options);
  const tmpConnectionTemplate = builder
    .addOptions([{ routing: go.Link.AvoidsNodes }])
    .get();

  diagram.linkTemplateMap.add('', tmpConnectionTemplate);
}

function addConnectionTemplate(diagram: go.Diagram, options: DiagramCreateOptions) {
  const builder = new LinkTemplateBuilder(ConnectionLink, options);

  const connectionTemplate = builder
    .addOptions([
      {
        adjusting: go.Link.None,
        contextMenu: diagram.contextMenu,
      },
      new go.Binding('visible', '', (m: AllDiagramSettings) => m.connection.visible).ofModel(),
    ])
    .addLine(
      LinkCategory.CONNECTION,
      [
        new go.Binding('stroke', '', (m: AllDiagramSettings, l: go.Part) => {
          if (options.type === DiagramType.DAV && l.part?.data.type === ResourceType.DEPENDENCY) {
            return 'red';
          }

          if (l.part?.isHighlighted) {
            return m.diagram.highlightColor;
          }
          return m.connection.borderColor;
        }).ofModel(),
      ],
    )
    .addParts([arrowTemplate(options)])
    .get();

  diagram.linkTemplateMap.add(LinkCategory.CONNECTION, connectionTemplate);
}

const addTemplate: templateFn = (diagram: go.Diagram, options, modelData) => {
  addTemporaryConnectionTemplate(diagram, options);
  addConnectionTemplate(diagram, options);

  if (options.mode === DiagramMode.MODEL && options.type !== DiagramType.DAV) {
    addTools(diagram, modelData);
    addValidation(diagram);
  }
};

export default {
  addTemplate,
};
