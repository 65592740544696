<template>
  <FieldLink
    :value="field.value || ''"
    @input="$emit('input', $event)"
    type="website"
    :disable="disable"
    :label="field.label"
    :required="field.required"
  />
</template>

<script>
import FieldLink from '@/components/fields/FieldLink.vue';
import formInputPreviewMixin from '@/components/formInputPreview/formInputPreviewMixin';

export default {
  name: 'FormInputPreviewLink',
  mixins: [formInputPreviewMixin],
  components: {
    FieldLink,
  },
};
</script>
