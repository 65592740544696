import * as go from 'gojs';
import { NodeCategory } from '@/bridge/enums/partCategories';

export function stayOnDiagram(part: go.Part, pt: go.Point, gridPt: go.Point) {
  if (!part.diagram) {
    return pt;
  }

  if (part.diagram.lastInput.shift) {
    part.diagram.currentTool.doCancel();
  }

  // don't constrain top-level nodes
  const grp = part.diagram
    .findTopLevelGroups()
    .filter((g: go.Group) => g.data.category === NodeCategory.ROOT)
    .first();

  if (grp === null) return pt;

  // try to stay within the background Shape of the Group
  const { placeholder } = grp;
  if (!placeholder) return gridPt;

  const p1 = placeholder.getDocumentPoint(go.Spot.TopLeft);
  const p2 = placeholder.getDocumentPoint(go.Spot.BottomRight);
  const b = part.actualBounds;
  const loc = part.location;

  // the left margin is bigger because of the layer labels
  const m = new go.Margin(20, 20, 20, 60);

  // now limit the location appropriately
  // eslint-disable-next-line
  const x =
    Math.max(p1.x + m.left, Math.min(gridPt.x, p2.x - m.right - b.width - 1))
    + (loc.x - b.x);
  // eslint-disable-next-line
  const y =
    Math.max(p1.y + m.top, Math.min(gridPt.y, p2.y - m.bottom - b.height - 1))
    + (loc.y - b.y);
  return new go.Point(x, y);
}
