import * as go from 'gojs';
import elementResizeAdornment from '@/bridge/element/elementResizeAdornment';
import { locToGoPoint, pointToNodeLoc } from '@/bridge/util/shared';
import { DiagramMode, DiagramType } from '@/bridge/enums/diagramOptions';
import { DiagramCreateOptions } from '@/bridge/types/diagramOptions';
import { diagramEvents } from '@/bridge/events/diagramEvents';

const $ = go.GraphObject.make;

function selectionAdornment() {
  return $(go.Adornment, 'Auto',
    $(go.Shape, 'RoundedRectangle',
      { fill: null, stroke: '#3498db', strokeWidth: 2 }),
    $(go.Placeholder));
}

function isModelDav(options?: DiagramCreateOptions): boolean {
  if (!options) return false;

  return options.type === DiagramType.DAV && options.mode === DiagramMode.MODEL;
}

function getContextMenu(diagram: go.Diagram, options?: DiagramCreateOptions) {
  if (isModelDav(options)) {
    return $('ContextMenu',
      $('ContextMenuButton',
        {
          padding: 10,
          click(e: go.InputEvent, obj) {
            if (obj.part) {
              diagramEvents.$emit(diagramEvents.REMOVE_ELEMENT, obj.part.data);
            }
          },
        },
        $(go.TextBlock, 'Remove Element',
          {
            font: 'bold 10pt sans-serif',
            desiredSize: new go.Size(120, 30),
            textAlign: 'center',
            verticalAlignment: go.Spot.Center,
          })));
  }

  return diagram.contextMenu;
}

export function nodeStyle(diagram: go.Diagram, options?: DiagramCreateOptions) {
  let selectionAdorned = false;
  if (options && (options.mode === DiagramMode.REVIEW || options.type === DiagramType.DAV)) {
    selectionAdorned = true;
  }

  return [
    {
      contextMenu: getContextMenu(diagram, options),
      resizable: true,
      selectionAdorned,
      resizeObjectName: 'SHAPE',
      resizeAdornmentTemplate: elementResizeAdornment(),
      selectionAdornmentTemplate: selectionAdornment(),
      selectionChanged: (/* node */) => {
        // Instance.$emit('node:selected', node);
      },
      click(/* e, node */) {
        // Instance.$emit('node:click', node);
      },
      doubleClick(/* e, node */) {
        // Instance.$emit('node:doubleClick', node);
      },
    },
    new go.Binding('zOrder', 'zIndex').makeTwoWay(),
    new go.Binding('location', 'loc', locToGoPoint).makeTwoWay(pointToNodeLoc),
  ];
}
