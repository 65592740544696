



















import Vue, { PropType } from 'vue';
import { NodePreset } from '@/bridge/types/diagramModel';
import Btn from '@/components/buttons/Btn.vue';
import AppModal from '@/components/AppModal.vue';
import ListNodePresets from '@/components/diagram/presets/ListNodePresets.vue';
import { DiagramModel } from '@/types/diagram';

export default Vue.extend({
  name: 'AddPreset',
  components: {
    Btn,
    AppModal,
    ListNodePresets,
  },
  props: {
    label: {
      type: String,
      default: 'Add Preset',
    },
    diagramDetails: {
      type: Object as PropType<DiagramModel>,
      required: true,
    },
  },
  computed: {
    presets(): Record<string, NodePreset> {
      return this.diagramDetails.version.checkpoint.model.modelData.nodePresets;
    },
  },
  methods: {
    async open() {
      (this.$refs.modal as any).open();
    },
    onSelect({ id }: { id: number }) {
      this.$emit('input', id);
      (this.$refs.modal as any).close();
    },
  },
});
