


































import Vue from 'vue';
import Btn from '@/components/buttons/Btn.vue';
import AppModal from '@/components/AppModal.vue';
import ListWithFilter from '@/components/list/ListWithFilter.vue';
import SelectLayer from '@/components/fields/SelectLayer.vue';
import LayerBadge from '@/components/LayerBadge.vue';

export default Vue.extend({
  name: 'SelectAssetModal',
  components: {
    LayerBadge,
    SelectLayer,
    ListWithFilter,
    AppModal,
    Btn,
  },
  props: {
    title: {
      type: String,
      default: 'Select Asset',
    },
  },
  computed: {
    searchUrl(): string {
      return `project/${this.$route.params.projectId}/assets/info?resourceType=asset`;
    },
  },
  data() {
    return {
      layers: [],
      extraColumns: [
        {
          name: 'layer',
          label: 'Layer',
          field: 'layer',
          align: 'center',
          sortable: false,
        },
      ],
    };
  },
  methods: {
    onLayerFilter(v: number[] | null, applyFilter: any) {
      applyFilter(v, { layer: v ? v.join(',') : null });
    },
    open() {
      (this.$refs.modal as any).open();
    },
    onRowClick(e: any) {
      this.$emit('rowClick', e);
      (this.$refs.modal as any).close();
    },
  },
});
