import * as go from 'gojs';
import { ExportOptions, createDownloadLink, ExportDiagramData } from '@/bridge/print/printUtils';
import { JSONModel } from '@/bridge/types/diagramModel';
import autoSave from '@/bridge/base/AutoSave';
import { LinkCategory } from '@/bridge/enums/partCategories';
import { getDefaultModelData } from '@/bridge/settings/modelData';

function cleanDiagramModel(options: ExportOptions, diagram: go.Diagram): JSONModel {
  const jsonModel = autoSave.getDiagramData(diagram);

  jsonModel.linkDataArray.forEach((l) => l.assetId = '');
  jsonModel.nodeDataArray.forEach((n) => n.assetId = '');

  if (!options.shapes) {
    jsonModel.shapeDataArray = [];
  }
  if (!options.connections) {
    jsonModel.linkDataArray = jsonModel.linkDataArray
      .filter((l) => l.category !== LinkCategory.CONNECTION);
  }
  if (!options.dependencies) {
    jsonModel.linkDataArray = jsonModel.linkDataArray
      .filter((l) => l.category !== LinkCategory.DEPENDENCY);
  }

  if (options.defaultLayerColor) {
    jsonModel.modelData = {
      ...jsonModel.modelData,
      layers: getDefaultModelData().layers,
      nodePresets: jsonModel.modelData.nodePresets,
    };
  }

  if (!options.presets) {
    jsonModel.modelData.nodePresets = {};
  }

  jsonModel.linkAssets = {};
  jsonModel.elementAssets = {};
  jsonModel.calloutDataArray = [];

  return jsonModel;
}

export function downloadJson(data: ExportDiagramData) {
  const jsonModel = cleanDiagramModel(data.exportOptions, data.diagram);
  createDownloadLink(
    `${data.diagramDetails.label}.json`,
    `data:application/json;charset=utf-8,${encodeURIComponent(JSON.stringify(jsonModel))}`,
  );
}
