import type { Node } from 'gojs';
import { ElementData } from '@/bridge/types/diagramModel';

export default function connectionValidation(
  fromNode: Node,
  _: any,
  toNode: Node,
) {
  const fromIndex: number = (fromNode.data as ElementData).group;
  const toIndex: number = (toNode.data as ElementData).group;

  return (
    fromIndex === toIndex // same layer
    || fromIndex + 1 === toIndex // adjacent layer
    || fromIndex - 1 === toIndex
  );
}
