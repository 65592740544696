<template>
  <FieldLabel
    :value="field.value || ''"
    @input="$emit('input', $event)"
    :disable="disable"
    :label="field.label"
    :min-len="field.minLen"
    :max-len="field.maxLen"
    :required="field.required"
  />
</template>

<script>
import FieldLabel from '@/components/fields/FieldLabel.vue';
import formInputPreviewMixin from '@/components/formInputPreview/formInputPreviewMixin';

export default {
  name: 'FormInputPreviewText',
  mixins: [formInputPreviewMixin],
  components: {
    FieldLabel,
  },
};
</script>
