import { DiagramStatus, DiagramType } from '@/bridge/enums/diagramOptions';

export function diagramStatusAction(s: DiagramStatus, type: DiagramType): string {
  if (s === DiagramStatus.DRAFT) {
    return `Approve ${type === DiagramType.BIT ? 'B&IT' : 'DAV'}`;
  }
  if (s === DiagramStatus.APPROVED) {
    return 'Change to Draft';
  }
  return 'Draft';
}

export function diagramStatusColor(s: DiagramStatus, invert = false): string {
  if (s === DiagramStatus.PUBLISHED) {
    return invert ? 'blue-6' : 'green-6';
  }

  if (s === DiagramStatus.APPROVED) {
    return invert ? 'orange-6' : 'blue-6';
  }
  return invert ? 'blue-6' : 'orange-6';
}

export function diagramStatusHexColor(s: DiagramStatus): string {
  if (s === DiagramStatus.PUBLISHED) {
    return '#4caf50';
  }
  if (s === DiagramStatus.APPROVED) {
    return '#2196f3';
  }
  return '#ff9800';
}

export function nextDiagramStatus(s: DiagramStatus): DiagramStatus {
  if (s === DiagramStatus.DRAFT) {
    return DiagramStatus.APPROVED;
  }
  return DiagramStatus.DRAFT;
}
