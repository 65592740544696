<template>
  <FieldEmail
    :value="field.value || ''"
    @input="$emit('input', $event)"
    :disable="disable"
    :label="field.label"
    :required="field.required"
  />
</template>

<script>
import FieldEmail from '@/components/fields/FieldEmail.vue';
import formInputPreviewMixin from '@/components/formInputPreview/formInputPreviewMixin';

export default {
  name: 'FormInputPreviewEmail',
  mixins: [formInputPreviewMixin],
  components: {
    FieldEmail,
  },
};
</script>
