import * as go from 'gojs';
import RealtimeDragSelectingTool from '@/bridge/tools/RealtimeDragSelectingTool';
import { NodeColor } from '@/bridge/types/diagramModel';

const $ = go.GraphObject.make;

type DragSelectionSettings = {
  color: NodeColor;
  strokeWidth: number;
};

// This could go in hasLocalSettings.ts, but because it's not part of preferences,
// we'll keep it here for now.
export const settings: DragSelectionSettings = {
  color: {
    backgroundColor: 'rgba(129,236,236,0.1)',
    borderColor: '#74b9ff',
    textColor: '',
  },
  strokeWidth: 2,
};

export default function dragSelectionTemplate() {
  return $(
    RealtimeDragSelectingTool,
    { isPartialInclusion: true, delay: 50 },
    {
      box: $(
        go.Part,
        { layerName: 'Tool', selectable: false },
        $(go.Shape, {
          name: 'SHAPE',
          fill: settings.color.backgroundColor,
          stroke: settings.color.borderColor,
          strokeWidth: settings.strokeWidth,
        }),
      ),
    },
  );
}
