import * as go from 'gojs';
import { diagramEvents } from '@/bridge/events/diagramEvents';
import { rootNode } from '@/bridge/base/defaultModel';
import { createDiagram } from '@/bridge/base/createDiagram';
import { addTemplates } from '@/bridge/base/addTemplates';
import { DiagramMode, DiagramType } from '@/bridge/enums/diagramOptions';
import { DiagramCreateOptions } from '@/bridge/types/diagramOptions';
import { DiagramCheckpoint } from '@/types/diagram';
import { CalloutCategory } from '@/bridge/enums/partCategories';
import { isTopLevelCategory } from '@/bridge/util/shared';
import store from '@/store';
import { CalloutData } from '@/bridge/types/diagramModel';
import { handleDiagramEvents } from '@/bridge/events/handleDiagramEvents';

const $ = go.GraphObject.make;

function addBitContextMenu(diagram: go.Diagram, options: DiagramCreateOptions) {
  if (options.contextMenu) {
    diagram.contextMenu = $(go.HTMLInfo, {
      ...options.contextMenu,
      show: (obj: any, _: go.Diagram, tool: go.Tool) => {
        // Show only the relevant buttons given the current state.
        diagramEvents.$emit('show-context-menu', { obj, diagram, tool });
      },
      hide: () => {
        diagramEvents.$emit('hide-context-menu');
      },
    });
  }
}

export function addModel(
  diagram: go.Diagram,
  checkpoint: DiagramCheckpoint,
  options?: DiagramCreateOptions,
) {
  const { model, elementAssets, linkAssets } = checkpoint;
  const diagramModel = {
    nodeKeyProperty: 'id',
    linkKeyProperty: 'id',
    linkDataArray: [],
    nodeDataArray: [rootNode],
    modelData: model.modelData,
  };

  if (model.linkDataArray) {
    const arr = model.linkDataArray.map((ld) => {
      // If the asset was updated, update the element label as well
      if (ld.assetId && linkAssets[ld.assetId]) {
        ld.label = linkAssets[ld.assetId].label;
      }
      return ld;
    });

    (diagramModel.linkDataArray as any).push(...arr);
  }

  // Normally all these arrays will exist on the model but in case
  // something goes wrong, this checks will prevent breaking the app.
  // Notice we check if the property exist on the model not if they have a length
  // the spread (...) operator breaks only if the model[property] is undefined
  if (model.layerDataArray) {
    (diagramModel.nodeDataArray as any).push(...model.layerDataArray);
  }
  if (model.nodeDataArray) {
    let presetIds: string[] = [];
    if (model.modelData.nodePresets && Object.keys(model.modelData.nodePresets)) {
      presetIds = Object.keys(model.modelData.nodePresets);
    }

    const arr = model.nodeDataArray.map((nd) => {
      // if the node has a preset that doesn't exist
      if (nd.preset && !presetIds.includes(`${nd.preset}`)) {
        nd.preset = null;
      }

      // If the asset was updated, update the element label as well
      if (nd.assetId && elementAssets[nd.assetId]) {
        nd.label = elementAssets[nd.assetId].label;
      }
      return nd;
    });

    (diagramModel.nodeDataArray as any).push(...arr);
  }
  if (model.shapeDataArray) {
    (diagramModel.nodeDataArray as any).push(...model.shapeDataArray);
  }
  if (model.calloutDataArray) {
    (diagramModel.nodeDataArray as any).push(...model.calloutDataArray);
  }
  if (model.guideDataArray) {
    (diagramModel.nodeDataArray as any).push(...model.guideDataArray);
  }

  diagram.model = $(go.GraphLinksModel, diagramModel);
  diagram.model.copiesKey = false;

  if (options && options.type === DiagramType.DAV) {
    return;
  }

  diagram.model.makeUniqueKeyFunction = (m: go.Model): go.Key => {
    m.modelData.nextNodeId += 1;
    return m.modelData.nextNodeId;
  };

  (diagram.model as go.GraphLinksModel).makeUniqueLinkKeyFunction = (
    m: go.Model,
  ): go.Key => {
    m.modelData.nextLinkId += 1;
    return m.modelData.nextLinkId;
  };
}

export function lockParts(d: go.Diagram) {
  const user = store.getters['profileModule/user'];
  const fullName = `${user.forename} ${user.surname}`;

  d.nodes
    .each((n) => {
      if (isTopLevelCategory(n.category)) {
        n.copyable = false;
        n.deletable = false;
        n.movable = false;
        n.resizable = false;
      } else {
        const isCallout = n.category === CalloutCategory.DEFAULT;
        const isEditable = isCallout && (n.data as CalloutData).status !== 'approved' && n.data.createdBy === fullName;

        if (isCallout) {
          n.layerName = 'Foreground';
        }

        n.copyable = false;
        n.deletable = isEditable;
        n.movable = isEditable;
        n.resizable = false;
      }
    });

  d.links.each((l) => {
    l.copyable = false;
    l.reshapable = false;
    (l as any).canShift = false;
    l.deletable = false;
    l.movable = false;
    l.resegmentable = false;
  });
}

// This will return the diagram after a few milliseconds
// to make sure everything is initialized
function getDiagram(
  options: DiagramCreateOptions,
  checkpoint: DiagramCheckpoint,
): Promise<go.Diagram> {
  return new Promise((resolve) => {
    const diagram = createDiagram(options, checkpoint.model.modelData);

    addTemplates(diagram, options, checkpoint.model.modelData);

    handleDiagramEvents(diagram, options);

    if (options.mode === DiagramMode.MODEL) {
      if (options.type === DiagramType.BIT) {
        addBitContextMenu(diagram, options);
      }
    }

    addModel(diagram, checkpoint, options);

    diagram.addDiagramListener('Modified', () => {
      const idx = document.title.indexOf('*');
      if (diagram.isModified) {
        if (idx < 0) document.title += '*';
      } else if (idx >= 0) document.title = document.title.substring(0, idx);
    });

    if (options.mode === DiagramMode.REVIEW) {
      lockParts(diagram);
    }

    setTimeout(() => {
      resolve(diagram);
    }, 100);
  });
}

export async function initCanvas(
  options: DiagramCreateOptions,
  checkpoint: DiagramCheckpoint,
): Promise<go.Diagram> {
  const diagram = await getDiagram(options, checkpoint);
  diagram.zoomToFit();

  return diagram;
}

export async function destroyCanvas(divId: string|HTMLDivElement) {
  const diagram = go.Diagram.fromDiv(divId);
  if (diagram) {
    diagram.div = null;
  }
}
