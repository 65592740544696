import * as go from 'gojs';

export function guideResizeAdornment(alignment: go.Spot) {
  return go.GraphObject.make(go.Shape, 'Circle',
    {
      alignment,
      cursor: 'col-resize',
      desiredSize: new go.Size(6, 6),
      fill: 'lightblue',
      stroke: 'dodgerblue',
    });
}
