

















import Vue from 'vue';
import FieldLabel from '@/components/fields/FieldLabel.vue';
import Btn from '@/components/buttons/Btn.vue';
import FieldDescription from '@/components/fields/FieldDescription.vue';

export default Vue.extend({
  name: 'LabelSettings',
  components: {
    Btn,
    FieldLabel,
    FieldDescription,
  },
  props: {
    fieldType: {
      type: String,
      default: 'input',
    },
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      label: this.value,
    };
  },
  watch: {
    value(v: string) {
      this.label = v;
    },
  },
});
