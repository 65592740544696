


















































































// library
import Vue, { VueConstructor } from 'vue';
import formSubmit from '@/mixins/formSubmit';
// types
import { FormWrapperRequest } from '@/types/form';
// components
import ApiError from '@/components/ApiError.vue';
import AppModal from '@/components/AppModal.vue';
import FieldTags from '@/components/fields/FieldTags.vue';
import FieldDate from '@/components/fields/FieldDate.vue';
import Btn from '@/components/buttons/Btn.vue';
import { getTimestampFromDateString } from '@/util/date';
import { RequestConfig } from '@/types/list';
import List from '@/components/list/List.vue';
import ApiService from '@/services/api.service';
import notify from '@/util/notify';
import DiagramToolbarBtn from '@/views/diagram/toolbar/DiagramToolbarBtn.vue';
import { availableRoles, RoleInformation } from '@/components/admin/org/roleInformation';

export default (Vue as VueConstructor<Vue & InstanceType<typeof formSubmit>>).extend({
  name: 'ShareDiagram',
  components: {
    DiagramToolbarBtn,
    FieldTags,
    FieldDate,
    Btn,
    AppModal,
    ApiError,
    List,
  },
  mixins: [formSubmit],
  computed: {
    request(): FormWrapperRequest {
      const { projectId, versionId } = this.$route.params;
      let expiryDate = this.shareExpiry;
      if (this.shareExpiry && typeof this.shareExpiry === 'string') {
        expiryDate = getTimestampFromDateString(this.shareExpiry);
      }

      return {
        config: {
          data: { emails: this.emails, expiryDate },
          method: 'post',
          url: `/project/${projectId}/diagram/share/${versionId}`,
        },
      };
    },
    requestConfig(): RequestConfig {
      const { projectId, versionId } = this.$route.params;
      return {
        url: `project/${projectId}/diagram/share/${versionId}/info`,
      };
    },
  },
  data() {
    const roleMappings = {} as any;
    availableRoles.map((r: RoleInformation) => roleMappings[r.type] = r.name);
    // default expiry set to 2 weeks
    const nextWeek = new Date();
    nextWeek.setDate(nextWeek.getDate() + 14);
    return {
      roleMappings,
      shareExpiry: nextWeek.getTime() as number | string,
      emails: [] as string[],
      extraColumns: [
        {
          name: 'email',
          field: 'email',
          label: 'Email',
          align: 'center',
          sortable: false,
        },
        {
          name: 'expiryDate',
          label: 'Expiry Date',
          field: 'expiryDate',
          align: 'center',
          sortable: true,
        },
        {
          name: 'role',
          label: 'Role',
          field: 'role',
          align: 'center',
          sortable: false,
        },
      ],
    };
  },
  methods: {
    async unshareWithUser(entry: any, loadRows: () => void) {
      try {
        const { projectId, versionId } = this.$route.params;
        await ApiService.put(`/project/${projectId}/diagram/unshare/${versionId}`, { ids: [entry.userId] });
        notify.success('Diagram version has been unshared with the user');
        await loadRows();
      } catch (e: any) {
        notify.danger('This share could not be deleted');
      }
    },
    updateEmails(tags: string[]) {
      this.emails = tags;
    },
    async onSubmit() {
      this.error = '';

      await this.submit(this.request, this.$refs.form);
      if (this.hasError) {
        return;
      }
      this.notify('Share invitations sent');
      (this.$refs.modal as any).close();
      this.emails = [];
      this.$emit('submit');
    },
  },
});
