<template>
  <q-checkbox
    :value="!!field.value"
    @input="$emit('input', $event)"
    :label="field.label"
    :disable="disable"
  />
</template>

<script>
import formInputPreviewMixin from '@/components/formInputPreview/formInputPreviewMixin';

export default {
  name: 'FormInputPreviewDate',
  mixins: [formInputPreviewMixin],
};
</script>
