import * as go from 'gojs';
import { DiagramCreateOptions } from '@/bridge/types/diagramOptions';
import { diagramEvents } from '@/bridge/events/diagramEvents';
import { getNormalizedScale } from '@/bridge/util/shared';
import updateBindings from '@/bridge/util/updateBindings';
import { addChangedSelectionEvent } from '@/bridge/events/ChangedSelectionEvent';
import { DiagramType } from '@/bridge/enums/diagramOptions';
import { addCalloutEvents } from '@/bridge/events/addCalloutEvents';
import { addBitEvents } from '@/bridge/events/addBitEvents';

export type NodeResizedEvent = {
  part: go.Part;
  width: number;
  height: number;
}

export function handleDiagramEvents(diagram: go.Diagram, options: DiagramCreateOptions) {
  if (options.type === DiagramType.BIT) {
    addBitEvents(diagram);
  }

  addCalloutEvents(diagram);
  addChangedSelectionEvent(diagram);

  diagram.addDiagramListener('SelectionDeleted', (e) => {
    e.diagram.clearHighlighteds();
  });

  diagram.addDiagramListener('ViewportBoundsChanged', (e) => {
    diagramEvents.$emit(
      diagramEvents.ZOOM_CHANGED,
      getNormalizedScale(e),
    );
  });

  diagramEvents.$on(diagramEvents.UPDATE_BINDINGS, () => {
    updateBindings(diagram);
  });
}
