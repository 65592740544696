





































































































import Vue from 'vue';
import { Diagram } from 'gojs';
import { Dav } from '@/bridge/dav/dav.v1';
import { OrgModel } from '@/types/org';
import { ProjectModel, ProjectPreferences } from '@/types/project';
import { DiagramModel, DiagramVersion } from '@/types/diagram';
import { JSONModel, AllDiagramSettings } from '@/bridge/types/diagramModel';
import { DiagramStatus, DiagramType } from '@/bridge/enums/diagramOptions';
import autoSave from '@/bridge/base/AutoSave';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import PageSpinner from '@/components/PageSpinner.vue';
import DiagramSettingsForm from '@/views/diagram/DiagramSettingsForm.vue';
import UpdateDiagramSettingsBanner from '@/views/diagram/UpdateDiagramSettingsBanner.vue';
import DiagramCanvas from '@/views/diagram/DiagramCanvas.vue';
import ShareDiagram from '@/views/diagram/toolbar/ShareDiagram.vue';
import ExportDiagram from '@/views/diagram/toolbar/ExportDiagram.vue';
import DiagramToolbarBtn from '@/views/diagram/toolbar/DiagramToolbarBtn.vue';
import UnsavedChangesModal from '@/views/diagram/UnsavedChangesModal.vue';
import DiagramZoomSlider from '@/views/diagram/toolbar/ZoomSlider.vue';
import ConfirmRemoveElement from '@/views/dav/ConfirmRemoveElement.vue';
import DavInfo from '@/views/dav/DavInfo.vue';
import DavCandidateList from '@/views/dav/DavCandidateList.vue';
import notify from '@/util/notify';
import ApiService from '@/services/api.service';
import DavTabs from '@/views/dav/DavTabs.vue';

export default Vue.extend({
  name: 'ModelDav',
  components: {
    DavTabs,
    DavInfo,
    ConfirmRemoveElement,
    DiagramZoomSlider,
    UnsavedChangesModal,
    DiagramToolbarBtn,
    ExportDiagram,
    ShareDiagram,
    DiagramCanvas,
    UpdateDiagramSettingsBanner,
    PageSpinner,
    Breadcrumbs,
    DiagramSettingsForm,
    DavCandidateList,
  },
  computed: {
    org(): OrgModel {
      return this.$store.getters['orgModule/details'];
    },
    project(): ProjectModel {
      return this.$store.getters['projectModule/details'];
    },
    diagramDetails(): DiagramModel|null {
      return this.$store.getters['diagramModule/details'];
    },
    diagramModel(): JSONModel|null {
      return this.$store.getters['diagramModule/model'];
    },
    settings(): AllDiagramSettings|null {
      return this.$store.getters['diagramModule/settings'];
    },
    projectSettings(): AllDiagramSettings|null {
      return this.$store.getters['projectModule/canvasSettings'];
    },
    version(): DiagramVersion|null {
      return this.$store.getters['diagramModule/version'];
    },
    isPublished(): boolean {
      if (this.version && this.version.status) {
        return this.version.status === DiagramStatus.PUBLISHED;
      }
      return false;
    },
    breadcrumbs(): any[] {
      const { versionId, diagramId } = this.$route.params;

      return [
        {
          label: this.org.label,
          url: `/org/${this.org.id}/projects`,
        },
        {
          label: this.project.label,
          url: `/org/${this.org.id}/project/${this.project.id}/davs`,
        },
        {
          label: 'View Diagram',
          url: `/org/${this.org.id}/project/${this.project.id}/view-dav/${diagramId}/version/${versionId}`,
        },
        {
          label: `Model ${this.diagramDetails?.label}`,
        },
      ];
    },
  },
  data() {
    return {
      diagramType: DiagramType.DAV,
      diagram: null as null|Diagram,
      dav: null as null|Dav,
      showDiagramInfo: true,
      canvasHeight: 'calc(100vh - 70px)',
      diagramDivId: 'modelDav',
      error: '',
      projectPreferences: {} as ProjectPreferences,
      interval: null as any,
    };
  },
  methods: {
    async onCanvasLoaded() {
      this.diagram = Diagram.fromDiv(this.diagramDivId);
      if (this.diagram) {
        this.dav = new Dav(this.diagram);
        this.diagram = this.dav.diagram;

        if (this.version) {
          const { nodeDataArray, linkDataArray } = this.version.checkpoint.model;

          const last = nodeDataArray[nodeDataArray.length - 1];
          if (last) {
            await this.dav.loadData(nodeDataArray, linkDataArray);
          }

          this.dav.isModified = false;
        }
      }

      window.onbeforeunload = () => {
        if (this.dav && !this.dav.isModified) {
          // eslint-disable-next-line no-void
          return void 0;
        }
        return true;
      };
    },
    async onRemove() {
      if (this.dav && this.version) {
        const last = this.dav.lastNode;
        const { davCandidateList } = this.$refs;
        if (last && davCandidateList) {
          await (davCandidateList as any).getCandidates(last.assetId);
        }
      }
    },
    async saveDiagram() {
      if (this.diagram) {
        await this.$store.dispatch('diagramModule/saveDiagram', {
          diagram: this.diagram,
          params: this.$route.params,
        });
        if (this.dav) {
          this.dav.isModified = false;
        }
      }
    },
    async saveDiagramSettings(s: AllDiagramSettings) {
      if (this.diagram) {
        s.diagram.gridVisible = false;
        await this.$store.dispatch('diagramModule/saveSettings', {
          diagram: this.diagram,
          settings: s,
          params: this.$route.params,
        });
        await this.$store.dispatch('diagramModule/loadDetails', { ...this.$route.params, force: true });

        if (this.dav) {
          this.dav.isModified = false;
        }
      }
    },
    async toggleFullScreen() {
      const el = document.querySelector('.q-page-container');

      if (el) {
        await this.$q.fullscreen.toggle(el);
      }
    },
    async onSessionExpired() {
      window.onbeforeunload = null;
      if (this.dav) {
        this.dav.isModified = false;
      }
      await this.$router.replace('/logout');
    },
    async redirectToView() {
      const { diagramId, versionId } = this.$route.params;
      await this.$router.replace(`/org/${this.org.id}/project/${this.project.id}/view-dav/${diagramId}/version/${versionId}`);
    },
    toggleInfoPanel() {
      setTimeout(() => {
        if (this.dav) {
          this.dav.diagram.requestUpdate();
        }
      }, 100);
      this.showDiagramInfo = !this.showDiagramInfo;
    },
    async clearLock() {
      notify.danger('Couldn\'t acquire lock on diagram, another user is currently modelling.');
      clearInterval(this.interval);
      await this.releaseLock();
      await this.redirectToView();
    },
    async refreshLock() {
      const { diagramId, projectId, versionId } = this.$route.params;
      let url = `/project/${projectId}/diagrams/${diagramId}/lock`;
      if (versionId) {
        url = `${url}?versionId=${versionId}`;
      }
      await ApiService.put(url);
    },
    async releaseLock() {
      const { diagramId, projectId, versionId } = this.$route.params;
      let url = `/project/${projectId}/diagrams/${diagramId}/unlock`;
      if (versionId) {
        url = `${url}?versionId=${versionId}`;
      }

      try {
        await ApiService.put(url);
      } catch (e) {
        console.log('could not release lock');
      }
    },
  },
  async created() {
    this.$store.dispatch('diagramModule/clear');

    // The user doesn't have the right role.
    // Don't load anything, just redirect.
    if (!this.$hasProjectRole(this.$Roles.CONTRIBUTOR)) {
      await this.redirectToView();
      return;
    }

    await this.$store.dispatch('diagramModule/loadDetails', { ...this.$route.params, force: true });
    this.$store.dispatch('diagramModule/setDiagramDivId', this.diagramDivId);

    // The user has the required role.
    // Check if the diagram can be modified.
    if (this.isPublished) {
      await this.redirectToView();
      return;
    }

    this.$auth.$on('sessionExpired', this.onSessionExpired);
    try {
      await this.refreshLock();
    } catch (e) {
      await this.clearLock();
      return;
    }

    clearInterval(this.interval);
    this.interval = setInterval(() => {
      try {
        this.refreshLock();
      } catch (e) {
        this.clearLock();
      }
    }, 5000);

    autoSave.$on(autoSave.EVENT_SAVE, this.saveDiagram);
  },
  destroyed() {
    clearInterval(this.interval);
  },
  beforeRouteLeave(to, from, next: () => void) {
    clearInterval(this.interval);
    this.$auth.$off('sessionExpired', this.onSessionExpired);
    autoSave.$off(autoSave.EVENT_SAVE, this.saveDiagram);

    // IMPORTANT! Do not unregister event listeners
    // below this line. It will lead to a potential
    // memory leak depending on the state of the diagram.
    if (this.dav && this.dav.isModified) {
      (this.$refs.unsavedChangesModal as any).open(next);
      return;
    }
    this.$store.dispatch('diagramModule/clear');
    this.releaseLock();

    window.onbeforeunload = null;
    next();
  },
});
