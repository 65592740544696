



































import Vue, { PropType } from 'vue';
import { TableColumn } from '@/types/list';
import { DataFormField, DataFormGroup, DataForm } from '@/types/dataForm';
import { fieldTypes } from '@/components/formInputConfig/fields';
import { formatDate } from '@/util/date';
import SecurityLevelCol from '@/components/list/SecurityLevelCol.vue';

export default Vue.extend({
  name: 'ViewFormContent',
  components: { SecurityLevelCol },
  props: {
    withValue: Boolean,
    collapseAll: Boolean,
    form: {
      type: Object as PropType<DataForm>,
      required: true,
    },
  },
  computed: {
    tableColumns(): TableColumn[] {
      if (this.withValue) {
        const valueCol: TableColumn = {
          name: 'value',
          label: 'Value',
          field: 'value',
          align: 'left',
        };
        const [label, ...rest] = this.columns;
        return [label, valueCol, ...rest];
      }

      return this.columns;
    },
    filteredGroups(): DataFormGroup[] {
      if (!this.form || !this.form.groups) {
        return [];
      }

      return this.form.groups.filter((g) => (!g.fields || g.fields.length > 0));
    },
  },
  data() {
    return {
      columns: [
        {
          name: 'label',
          label: 'Field Name',
          field: 'label',
          align: 'left',
        },
        {
          name: 'type',
          label: 'Type',
          field: 'type',
          align: 'left',
        },
        {
          name: 'securityLevel',
          label: 'Data Security Level',
          field: 'securityLevel',
          align: 'left',
        },
        {
          name: 'required',
          label: 'Required',
          field: 'required',
          align: 'left',
        },
      ] as TableColumn[],
    };
  },
  methods: {
    getDisplayValue(row: Record<string, any>) {
      if (row.type === fieldTypes.NUMBER) {
        return row.value || 0;
      }

      if (row.type === fieldTypes.CHECKBOX) {
        return !!row.value;
      }

      if (!row.value) {
        return '';
      }

      if (row.type === fieldTypes.DATE) {
        return formatDate(row.value);
      }

      return row.value;
    },
    getSortedGroups(): DataFormGroup[] {
      if (!this.form || !this.form.groups) {
        return [];
      }

      const groups = [...this.form.groups];

      return groups
        .sort((a: DataFormGroup, b: DataFormGroup) => a.sort - b.sort)
        .map((g: DataFormGroup) => {
          if (g.fields && g.fields.length) {
            g.fields.sort((a: DataFormField, b: DataFormField) => a.sort - b.sort);
          }
          return g;
        });
    },
  },
});
