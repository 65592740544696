<template>
  <FieldDate
    :value="field.value"
    @input="$emit('input', $event)"
    :label="field.label"
    :disable="disable"
    :required="field.required"
  />
</template>

<script>
import FieldDate from '@/components/fields/FieldDate.vue';
import formInputPreviewMixin from '@/components/formInputPreview/formInputPreviewMixin';

export default {
  name: 'FormInputPreviewDate',
  mixins: [formInputPreviewMixin],
  components: {
    FieldDate,
  },
};
</script>
