import * as go from 'gojs';
import { elementColor } from '@/bridge/settings/elementSettings';
import { AllDiagramSettings, ElementData, ShapeData } from '@/bridge/types/diagramModel';
import { getFontString, getVerticalAlignment } from '@/bridge/settings/common';
import { TextStyle } from '@/bridge/types/textStyle';

type PartData = ElementData|ShapeData;

export function getTextBindings(textStyleConstraints: Partial<TextStyle> = {}) {
  return [
    new go.Binding('text', 'label').makeTwoWay(),
    new go.Binding('stroke', 'isHighlighted', (is: boolean, p: go.Part) => {
      if (is) {
        if (p.part && (p.part as any).$highlightText) {
          return (p.part as any).$highlightText;
        }
        return 'white';
      }

      return elementColor(p.part as go.Part).textColor;
    }).ofObject(),
    new go.Binding('font', '', (md: AllDiagramSettings, tb: go.TextBlock) => {
      const { data }: { data: PartData } = tb.part as go.Part;
      if (data.settings && data.settings.textStyle) {
        return getFontString({
          ...data.settings.textStyle,
          ...textStyleConstraints,
        });
      }

      if (data.preset) {
        const preset = md.nodePresets[data.preset];
        if (preset && preset.textStyle) {
          return getFontString({
            ...preset.textStyle,
            ...textStyleConstraints,
          });
        }
      }

      if (md.layers[data.group] && md.layers[data.group].elementTextStyle) {
        return getFontString({
          ...md.layers[data.group].elementTextStyle,
          ...textStyleConstraints,
        });
      }

      return getFontString(md.element.textStyle);
    }).ofModel(),
    new go.Binding('textAlign', '', (md: AllDiagramSettings, tb: go.TextBlock) => {
      const { data }: { data: PartData } = tb.part as go.Part;
      if (data.settings && data.settings.textStyle) {
        return data.settings.textStyle.textAlign;
      }

      if (data.preset) {
        const preset = md.nodePresets[data.preset];
        if (preset && preset.textStyle) {
          return preset.textStyle.textAlign;
        }
      }

      if (md.layers[data.group] && md.layers[data.group].elementTextStyle) {
        return md.layers[data.group].elementTextStyle.textAlign;
      }

      return md.element.textStyle.textAlign;
    }).ofModel(),
    new go.Binding('verticalAlignment', '', (md: AllDiagramSettings, tb: go.TextBlock) => {
      const { data }: { data: PartData } = tb.part as go.Part;
      if (data.settings && data.settings.textStyle) {
        return getVerticalAlignment(data.settings.textStyle.verticalAlignment);
      }

      if (data.preset) {
        const preset = md.nodePresets[data.preset];
        if (preset && preset.textStyle) {
          return getVerticalAlignment(preset.textStyle.verticalAlignment);
        }
      }

      if (md.layers[data.group] && md.layers[data.group].elementTextStyle) {
        return getVerticalAlignment(md.layers[data.group].elementTextStyle.verticalAlignment);
      }

      return getVerticalAlignment(md.element.textStyle.verticalAlignment);
    }).ofModel(),
    new go.Binding('isUnderline', '', (md: AllDiagramSettings, tb: go.TextBlock) => {
      const { data }: { data: PartData } = tb.part as go.Part;
      if (data.settings && data.settings.textStyle) {
        return data.settings.textStyle.isUnderline;
      }

      if (data.preset) {
        const preset = md.nodePresets[data.preset];
        if (preset && preset.textStyle) {
          return preset.textStyle.isUnderline;
        }
      }

      if (md.layers[data.group] && md.layers[data.group].elementTextStyle) {
        return md.layers[data.group].elementTextStyle.isUnderline;
      }

      return md.element.textStyle.isUnderline;
    }).ofModel(),
  ];
}
