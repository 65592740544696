<template>
  <div>
    <q-tooltip :offset="[0, -10]" v-if="field.description">
      {{ field.description }}
    </q-tooltip>

    <q-item>
      <q-item-section>
        <component
          @input="$emit('input', $event)"
          v-if="previewComponent"
          :is="previewComponent"
          :field="field"
          :disable="disable"
        />
      </q-item-section>
      <q-item-section top class="q-pl-md">
        <q-item-label>Required</q-item-label>
        <q-item-label class="text-bold">
          {{ field.required ? 'Yes' : 'No' }}
        </q-item-label>
      </q-item-section>
      <q-item-section top class="q-pl-md">
        <q-item-label>Data security level</q-item-label>
        <q-item-label class="text-bold">
          <SecurityLevelCol tag="span" :row="{ securityLevel: field.securityLevel }" />
        </q-item-label>
      </q-item-section>
    </q-item>
  </div>
</template>

<script>
import { fieldTypes } from '@/components/formInputConfig/fields';
import FormInputPreviewText from '@/components/formInputPreview/FormInputPreviewText.vue';
import FormInputPreviewEmail from '@/components/formInputPreview/FormInputPreviewEmail.vue';
import FormInputPreviewTextarea from '@/components/formInputPreview/FormInputPreviewTextarea.vue';
import FormInputPreviewLink from '@/components/formInputPreview/FormInputPreviewLink.vue';
import FormInputPreviewNumber from '@/components/formInputPreview/FormInputPreviewNumber.vue';
import FormInputPreviewDate from '@/components/formInputPreview/FormInputPreviewDate.vue';
import FormInputPreviewTime from '@/components/formInputPreview/FormInputPreviewTime.vue';
import FormInputPreviewCheckbox from '@/components/formInputPreview/FormInputPreviewCheckbox.vue';
import FormInputPreviewSelect from '@/components/formInputPreview/FormInputPreviewSelect.vue';
import SecurityLevelCol from '@/components/list/SecurityLevelCol.vue';

export default {
  name: 'InputPreviewWrapper',
  props: {
    disable: Boolean,
    field: {
      type: Object,
      required: true,
    },
  },
  components: {
    SecurityLevelCol,
    FormInputPreviewTextarea,
    FormInputPreviewText,
    FormInputPreviewLink,
    FormInputPreviewEmail,
    FormInputPreviewNumber,
    FormInputPreviewDate,
    FormInputPreviewTime,
    FormInputPreviewCheckbox,
    FormInputPreviewSelect,
  },
  computed: {
    previewComponent() {
      switch (this.field.type) {
        case fieldTypes.LINK:
          return 'FormInputPreviewLink';
        case fieldTypes.TEXTAREA:
          return 'FormInputPreviewTextarea';
        case fieldTypes.EMAIL:
          return 'FormInputPreviewEmail';
        case fieldTypes.NUMBER:
          return 'FormInputPreviewNumber';
        case fieldTypes.DATE:
          return 'FormInputPreviewDate';
        case fieldTypes.TIME:
          return 'FormInputPreviewTime';
        case fieldTypes.CHECKBOX:
          return 'FormInputPreviewCheckbox';
        case fieldTypes.SELECT:
          return 'FormInputPreviewSelect';
        default:
          return 'FormInputPreviewText';
      }
    },
  },
};
</script>
