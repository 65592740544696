

































import Vue from 'vue';
import { DataForm } from '@/types/dataForm';
import ApiService from '@/services/api.service';

export default Vue.extend({
  name: 'SelectForm',
  props: {
    multiple: Boolean,
    required: Boolean,
    errorMessage: String,
    value: {
      type: [String, Array],
      required: false,
    },
  },
  computed: {
    label() {
      if (this.multiple) {
        return 'Select forms';
      }
      return 'Select form';
    },
  },
  data() {
    return {
      options: [] as DataForm[],
    };
  },
  methods: {
    validation(selected: string|string[]) {
      if (this.required) {
        if (this.multiple && (!selected || !selected.length)) {
          return 'Form is required';
        }
        if (!this.multiple && !selected) {
          return 'Form is required';
        }
      }

      return true;
    },
    async filterFn(val: string, update: any, abort: any) {
      try {
        const response = await ApiService.get(`project/${this.$route.params.projectId}/forms/info`, {
          perPage: 99,
          search: val,
        });

        update(() => {
          if (!response.results || !response.results.length) {
            this.options = [];
            return;
          }
          const needle = val.toLowerCase();
          this.options = response.results
            .filter((v: DataForm) => v.label.toLowerCase()
              .includes(needle));
        });
      } catch {
        abort();
      }
    },
  },
  async created() {
    const response = await ApiService.get(
      `project/${this.$route.params.projectId}/forms/info`,
      { perPage: 99 },
    );

    if (!response.results || !response.results.length) {
      this.options = [];
      return;
    }

    this.options = response.results;
  },
});
