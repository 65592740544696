import { AddResourceData } from '@/types/resource';

type DataField = {
  formId: string;
  values: Record<string, any>;
}

export function transformRequest(data: AddResourceData) {
  const fieldValues: DataField[] = [];

  /**
   * We iterate through each form -> group -> fields
   * to get something like this
   * {
   *   formId: 'uuid',
   *   values: {
   *     'field-1-id': 'value',
   *     'field-2-id': 'value',
   *   }
   * }
   */
  Object.keys(data.forms).forEach((formId) => {
    const tmp: DataField = {
      formId,
      values: {},
    };
    data.forms[formId].groups.forEach((g) => {
      g.fields.forEach((f) => {
        if (f.value !== null) {
          tmp.values[f.id] = f.value;
        }
      });
    });

    fieldValues.push(tmp);
  });

  return {
    modellingStateId: data.modellingStateId,
    data: fieldValues,
    remove: data.remove,
  };
}
