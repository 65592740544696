<template>
  <q-input
    v-bind="$attrs"
    mask="time"
    :rules="[requiredTime]"
    dense
    outlined
    clearable
    no-error-icon
    :value="value"
    :required="required"
    :error-message="errorMessage"
    @input="$emit('input', $event)"
    :error="!!errorMessage"
    lazy-rules="ondemand"
  >
    <template #append>
      <q-icon name="schedule" class="cursor-pointer">
        <q-popup-proxy transition-show="scale" transition-hide="scale">
          <q-time
            minimal
            format24h
            :value="value"
            @input="$emit('input', $event)"
          >
            <div class="row items-center justify-end">
              <q-btn v-close-popup label="Close" color="primary" flat />
            </div>
          </q-time>
        </q-popup-proxy>
      </q-icon>
    </template>
  </q-input>
</template>

<script>
export default {
  name: 'FieldTime',
  props: {
    value: [String, Number],
    errorMessage: String,
    required: Boolean,
  },
  methods: {
    validTime(val) {
      if (!val) {
        return true;
      }

      if (!/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(val)) {
        return 'Please enter a valid time';
      }

      return true;
    },
    requiredTime(val) {
      if (this.required && !val) {
        return 'Please enter a value';
      }
      return this.validTime(val);
    },
  },
};
</script>
