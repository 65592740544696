


























import Vue from 'vue';
import AppModal from '@/components/AppModal.vue';
import Btn from '@/components/buttons/Btn.vue';

export default Vue.extend({
  name: 'UseProjectSettingsWarning',
  components: { Btn, AppModal },
  methods: {
    open() {
      if (this.$refs.modal) {
        (this.$refs.modal as any).open();
      }
    },
  },
});
