import * as go from 'gojs';
import { calloutSize, calloutColor } from '@/bridge/shapes/callout';

const $ = go.GraphObject.make;

export function legendCalloutTemplate() {
  return $(go.Node, 'Horizontal',
    {
      margin: new go.Margin(0, 0, 10, 0),
    },
    $(go.Shape, 'Circle',
      {
        stroke: null,
        width: calloutSize,
        height: calloutSize,
        margin: new go.Margin(0, 10, 0, 0),
      },
      new go.Binding('fill', '', (data: any) => calloutColor[data.color as keyof typeof calloutColor].hex)),
    $(go.TextBlock,
      { font: '14px sans-serif' },
      new go.Binding('text', 'label')));
}
