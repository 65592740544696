















































import Vue, { PropType } from 'vue';
import { Dav } from '@/bridge/dav/dav.v1';
import { DiagramModel, DiagramVersion } from '@/types/diagram';
import { ElementData, LinkData } from '@/bridge/types/diagramModel';
import { LinkCategory } from '@/bridge/enums/partCategories';
import PreviewDavResource from '@/views/dav/PreviewDavResource.vue';
import Btn from '@/components/buttons/Btn.vue';
import DiagramStatusText from '@/components/diagramStatus/DiagramStatusText.vue';

type DavStats = {
  assets: number;
  connections: number;
  dependencies: number;
};

export default Vue.extend({
  name: 'DavInfo',
  components: {
    DiagramStatusText,
    Btn,
    PreviewDavResource,
  },
  props: {
    show: Boolean,
    dav: {
      type: Object as PropType<Dav>,
      required: true,
    },
  },
  computed: {
    diagramDetails(): DiagramModel|null {
      return this.$store.getters['diagramModule/details'];
    },
    version(): DiagramVersion|null {
      return this.$store.getters['diagramModule/version'];
    },
    davResources(): (ElementData|LinkData)[] {
      const arr: (ElementData|LinkData)[] = [];
      this.davAssets.forEach((da, index) => {
        const c = this.davConnectors[index - 1];
        if (index === 0) {
          arr.push(da);
        } else if (c) {
          arr.push(c, da);
        }
      });

      return arr;
    },
    davStats(): DavStats {
      if (this.dav) {
        const { davAssets, davConnectors } = this.dav;
        return {
          assets: davAssets.length,
          connections: davConnectors.filter((c) => c.category === LinkCategory.CONNECTION).length,
          dependencies: davConnectors.filter((c) => c.category === LinkCategory.DEPENDENCY).length,
        };
      }
      return {
        assets: 0,
        connections: 0,
        dependencies: 0,
      };
    },
    davAssets(): ElementData[] {
      if (this.dav) {
        return this.dav.davAssets;
      }
      return [];
    },
    davConnectors(): LinkData[] {
      if (this.dav) {
        return this.dav.davConnectors;
      }
      return [];
    },
  },
  methods: {
    async removeLast() {
      if (this.dav) {
        await this.dav.removeLast();
        this.$emit('remove');
      }
    },
  },
});
