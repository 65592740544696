import * as go from 'gojs';
import { DiagramMode, DiagramType } from '@/bridge/enums/diagramOptions';
import {
  getViewOptions,
  getModelOptions,
  getReviewOptions,
  getDavModelOptions,
} from '@/bridge/base/diagramOptions';
import { DiagramCreateOptions } from '@/bridge/types/diagramOptions';
import { AllDiagramSettings } from '@/bridge/types/diagramModel';

const $ = go.GraphObject.make;

export function createDiagram(co: DiagramCreateOptions, md: AllDiagramSettings): go.Diagram {
  let diagramOptions: Record<string, any>;
  const { mode, type, selector } = co;

  if (mode === DiagramMode.REVIEW) {
    // We might need different createOptions for DAV review
    diagramOptions = getReviewOptions(md);
  } else if (mode === DiagramMode.MODEL) {
    diagramOptions = type === DiagramType.BIT ? getModelOptions(md) : getDavModelOptions();
  } else {
    diagramOptions = getViewOptions();
  }

  return $(go.Diagram, selector, diagramOptions);
}
