








































import Vue, { PropType } from 'vue';
import { Dav } from '@/bridge/dav/dav.v1';
import { ElementData } from '@/bridge/types/diagramModel';
import { diagramEvents } from '@/bridge/events/diagramEvents';
import autoSave from '@/bridge/base/AutoSave';
import Btn from '@/components/buttons/Btn.vue';
import AppModal from '@/components/AppModal.vue';

export default Vue.extend({
  name: 'ConfirmRemoveElement',
  components: {
    Btn,
    AppModal,
  },
  props: {
    dav: {
      type: Object as PropType<Dav>,
      required: true,
    },
  },
  data() {
    return {
      asset: null as null|ElementData,
      nodesCount: 0,
    };
  },
  methods: {
    open(asset: ElementData) {
      if (!asset || !this.$refs.modal) {
        return;
      }

      this.nodesCount = this.dav.davAssets.length;
      this.asset = asset;

      if (this.nodesCount === 1) {
        this.removeFrom('right');
        this.onClose();
        this.$emit('remove');
        return;
      }

      this.dav.highlight(asset);
      (this.$refs.modal as any).open();
    },
    async removeFrom(direction: 'left'|'right') {
      if (this.asset) {
        await this.dav.removeFrom(this.asset, direction);
        autoSave.$emit(autoSave.EVENT_SAVE);
        this.$emit('remove');
      }
      (this.$refs.modal as any).close();
    },
    onClose() {
      this.dav.diagram.clearHighlighteds();
      this.asset = null;
      this.nodesCount = 0;
    },
  },
  created() {
    diagramEvents.$on(diagramEvents.REMOVE_ELEMENT, this.open);
  },
  destroyed() {
    diagramEvents.$off(diagramEvents.REMOVE_ELEMENT, this.open);
  },
});
