import * as go from 'gojs';
import { getDiagramFromDiv } from '@/bridge/util/shared';

const $ = go.GraphObject.make;

export function createOverview(diagramDivId: string, overviewDivId: string) {
  const diagram = getDiagramFromDiv(diagramDivId);
  if (!diagram) return;

  $(go.Overview, overviewDivId, { observed: diagram });
}

export function destroyOverview(selector: string) {
  const diagram = getDiagramFromDiv(selector);
  if (!diagram) return;

  diagram.div = null;
}
