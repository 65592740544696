import { capitalizeAllWords } from '@/util/strings';

export const fieldTypes = {
  TEXT: 'text',
  TEXTAREA: 'textarea',
  EMAIL: 'email',
  LINK: 'link',
  DATE: 'date',
  // TIME: 'time',
  NUMBER: 'number',
  // PERCENT: 'percent',
  // CURRENCY: 'currency',
  SELECT: 'select',
  CHECKBOX: 'checkbox',
  // RADIO: 'radio',
};

// return something like [{ label: 'Text', value: 'text' }, ...]
export const getFieldTypeOptions = () => Object.values(fieldTypes)
  .map((t) => ({
    label: capitalizeAllWords(t),
    value: t,
  }));
