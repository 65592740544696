import EventBus from '@/util/EventBus';

class DiagramEvents extends EventBus {
  // Diagram
  public readonly UPDATE_BINDINGS = 'updateBindings';
  public readonly ZOOM_CHANGED = 'zoomChanged';
  public readonly LINK_DRAWN = 'linkDrawn';

  // Nodes
  public readonly NODE_DRAG = 'nodeDrag';
  public readonly PART_RESIZED = 'partResized';
  public readonly SELECTION_CHANGED = 'selectionChanged';

  // Legend
  public readonly UPDATE_LEGEND_MODEL = 'updateLegendModel';

  // Comments
  public readonly CALLOUT_CREATED = 'calloutCreated';
  public readonly SELECT_CALLOUT = 'selectCallout';
  public readonly UPDATE_CALLOUT = 'updateCallout';
  public readonly TOGGLE_APPROVED_CALLOUTS = 'toggleApprovedCallouts';
  public readonly DELETE_CALLOUT = 'deleteCallout';
  public readonly CALLOUT_DELETED = 'calloutDeleted';

  // DAV
  public readonly REMOVE_ELEMENT = 'removeElement';
}

export const diagramEvents = new DiagramEvents();
