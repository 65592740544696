import * as go from 'gojs';
import {
  computeMinLaneSize,
  computeMinPoolSize,
  computeLaneSize,
} from '@/bridge/poolLayout/PoolLayout';
import { reLayoutDiagram } from '@/bridge/util/shared';
import { getLayerPadding } from '@/bridge/poolLayout/layerTemplate';
import { NodeCategory, GuideCategory } from '@/bridge/enums/partCategories';
import { isShape } from '@/bridge/settings/shapeSettings';
import { DiagramSettings } from '@/bridge/types/diagramModel';

// define a custom ResizingTool to limit how far one can shrink a lane Group
class LaneResizingTool extends go.ResizingTool {
  maxSize: go.Size;

  constructor() {
    super();

    this.maxSize = new go.Size(NaN, NaN);
  }

  isLengthening() {
    return (this.handle as go.GraphObject).alignment === go.Spot.Right;
  }

  /** @override */
  computeMinSize() {
    const lane = (this.adornedObject as go.GraphObject).part as go.Group;

    let msz = computeMinLaneSize(lane); // get the absolute minimum size
    if (this.isLengthening()) {
      // compute the minimum length of all lanes
      if (lane.data.category === NodeCategory.LAYER) {
        const sz = computeMinPoolSize(lane.containingGroup as go.Group);
        msz.width = Math.max(msz.width, sz.width);
      } else {
        msz = go.Size.parse(lane.data.minSize);
      }
    } else {
      // find the minimum size of this single lane
      // eslint-disable-next-line no-lonely-if
      if (lane.data.category === NodeCategory.LAYER) {
        const sz = computeLaneSize(lane);
        msz.width = Math.max(msz.width, sz.width);
        msz.height = Math.max(msz.height, sz.height);
      } else {
        msz = go.Size.parse(lane.data.minSize);
      }
    }
    return msz;
  }

  /** @override */
  resize(newr: go.Rect) {
    const { diagram } = this;
    if (diagram === null) return;
    diagram.selection.each((part) => {
      // only Nodes and shapes
      if (
        part instanceof go.Link
        || part instanceof go.Group
        || part.category === GuideCategory.H_GUIDE
        || part.category === GuideCategory.V_GUIDE
      ) return;

      const obj = part.resizeObject;

      // calculate new location
      const pos = part.position.copy();
      const angle = obj.getDocumentAngle();
      const sc = obj.getDocumentScale();

      const radAngle = (Math.PI * angle) / 180;
      const angleCos = Math.cos(radAngle);
      const angleSin = Math.sin(radAngle);

      const deltaWidth = newr.width - obj.naturalBounds.width;
      const deltaHeight = newr.height - obj.naturalBounds.height;

      const angleBottom = (angle > 0 && angle < 180) ? 1 : 0;
      const angleLeft = (angle > 90 && angle < 270) ? 1 : 0;
      const angleTop = (angle > 180 && angle < 360) ? 1 : 0;

      // eslint-disable-next-line max-len
      pos.x += sc * ((newr.x + deltaWidth * angleLeft) * angleCos - (newr.y + deltaHeight * angleBottom) * angleSin);
      // eslint-disable-next-line max-len
      pos.y += sc * ((newr.x + deltaWidth * angleTop) * angleSin + (newr.y + deltaHeight * angleLeft) * angleCos);

      obj.desiredSize = newr.size;
      part.position = pos;
    });

    if (!this.adornedObject || !this.adornedObject.part) return;

    const lane = (this.adornedObject).part;
    const shape = lane.resizeObject;
    const intRect = new go.Rect(
      Math.round(newr.x),
      Math.round(newr.y),
      Math.round(newr.width),
      Math.round(newr.height),
    );

    if (this.isLengthening()) {
      // changing the length of all the lanes resize node
      if (lane.data.category !== NodeCategory.LAYER) {
        if (shape !== null) {
          // set its desiredSize length, but leave each breadth alone
          shape.width = intRect.width;
        }
      } else if (lane.data.category === NodeCategory.LAYER) {
        const diff = intRect.width - lane.resizeObject.width;
        const newX = Math.round(this.diagram.position.x + diff);
        this.diagram.position = new go.Point(
          newX,
          Math.round(this.diagram.position.y),
        );

        // resize layer and along with the other layers in the pool
        (lane.containingGroup as go.Group).memberParts.each((l) => {
          if (l instanceof go.Link) {
            return;
          }
          if (l.resizeObject !== null) {
            // set its desiredSize length, but leave each breadth alone
            // eslint-disable-next-line no-param-reassign
            l.resizeObject.width = intRect.width;
          }
        });
      }
    } else {
      // changing the breadth of a single lane
      const vpb = this.diagram.viewportBounds;
      const { category } = shape.part?.data;

      if (isShape(category)) {
        const root = (shape.diagram as go.Diagram)
          .findTopLevelGroups()
          .filter((g: go.Group) => g.data.category === NodeCategory.ROOT)
          .first();

        const rootBounds = (root as go.Group).getDocumentBounds();
        const { location } = shape.part as go.Part;
        const { width, height } = (shape.part as go.Part).resizeObject;
        let outside = false;

        const { width: gridWidth, height: gridHeight } = this.diagram.grid.gridCellSize;
        const layerPadding = getLayerPadding({
          gridWidth,
          gridHeight,
        } as DiagramSettings);

        if (location.x + width - layerPadding.right > rootBounds.right) {
          // left
          outside = true;
        }
        // 40 - the layer label width
        if (location.x + layerPadding.left + 40 < rootBounds.left) {
          // right
          outside = true;
        }
        if (location.y - layerPadding.top < rootBounds.top) {
          // top
          outside = true;
        }
        if (location.y + height + layerPadding.bottom > rootBounds.bottom) {
          // bottom
          outside = true;
        }
        if (outside) {
          (shape.diagram as go.Diagram).currentTool.doCancel();
          return;
        }
      }
      if (
        lane.data.category === NodeCategory.LAYER
        && intRect.height > vpb.height
      ) {
        const diff = intRect.height - lane.resizeObject.height;
        const newY = Math.round(this.diagram.position.y + diff);

        this.diagram.position = new go.Point(
          Math.round(this.diagram.position.x),
          newY,
        );
        shape.height = intRect.height;
      } else {
        go.ResizingTool.prototype.resize.call(this, intRect);
      }
    }

    reLayoutDiagram(this.diagram); // now that the lane has changed size, layout the pool again
  }
}

const lrt: go.ResizingTool = new LaneResizingTool();
export default lrt;
