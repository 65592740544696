




















































































































































import Vue, { PropType } from 'vue';
import ApiService from '@/services/api.service';
import { AssetResource, ConnectorResource, ResourceWithData, ResourceType } from '@/types/resource';
import AppCard from '@/components/card/AppCard.vue';
import LayerBadge from '@/components/LayerBadge.vue';
import PageSpinner from '@/components/PageSpinner.vue';
import ApiError from '@/components/ApiError.vue';
import ViewFormContent from '@/views/forms/ViewFormContent.vue';
import DetailsCardRow from '@/components/card/DetailsCardRow.vue';
import PreviewResource from '@/views/resources/PreviewResource.vue';
import ResourceForm from '@/views/resources/ResourceForm.vue';
import ResourceDataForm from '@/views/resources/resourceData/ResourceDataForm.vue';
import DeleteResource from '@/views/resources/DeleteResource.vue';
import DeleteResourceData from '@/views/resources/resourceData/DeleteResourceData.vue';

export default Vue.extend({
  name: 'ViewResource',
  components: {
    DeleteResourceData,
    DeleteResource,
    ResourceDataForm,
    ResourceForm,
    PreviewResource,
    DetailsCardRow,
    ViewFormContent,
    ApiError,
    PageSpinner,
    LayerBadge,
    AppCard,
  },
  props: {
    readonly: Boolean,
    selectedResourceId: String,
    resourceType: {
      type: String as PropType<ResourceType>,
      required: true,
    },
  },
  computed: {
    resourceId(): string {
      if (this.selectedResourceId) {
        return this.selectedResourceId;
      }
      return this.$route.params.resourceId;
    },
    projectId(): string {
      return this.$route.params.projectId;
    },
    isAssetResource(): boolean {
      return this.resourceType === ResourceType.ASSET;
    },
    isDependency(): boolean {
      return this.resourceType === ResourceType.DEPENDENCY;
    },
    usedByTotal(): string {
      const { usedByTotal } = this.resource;

      switch (usedByTotal) {
        case 0:
          return 'none';
        case 1:
          return 'one';
        default:
          return `${usedByTotal}`;
      }
    },
  },
  data() {
    return {
      resource: {} as AssetResource|ConnectorResource,
      options: [] as any[],
      resourceData: {} as ResourceWithData,
      selectedAssetData: '',
      error: '',
      loading: false,
    };
  },
  methods: {
    async loadResource() {
      this.loading = true;
      try {
        this.resource = await ApiService.get(`/project/${this.projectId}/assets/${this.resourceId}`);
      } catch {
        this.error = 'Could not load this resource. Please try again later';
      }
      this.$emit('setResource', this.resource);
      this.loading = false;
    },
    async loadState(id: string) {
      this.resourceData = await ApiService.get(`/project/${this.projectId}/assets/${this.resourceId}/states/${id}`);
    },
    async loadDataByState(modellingStateId?: string) {
      const response = await ApiService.get(`/project/${this.projectId}/assets/${this.resourceId}/states/info`);
      if (response.results?.length) {
        let id = '';
        if (modellingStateId) {
          id = response.results
            .filter((ms: { id: string }) => ms.id === modellingStateId)[0]?.id;
        } else {
          id = response.results[0]?.id;
        }

        this.options = response.results;
        this.selectedAssetData = id;
        this.resourceData = await ApiService.get(`/project/${this.projectId}/assets/${this.resourceId}/states/${id}`);
      } else {
        this.resourceData = {} as ResourceWithData;
      }
    },
  },
  async created() {
    await this.loadResource();
    await this.loadDataByState();
  },
});
