































































import Vue, { PropType } from 'vue';
import LayerBadge from '@/components/LayerBadge.vue';
import DetailsCardRow from '@/components/card/DetailsCardRow.vue';
import { AssetResource, ConnectorResource } from '@/types/resource';
import Btn from '@/components/buttons/Btn.vue';

export default Vue.extend({
  name: 'PreviewResource',
  components: {
    Btn,
    LayerBadge,
    DetailsCardRow,
  },
  props: {
    withAvatar: Boolean,
    resource: {
      type: Object as PropType<AssetResource|ConnectorResource>,
      required: true,
    },
  },
  computed: {
    viewPath() {
      const { type, id } = this.resource;
      const { orgId, projectId } = this.$route.params;
      return `/org/${orgId}/project/${projectId}/${type}/${id}`;
    },
    avatar() {
      if (this.resource.type === 'asset') {
        return {
          icon: 'square',
          color: 'primary',
        };
      }

      if (this.resource.type === 'dependency') {
        return {
          icon: 'arrow_downward',
          color: 'red',
        };
      }

      return {
        icon: 'arrow_downward',
        color: 'primary',
      };
    },
  },
});
