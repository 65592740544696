import * as go from 'gojs';
import { GuideCategory } from '@/bridge/enums/partCategories';
import { AllDiagramSettings, NodeSize, templateFn } from '@/bridge/types/diagramModel';
import { guideResizeAdornment } from '@/bridge/guides/guideResizeAdornment';
import { DiagramMode } from '@/bridge/enums/diagramOptions';
import { locToGoPoint, pointToNodeLoc } from '@/bridge/util/shared';

const $ = go.GraphObject.make;

function getTemplate(md: AllDiagramSettings, viewMode: boolean, isVertical: boolean) {
  const adornmentSpots = {
    // top/left resize handle
    first: isVertical ? go.Spot.Top : go.Spot.Left,
    // bottom/right resize handle
    last: isVertical ? go.Spot.Bottom : go.Spot.Right,
  };

  return $(go.Node, 'Spot',
    {
      visible: !viewMode,
      resizable: true,
      resizeObjectName: 'SHAPE',
      dragComputation(part: any, pt: go.Point, gridPt: go.Point) {
        const { diagram } = part;
        if (diagram === null) return gridPt;

        const r: go.Rect = part.actualBounds;
        const gp = gridPt.copy();
        if (part.category === GuideCategory.V_GUIDE) {
          gp.x = gridPt.x - r.width / 2;
        }
        if (part.category === GuideCategory.H_GUIDE) {
          gp.y = gridPt.y - r.height / 2;
        }

        return gp;
      },
      resizeAdornmentTemplate:
        $(go.Adornment, 'Spot',
          $(go.Placeholder),
          guideResizeAdornment(adornmentSpots.first),
          guideResizeAdornment(adornmentSpots.last)),
    },
    new go.Binding('location', 'location', locToGoPoint).makeTwoWay(pointToNodeLoc),
    new go.Binding('minLocation', '', () => new go.Point(md.diagram.gridWidth, 0)),
    $(go.Panel, isVertical ? 'Vertical' : 'Horizontal',
      {
        alignment: go.Spot.Center,
        background: 'transparent',
        mouseEnter(e: go.InputEvent, part: go.GraphObject) {
          (part as go.Part).background = 'rgba(0, 0, 0, 0.2)';
        },
        mouseLeave(e: go.InputEvent, part: go.GraphObject) {
          (part as go.Part).background = 'transparent';
        },
      },
      $(go.Shape, 'Rectangle',
        {
          isPanelMain: true,
          name: 'SHAPE',
          fill: '#b8e994',
          stroke: null,
          strokeWidth: 0,
          width: isVertical ? 1 : 1000,
          height: isVertical ? 1000 : 1,
          minSize: new go.Size(isVertical ? 1 : 100, isVertical ? 100 : 1),
          maxSize: new go.Size(isVertical ? 1 : Infinity, isVertical ? Infinity : 1),
        },
        new go.Binding('fill', '', () => md.diagram.guidesColor),
        new go.Binding('desiredSize', 'size', (s: NodeSize) => new go.Size(s.width, s.height))
          .makeTwoWay((s: go.Size) => ({ width: s.width, height: s.height }))),
      $(go.TextBlock,
        {
          editable: true,
          angle: isVertical ? 270 : 0,
          alignment: isVertical ? go.Spot.Bottom : go.Spot.Right,
          alignmentFocus: isVertical ? go.Spot.Top : go.Spot.Left,
        },
        new go.Binding('text', 'size', (s: NodeSize) => (isVertical ? `${s.height}px` : `${s.width}px`)))));
}

const addTemplate: templateFn = (diagram: go.Diagram, options, modelData) => {
  const viewMode = options.mode === DiagramMode.VIEW;

  diagram.nodeTemplateMap.add(GuideCategory.H_GUIDE, getTemplate(modelData, viewMode, false));
  diagram.nodeTemplateMap.add(GuideCategory.V_GUIDE, getTemplate(modelData, viewMode, true));
};

export default {
  addTemplate,
};
