import * as go from 'gojs';

const $ = go.GraphObject.make;

function circleTemplate(spot: go.Spot): go.Shape {
  return $(go.Shape, 'Circle', {
    fill: '#3498db',
    stroke: '#2980b9',
    cursor: 'col-resize',
    alignment: spot,
    desiredSize: new go.Size(10, 10),
  });
}

export default function elementResizeAdornmentTemplate() {
  return $(
    go.Adornment,
    'Spot',
    $(go.Placeholder),
    // top-left
    circleTemplate(new go.Spot(0, 0, 2, 2)),
    // top-right
    circleTemplate(new go.Spot(1, 0, -2, 2)),
    // bottom-right
    circleTemplate(new go.Spot(1, 1, -2, -2)),
    // bottom-left
    circleTemplate(new go.Spot(0, 1, 2, -2)),
  );
}
