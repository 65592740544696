<template>
  <div>
    <div v-for="(el, index) in elements" :key="`group-${index}`">
      <template v-if="el.fields">
        <form-group-list-item :group="el" :can-edit="false" :can-delete="false">
          <ResourceFields
            @input="$emit('input', $event)"
            v-if="el.fields"
            is-field
            :elements="el.fields"
            :group-index="index"
            :form-id="formId"
          />
        </form-group-list-item>
      </template>

      <template v-else>
        <FormInputPreview @input="onInput($event, index, el.type)" :field="el"/>
      </template>
    </div>

    <!-- Show drop area if the group is empty  -->
    <div v-if="isField && !elements.length" class="drop-area">
      <em class="block q-mt-md">
        This group is empty. Add a new field or drop fields from other groups here.
      </em>
    </div>
  </div>
</template>

<script>
import FormGroupListItem from '@/components/dataForm/FormGroupListIItem.vue';
import FormInputPreview from '@/components/formInputPreview/FormInputPreview.vue';
import { fieldTypes } from '@/components/formInputConfig/fields';
import { getTimestampFromDateString } from '@/util/date';

export default {
  name: 'ResourceFields',
  components: {
    FormInputPreview,
    FormGroupListItem,
  },
  props: {
    elements: {
      required: true,
      type: Array,
    },
    isField: Boolean,
    formId: String,
    groupIndex: Number,
  },
  methods: {
    onInput(value, fieldIndex, fieldType) {
      let val = value;

      if (fieldType === fieldTypes.NUMBER) {
        val = parseFloat(value);
        if (Number.isNaN(val)) {
          val = null;
        }
      }

      if (fieldType === fieldTypes.DATE && typeof val === 'string') {
        val = getTimestampFromDateString(val);
      }

      this.$emit('input', {
        value: val,
        fieldIndex,
        groupIndex: this.groupIndex,
        formId: this.formId,
      });
    },
  },
};
</script>
