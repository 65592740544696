export enum SelectionType {
  NONE = '',
  NODE = 'node',
  LINK = 'link',
  SHAPE = 'shape',
  COMMENT = 'comment'
}

export type CanvasSelection = {
  selection: any[],
  type: SelectionType,
};
