// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/*eslint-disable */
import * as go from 'gojs';
import { LinkCategory } from '@/bridge/enums/partCategories';
/*
 *  Copyright (C) 1998-2019 by Northwoods Software Corporation. All Rights Reserved.
 */

// A custom Tool for shifting the end point of a Link to be anywhere along the edges of the port.

/**
 * This constructor produces a tool for shifting the end of a link;
 * use it in a bit.toolManager.mouseDownTools list:
 * <pre>myDiagram.toolManager.mouseDownTools.add(new LinkShiftingTool());</pre>
 * @constructor
 * @extends Tool
 * @class
 */
function LinkShiftingTool() {
  go.Tool.call(this);
  this.name = 'LinkShifting';

  /** @type {Size} */
  this._gridCellSize = new go.Size(NaN, NaN);
  /** @type {Point} */
  this._gridOrigin = new go.Point(NaN, NaN);
  /** @type {boolean} */
  this._isGridSnapEnabled = true;

  // these are archetypes for the two shift handles, one at each end of the Link:
  let h = new go.Shape();
  h.geometryString = 'F1 M0 0 L8 0 M8 4 L0 4';
  h.fill = null;
  h.stroke = 'dodgerblue';
  h.background = 'lightblue';
  h.cursor = 'pointer';
  h.segmentIndex = 0;
  h.segmentFraction = 1;
  h.segmentOrientation = go.Link.OrientAlong;

  /** @type {GraphObject} */
  this._fromHandleArchetype = h;

  h = new go.Shape();
  h.geometryString = 'F1 M0 0 L8 0 M8 4 L0 4';
  h.fill = null;
  h.stroke = 'dodgerblue';
  h.background = 'lightblue';
  h.cursor = 'pointer';
  h.segmentIndex = -1;
  h.segmentFraction = 1;
  h.segmentOrientation = go.Link.OrientAlong;
  /** @type {GraphObject} */
  this._toHandleArchetype = h;

  // transient state
  /** @type {GraphObject} */
  this._handle = null;
  /** @type {List} */
  this._originalPoints = null;
}
go.Diagram.inherit(LinkShiftingTool, go.Tool);

/**
 * Gets or sets the {@link Size} of each grid cell to which link points will be snapped.
 * The default value is NaNxNaN, which means use the {@link Diagram#grid}'s {@link Panel#gridCellSize}.
 * @name LinkShiftingTool#gridCellSize
 * @function.
 * @return {Size}
 */
Object.defineProperty(LinkShiftingTool.prototype, 'gridCellSize', {
  get: function () {
    return this._gridCellSize;
  },
  set: function (val) {
    if (!(val instanceof go.Size))
      throw new Error(
        'new value for LinkShiftingTool.gridCellSize must be a Size, not: ' +
          val,
      );
    this._gridCellSize = val.copy();
  },
});

/**
 * Gets or sets the {@link Point} origin for the grid to which link points will be snapped.
 * The default value is NaN,NaN, which means use the {@link Diagram#grid}'s {@link Panel#gridOrigin}.
 * @name LinkShiftingTool#gridOrigin
 * @function.
 * @return {Point}
 */
Object.defineProperty(LinkShiftingTool.prototype, 'gridOrigin', {
  get: function () {
    return this._gridOrigin;
  },
  set: function (val) {
    if (!(val instanceof go.Point))
      throw new Error(
        'new value for LinkShiftingTool.gridOrigin must be a Point, not: ' +
          val,
      );
    this._gridOrigin = val.copy();
  },
});

/**
 * Gets or sets whether a reshape handle's position should be snapped to a grid point.
 * The default value is true.
 * This affects the behavior of {@link #computeReshape}.
 */
Object.defineProperty(LinkShiftingTool.prototype, 'isGridSnapEnabled', {
  get: function () {
    return this._isGridSnapEnabled;
  },
  set: function (val) {
    if (typeof val !== 'boolean')
      throw new Error(
        'new value for LinkShiftingTool.isGridSnapEnabled must be a boolean, not: ' +
          val,
      );
    this._isGridSnapEnabled = val;
  },
});

/*
 * A small GraphObject used as a shifting handle.
 * @name LinkShiftingTool#fromHandleArchetype
 * @function.
 * @return {GraphObject}
 */
Object.defineProperty(LinkShiftingTool.prototype, 'fromHandleArchetype', {
  get: function () {
    return this._fromHandleArchetype;
  },
  set: function (value) {
    this._fromHandleArchetype = value;
  },
});

/*
 * A small GraphObject used as a shifting handle.
 * @name LinkShiftingTool#toHandleArchetype
 * @function.
 * @return {GraphObject}
 */
Object.defineProperty(LinkShiftingTool.prototype, 'toHandleArchetype', {
  get: function () {
    return this._toHandleArchetype;
  },
  set: function (value) {
    this._toHandleArchetype = value;
  },
});

LinkShiftingTool.prototype.updateAdornments = function (part) {
  if (!(part instanceof go.Link)) {
    return;
  }

  if (
    part.pointsCount <= 4 &&
    (part.routing === go.Link.None || part.routing === go.Link.Normal)
  ) {
    part.removeAdornment('LinkShiftingFrom');
    part.removeAdornment('LinkShiftingTo');
    return;
  }
  if (part.canShift !== undefined && !part.canShift) {
    return;
  }

  if (part === null || !(part instanceof go.Link)) return; // this tool only applies to Links
  var link = part;
  // show handles if link is selected, remove them if no longer selected
  var category = 'LinkShiftingFrom';
  var adornment = null;
  if (link.isSelected && !this.diagram.isReadOnly) {
    var selelt = link.selectionObject;
    if (
      selelt !== null &&
      link.actualBounds.isReal() &&
      link.isVisible() &&
      selelt.actualBounds.isReal() &&
      selelt.isVisibleObject()
    ) {
      var spot = link.computeSpot(true);
      adornment = link.findAdornment(category);
      if (adornment === null) {
        adornment = this.makeAdornment(selelt, false);
        adornment.category = category;
        link.addAdornment(category, adornment);
      }
      adornment.elt(0).segmentFraction = link.fromSpot.isSpot() ? 1 : 0.1;
    }
  }
  if (adornment === null) link.removeAdornment(category);

  category = 'LinkShiftingTo';
  adornment = null;
  if (link.isSelected && !this.diagram.isReadOnly) {
    var selelt = link.selectionObject;
    if (
      selelt !== null &&
      link.actualBounds.isReal() &&
      link.isVisible() &&
      selelt.actualBounds.isReal() &&
      selelt.isVisibleObject()
    ) {
      var spot = link.computeSpot(false);
      adornment = link.findAdornment(category);
      if (adornment === null) {
        adornment = this.makeAdornment(selelt, true);
        adornment.category = category;
        link.addAdornment(category, adornment);
      }
      adornment.elt(0).segmentFraction = link.toSpot.isSpot() ? 1 : 0.1;
    }
  }
  if (adornment === null) link.removeAdornment(category);
};

/**
 * @this {LinkShiftingTool}
 * @param {GraphObject} selelt the {@link GraphObject} of the {@link Link} being shifted.
 * @param {boolean} toend
 * @return {Adornment}
 */
LinkShiftingTool.prototype.makeAdornment = function (selelt, toend) {
  var adornment = new go.Adornment();
  adornment.type = go.Panel.Link;
  var h = toend ? this.toHandleArchetype : this.fromHandleArchetype;
  if (h !== null) {
    // add a single handle for shifting at one end
    adornment.add(h.copy());
  }
  adornment.adornedObject = selelt;
  return adornment;
};

/**
 * @this {LinkShiftingTool}
 * @return {boolean}
 */
LinkShiftingTool.prototype.canStart = function () {
  if (!this.isEnabled) return false;
  var diagram = this.diagram;
  if (diagram === null || diagram.isReadOnly || diagram.isModelReadOnly)
    return false;
  if (!diagram.lastInput.left) return false;
  var h = this.findToolHandleAt(
    diagram.firstInput.documentPoint,
    'LinkShiftingFrom',
  );
  if (h === null)
    h = this.findToolHandleAt(
      diagram.firstInput.documentPoint,
      'LinkShiftingTo',
    );
  return h !== null;
};

/**
 * @this {LinkShiftingTool}
 */
LinkShiftingTool.prototype.doActivate = function () {
  var diagram = this.diagram;
  if (diagram === null) return;
  var h = this.findToolHandleAt(
    diagram.firstInput.documentPoint,
    'LinkShiftingFrom',
  );
  if (h === null)
    h = this.findToolHandleAt(
      diagram.firstInput.documentPoint,
      'LinkShiftingTo',
    );
  if (h === null) return;
  var ad = h.part;
  var link = ad.adornedObject.part;
  if (!(link instanceof go.Link)) return;

  this._handle = h;
  this._originalPoints = link.points.copy();
  this.startTransaction(this.name);
  diagram.isMouseCaptured = true;
  diagram.currentCursor = 'pointer';
  this.isActive = true;
};

/**
 * @this {LinkShiftingTool}
 */
LinkShiftingTool.prototype.doDeactivate = function () {
  this.isActive = false;
  var diagram = this.diagram;
  if (diagram === null) return;

  diagram.isMouseCaptured = false;
  diagram.currentCursor = '';
  this.stopTransaction();
};

/**
 * Clean up tool state.
 * @this {LinkShiftingTool}
 */
LinkShiftingTool.prototype.doStop = function () {
  this._handle = null;
  this._originalPoints = null;
};

/**
 * Clean up tool state.
 * @this {LinkShiftingTool}
 */
LinkShiftingTool.prototype.doCancel = function () {
  var ad = this._handle.part;
  var link = ad.adornedObject.part;
  link.points = this._originalPoints;
  this.stopTool();
};

/**
 * @this {LinkShiftingTool}
 */
LinkShiftingTool.prototype.doMouseMove = function () {
  if (this.isActive) {
    this.doReshape(this.diagram.lastInput.documentPoint);
  }
};

/**
 * @this {LinkShiftingTool}
 */
LinkShiftingTool.prototype.doMouseUp = function () {
  if (this.isActive) {
    this.doReshape(this.diagram.lastInput.documentPoint);
    this.transactionResult = this.name;
  }
  this.stopTool();
};

function getLimits(port, cell, direction) {
  // Divide node width / height by grid width/height
  const divisions = port[direction] / cell[direction];
  // 100% divided by total grid divisions
  const low = 100 / divisions / 100;
  const high = low * (divisions - 1);

  return { low, high };
}

LinkShiftingTool.prototype.doReshape = function (pt) {
  var ad = this._handle.part;
  var link = ad.adornedObject.part;
  var fromend = ad.category === 'LinkShiftingFrom';
  var port = null;

  if (fromend) {
    port = link.fromPort;
  } else {
    port = link.toPort;
  }

  var portb = new go.Rect(
    port.getDocumentPoint(go.Spot.TopLeft),
    port.getDocumentPoint(go.Spot.BottomRight),
  );
  var lp = link.getLinkPointFromPoint(
    port.part,
    port,
    port.getDocumentPoint(go.Spot.Center),
    pt,
    fromend,
  );
  var lpNoGridSnap = lp.copy();

  if (this.isGridSnapEnabled && link.curve !== go.Link.Bezier) {
    // first, find the grid to which we should snap
    var cell = this.gridCellSize;
    var orig = this.gridOrigin;
    if (!cell.isReal() || cell.width === 0 || cell.height === 0)
      cell = this.diagram.grid.gridCellSize;
    if (!orig.isReal()) orig = this.diagram.grid.gridOrigin;
    // second, compute the closest grid point
    lp = lp.copy().snapToGrid(orig.x, orig.y, cell.width, cell.height);
  }

  let spot = new go.Spot(
    (lp.x - portb.x) / (portb.width || 1),
    (lp.y - portb.y) / (portb.height || 1),
  );
  let xNoSnap = (lpNoGridSnap.x - portb.x) / portb.width;
  let yNoSnap = (lpNoGridSnap.y - portb.y) / portb.height;

  if (link.data.category !== LinkCategory.DEPENDENCY) {
    const xLimits = getLimits(portb, cell, 'width');
    const yLimits = getLimits(portb, cell, 'height');

    // top-left
    if (xNoSnap < xLimits.low && yNoSnap === 0) {
      // top-left-top
      spot = new go.Spot(0.0001, 0);
    } else if (xNoSnap === 0 && yNoSnap < yLimits.low - 0.1111) {
      // top-left-left
      spot = new go.Spot(0, 0.0001);
    }

    // top-right
    if (xNoSnap > xLimits.high && yNoSnap === 0) {
      // top-right-top
      spot = new go.Spot(0.9999, 0);
    } else if (xNoSnap === 1 && yNoSnap < yLimits.low - 0.1111) {
      // top-right-right
      spot = new go.Spot(1, 0.0001);
    }

    // bottom-left
    if (xNoSnap === 1 && yNoSnap > yLimits.high + 0.1111) {
      // bottom-left-left
      spot = new go.Spot(1, 0.9999);
    } else if (xNoSnap > xLimits.high && yNoSnap === 1) {
      // bottom-left-bottom
      spot = new go.Spot(0.9999, 1);
    }
    // bottom-right
    if (xNoSnap < xLimits.low && yNoSnap === 1) {
      // bottom-right-bottom
      spot = new go.Spot(0.0001, 1);
    } else if (xNoSnap === 0 && yNoSnap > yLimits.high + 0.1111) {
      // bottom-left-left
      spot = new go.Spot(0, 0.9999);
    }
  }
  // console.log(spot.toString());
  if (spot.x > 0.0001 && spot.x < 0.9999) {
    if (spot.y < 0.5) {
      spot.y = 0;
    } else {
      spot.y = 1;
    }
  }

  if (fromend) {
    link.fromSpot = spot;
  } else {
    link.toSpot = spot;
  }
};

export default LinkShiftingTool;
/* eslint-enable */
