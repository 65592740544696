














































import Vue, { PropType } from 'vue';
import { layerUtils } from '@/bridge/base/layerInfo';
import notify from '@/util/notify';
import Btn from '@/components/buttons/Btn.vue';
import ListTooltip from '@/components/list/ListTooltip.vue';
import LayerBadge from '@/components/LayerBadge.vue';
import SelectCandidateConnector from '@/views/dav/SelectCandidateConnector.vue';
import {
  DavCandidates,
  DavCandidateAsset,
  DavCandidateConnector, ElementData,
} from '@/bridge/types/diagramModel';
import ViewResourceModal from '@/views/resources/ViewResourceModal.vue';

export default Vue.extend({
  name: 'SelectCandidate',
  components: {
    ViewResourceModal,
    SelectCandidateConnector,
    Btn,
    LayerBadge,
    ListTooltip,
  },
  props: {
    selectedAssets: {
      type: Array as PropType<ElementData[]>,
      required: true,
    },
    candidates: {
      type: Object as PropType<DavCandidates>,
      required: true,
    },
  },
  computed: {
    message(): string {
      const { assets } = this.candidates;
      if (!assets || !assets.length) {
        return 'The dataflow for this asset stops here.';
      }
      return '';
    },
    assets(): null | Record<string, DavCandidateAsset[]> {
      const { assets } = this.candidates;
      const tmp: Record<string, DavCandidateAsset[]> = {};

      if (assets && assets.length) {
        assets.forEach((a) => {
          if (!tmp[layerUtils.getName(a.layer)]) {
            tmp[layerUtils.getName(a.layer)] = [];
          }
          tmp[layerUtils.getName(a.layer)].push(a);
        });
      }

      return tmp;
    },
  },
  data() {
    return {
      selectedCandidate: null as null | DavCandidateAsset,
    };
  },
  methods: {
    onSelectAsset(asset: DavCandidateAsset) {
      if (asset.links) {
        if (asset.links.length > 1) {
          // The user will need to select the connector first;
          this.selectedCandidate = asset;
        } else {
          this.$emit('select', {
            asset,
            connector: asset.links[0],
          });
        }
      }
    },
    onSelectConnector(connector: DavCandidateConnector|null) {
      if (!connector) {
        notify.info('Connector could not be created. Please try again later.');
      } else {
        this.$emit('select', {
          connector,
          asset: this.selectedCandidate,
        });
      }

      this.selectedCandidate = null;
    },
  },
});
