import { Link } from 'gojs';
import * as go from 'gojs';
import { ConnectorResourceRequest, ResourceType } from '@/types/resource';
import ApiService from '@/services/api.service';
import notify from '@/util/notify';
import { SearchResponse } from '@/types/list';
import { diagramEvents } from '@/bridge/events/diagramEvents';
import { SelectionType, CanvasSelection } from '@/bridge/types/canvasSelection';

export type ConnectorAssets = {
  from: string;
  to: string;
};

export type CheckedLink = {
  connectors: SearchResponse,
  assets: ConnectorAssets,
  link: go.Link,
}

export function getConnectorAssets(link: Link): ConnectorAssets {
  const { fromNode, toNode } = link;
  if (!fromNode || !toNode) {
    return { from: '', to: '' };
  }

  return {
    from: fromNode.data.assetId || '',
    to: toNode.data.assetId || '',
  };
}

function getNewConnectorData(link: Link): ConnectorResourceRequest {
  const assets = getConnectorAssets(link);
  const data: ConnectorResourceRequest = {
    label: '',
    description: '',
    from: '',
    to: '',
    // @TODO - does BE support this?
    // status: DiagramStatus.DRAFT,
    type: link.category as ResourceType,
  };

  const { fromNode, toNode } = link;
  if (!fromNode || !toNode) return data;

  let label = `${fromNode.data.label} is connected with ${toNode.data.label}`;
  if (link.category === ResourceType.DEPENDENCY) {
    label = `${toNode.data.label} is dependent on ${fromNode.data.label}`;
  }

  return {
    ...data,
    label,
    from: assets.from,
    to: assets.to,
  };
}

export function updateLinkData(link: Link, data: { id: string; label: string; }) {
  if (!link.diagram) return;

  link.diagram.commit((diag) => {
    diag.model.set(link.data, 'assetId', data.id);
    diag.model.set(link.data, 'label', data.label);
  }, null);
}

export async function createNewConnector(link: Link, url: string) {
  const newConnectorData = getNewConnectorData(link);

  if (!link.diagram || !newConnectorData.from || !newConnectorData.to) {
    return;
  }

  /**
   * Try to create a connector when a link is drawn
   */
  try {
    const { id } = await ApiService.post(url, newConnectorData);

    updateLinkData(link, { id, label: newConnectorData.label });

    notify.info('A new connector was created');
    return;
  } catch {
    throw new Error('Could not create a connector');
  }
}

export function removeLinkFromCanvas(link: Link) {
  const { diagram } = link;
  if (!diagram) return;

  diagram.remove(link);
  diagram.undoManager.clear();
}

export function emitSelection(diagram: go.Diagram) {
  setTimeout(() => {
    diagramEvents.$emit(
      diagramEvents.SELECTION_CHANGED,
      {
        selection: diagram.selection.copy().toArray(),
        type: SelectionType.LINK,
      } as CanvasSelection,
    );
  }, 100);
}

export function highlightPart(p: go.Part) {
  const { diagram } = p;
  if (!diagram) return;

  diagram.commit(() => {
    diagram.clearHighlighteds();
    diagram.highlight(p);
    diagram.centerRect(p.actualBounds);
    diagram.model.set(diagram.model.modelData, 'selectLinks', Date.now());
  }, null);
}

export function clearHighlighted(diagram: go.Diagram) {
  diagram.commit(() => {
    diagram.clearHighlighteds();
    diagram.model.set(diagram.model.modelData, 'selectLinks', 0);
  }, null);
}
