











































import Vue, { PropType } from 'vue';
import { Diagram } from 'gojs';
import { DiagramModel } from '@/types/diagram';
import { ExportOptions } from '@/bridge/print/printUtils';
import { downloadDiagram } from '@/bridge/print/downloadDiagram';
import AppModal from '@/components/AppModal.vue';
import PageSpinner from '@/components/PageSpinner.vue';
import DiagramCanvas from '@/views/diagram/DiagramCanvas.vue';
import DiagramLegend from '@/views/diagram/DiagramLegend.vue';
import ExportDiagramOptions from '@/views/diagram/toolbar/ExportDiagramOptions.vue';
import DiagramToolbarBtn from '@/views/diagram/toolbar/DiagramToolbarBtn.vue';
import { DiagramType } from '@/bridge/enums/diagramOptions';

export default Vue.extend({
  name: 'ExportDiagram',
  components: {
    DiagramToolbarBtn,
    DiagramLegend,
    AppModal,
    PageSpinner,
    DiagramCanvas,
    ExportDiagramOptions,
  },
  props: {
    diagramType: {
      type: String as PropType<DiagramType>,
      default: DiagramType.BIT,
    },
  },
  computed: {
    diagramDetails(): DiagramModel|null {
      return this.$store.getters['diagramModule/details'];
    },
  },
  data() {
    return {
      showLegend: true,
      diagramLoaded: false,
      diagramDivId: 'exportBit',
      legendDivId: 'exportLegend',
    };
  },
  methods: {
    openModal() {
      if (this.$refs.modal) {
        (this.$refs.modal as any).open();
      }
    },
    toggleLegend(show: boolean) {
      this.showLegend = show;
    },
    onCanvasLoaded() {
      this.diagramLoaded = true;
      setTimeout(() => {
        const d = Diagram.fromDiv(this.diagramDivId);
        if (d) {
          d.zoomToFit();
        }
      }, 100);
    },
    async onExport(options: ExportOptions) {
      if (!this.diagramDetails) return;

      options.diagramDivId = this.diagramDivId;
      options.legendDivId = this.legendDivId;

      await downloadDiagram(options, { ...this.diagramDetails });

      (this.$refs.exportDiagramOptions as any).loading = false;
    },
  },
});
