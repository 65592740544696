import * as go from 'gojs';
import { CanvasSelection } from '@/bridge/types/canvasSelection';
import { reLayoutDiagram } from '@/bridge/util/shared';

export function updateSelectionData(selected: CanvasSelection, key: string, value: any) {
  const [part] = selected.selection;
  if (!part) return;

  const { diagram }: { diagram: go.Diagram } = part;
  if (!diagram) return;

  diagram.commit((d) => {
    const m = d.model;

    selected.selection.forEach((p: go.Part) => {
      m.set(p.data, key, value);
    });
  }, null);

  reLayoutDiagram(diagram);
  diagram.updateAllTargetBindings();
}

export function clearDeletedPresets(diagram: go.Diagram, deletedPresets: string[]) {
  diagram.nodes.each((n) => {
    if (n.data.preset && deletedPresets.includes(n.data.preset)) {
      diagram.commit((d) => {
        d.model.set(n.data, 'preset', null);
      }, null);
    }
  });
}
