






























import Vue, { PropType } from 'vue';
import { Dav } from '@/bridge/dav/dav.v1';
import { DiagramModel, DiagramVersion } from '@/types/diagram';
import {
  DavCandidates,
  DavCandidateAsset,
  DavCandidateConnector, ElementData, LinkData,
} from '@/bridge/types/diagramModel';
import ApiService from '@/services/api.service';
import SelectAssetModal from '@/views/resources/SelectAssetModal.vue';
import SelectCandidate from '@/views/dav/SelectCandidate.vue';
import ApiError from '@/components/ApiError.vue';

export default Vue.extend({
  name: 'DavCandidateList',
  components: {
    ApiError,
    SelectCandidate,
    SelectAssetModal,
  },
  props: {
    dav: {
      type: Object as PropType<Dav>,
      required: true,
    },
  },
  computed: {
    diagramDetails(): DiagramModel|null {
      return this.$store.getters['diagramModule/details'];
    },
    version(): DiagramVersion|null {
      return this.$store.getters['diagramModule/version'];
    },
    davAssets(): ElementData[] {
      if (this.dav) {
        return this.dav.davAssets;
      }
      return [];
    },
    davConnectors(): LinkData[] {
      if (this.dav) {
        return this.dav.davConnectors;
      }
      return [];
    },
  },
  data() {
    return {
      candidates: null as null|DavCandidates,
      error: '',
    };
  },
  methods: {
    async selectProvider(asset: DavCandidateAsset) {
      if (this.dav) {
        this.dav.clear();
        this.dav.add(asset);
        await this.getCandidates(asset.id);
      }
    },
    async addCandidate(e: { asset: DavCandidateAsset, connector: DavCandidateConnector }) {
      if (this.dav) {
        await this.dav.add(e.asset, e.connector);
        await this.getCandidates(e.asset.id);
      }
    },
    async getCandidates(assetId: string) {
      this.candidates = null;
      this.error = '';

      try {
        const candidates = await ApiService.get(`/project/${this.$route.params.projectId}/diagram/candidates?assetId=${assetId}`);

        if (!candidates.assets || !candidates.assets.length) {
          // @TODO - handle sub-davs
          this.error = 'No candidates for the selected assets';
        } else {
          this.candidates = candidates;
        }
      } catch {
        this.error = 'There was an error. We could not get the candidates for the selected asset.';
      }
    },
  },
  async created() {
    const last = this.dav.lastNode;
    if (last) {
      await this.getCandidates(last.assetId);
    }
  },
});
