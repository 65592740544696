import * as go from 'gojs';
import { LinkCategory } from '@/bridge/enums/partCategories';
import { LinkData } from '@/bridge/types/diagramModel';

export async function createDependency(diagram: go.Diagram, fromTo: go.Node[]) {
  const [first, last] = fromTo;

  const data = {
    from: first.key,
    to: last.key,
    category: LinkCategory.DEPENDENCY,
    borderColor: diagram.model.modelData.dependency.borderColor,
  };

  diagram.model.commit((m) => {
    (m as go.GraphLinksModel).addLinkData(data);
    diagram.clearSelection();
  }, null);

  const l = diagram.findLinksByExample(data).first();
  if (l) {
    diagram.raiseDiagramEvent('LinkDrawn', l);
    diagram.select(l);
  }
}

export function reverseDependency(diagram: go.Diagram, linkData: LinkData, emitLinkDrawn = true) {
  diagram.model.commit((m) => {
    const link = diagram.findLinkForData(linkData);

    if (!link) return;

    const { data } = link;
    const { from, to, fromSpot, toSpot } = linkData;

    m.set(data, 'to', from);
    m.set(data, 'from', to);
    m.set(data, 'toSpot', fromSpot);
    m.set(data, 'fromSpot', toSpot);
  }, null);

  const link = diagram.findLinksByExample({ id: linkData.id }).first();

  if (link && emitLinkDrawn) {
    diagram.raiseDiagramEvent('LinkDrawn', link);
  }
}
