import * as go from 'gojs';
import { getDefaultDiagramSettings } from '@/bridge/settings/diagramSettings';

const $ = go.GraphObject.make;

// Define a function for creating a 'port' that is normally transparent.
// The 'name' is used as the GraphObject.portId,
// the 'align' is used to determine where to position the port relative to the body of the node,
// the 'spot' is used to control how links connect with the port and whether the port
// stretches along the side of the node,
// and the boolean 'output' and 'input' arguments control whether
// the user can draw links from or to the port.
export function makePort(
  name: string,
  align: go.Spot,
  spot: go.Spot,
  output: boolean,
  input: boolean,
  readonly = false,
): go.Shape {
  const horizontal = align.equals(go.Spot.Top) || align.equals(go.Spot.Bottom);
  // the port is basically just a transparent rectangle that
  // stretches along the side of the node,
  // and becomes colored when the mouse passes over it
  let size;
  if (horizontal) {
    size = new go.Size(NaN, 8);
  } else {
    size = new go.Size(8, NaN);
  }
  return $(go.Shape, {
    fill: 'transparent', // changed to a color in the mouseEnter event handler
    strokeWidth: 0, // no stroke
    desiredSize: size,
    margin: 1,
    alignment: align, // align the port on the main Shape
    stretch: horizontal ? go.GraphObject.Horizontal : go.GraphObject.Vertical,
    portId: name, // declare this object to be a 'port'
    fromSpot: spot, // declare where links may connect at this port
    fromLinkable: output, // declare whether the user may draw links from here
    toSpot: spot, // declare where links may connect at this port
    toLinkable: input, // declare whether the user may draw links to here
    cursor: readonly ? 'normal' : 'pointer',
    mouseEnter(e: go.InputEvent, port: go.GraphObject) {
      // the PORT argument will be this Shape
      if (!e.diagram.isReadOnly) {
        (port as go.Shape).fill = getDefaultDiagramSettings().portHoverColor;
      }
    },
    mouseLeave(e: go.InputEvent, port: go.GraphObject) {
      (port as go.Shape).fill = 'transparent';
    },
  });
}
