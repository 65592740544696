import * as go from 'gojs';
import { LinkCategory } from '@/bridge/enums/partCategories';
import { diagramEvents } from '@/bridge/events/diagramEvents';

export function addLinkDrawnEvent(diagram: go.Diagram) {
  diagram.addDiagramListener('LinkDrawn', (e) => {
    if (!(e.subject instanceof go.Link)) {
      diagramEvents.$emit(diagramEvents.LINK_DRAWN, e);
      return;
    }

    const link = (e.subject as go.Link);

    /**
     * This will  to allow drawing straight using ConnectionLink
     * then change the class to a normal link to allow linkShifting
     */
    setTimeout(async () => {
      if (link.category !== LinkCategory.DEPENDENCY) {
        const oldSkips = e.diagram.skipsUndoManager;

        e.diagram.skipsUndoManager = true;
        e.diagram.model.commit((m) => {
          m.setDataProperty(link.data, 'category', LinkCategory.CONNECTION);
        });
        e.diagram.skipsUndoManager = oldSkips;
      }

      diagramEvents.$emit(diagramEvents.LINK_DRAWN, e);
    }, 100);
  });
}
