import * as go from 'gojs';
import { AllDiagramSettings } from '@/bridge/types/diagramModel';

const $ = go.GraphObject.make;

function legendConnectionTemplate() {
  return $(
    go.Panel,
    'Horizontal',
    {
      column: 0,
      alignment: go.Spot.Center,
      padding: 2,
    },
    $(
      go.Panel,
      'Horizontal',
      $(
        go.Shape,
        'LineH',
        {
          width: 25,
          height: 15,
          stroke: 'transparent',
          margin: new go.Margin(0, 5, 0, 0),
        },
        new go.Binding('stroke', '', (m: AllDiagramSettings) => m.connection.borderColor).ofModel(),
        new go.Binding('fill', '', (m: AllDiagramSettings) => m.connection.borderColor).ofModel(),
      ),
      $(
        go.TextBlock, // the lane label
        {
          text: 'Connection',
          editable: false,
          margin: new go.Margin(2, 10, 0, 0),
          font: 'normal 14px DIN Next LT Pro',
        },
      ),
    ),
  );
}

function legendDependencyTemplate() {
  return $(
    go.Panel,
    'Horizontal',
    {
      alignment: go.Spot.Center,
      padding: 2,
      column: 2,
    },
    $(
      go.Panel,
      'Horizontal',
      $(go.Shape,
        {
          geometryString:
            'M2.4,27.6c7.9-10.3,47.8-56.1,97.5,1 x' // curved line
            + 'M91.7,26.8c0.1-0.3,0.3-0.4,0.6-0.3l7.4,2L98.4,21c0-0.3,0.1-0.5,0.4-0.6s0.5,0.1,0.6,0.4l1.4,8.3c0,0.2,0,0.3-0.2,0.5c-0.1,0.1-0.3,0.2-0.5,0.1l-8.1-2.2c-0.1,0-0.2-0.1-0.2-0.1C91.7,27.2,91.7,27,91.7,26.8z x' // arrowhead
            + 'F M4.5,29.1c-0.8,1.1-2.4,1.4-3.5,0.5c-1.1-0.8-1.4-2.4-0.5-3.5c0.8-1.1,2.4-1.4,3.5-0.5C5.1,26.4,5.4,27.9,4.5,29.1z', // circle
          strokeWidth: 1,
          width: 25,
          height: 10,
        },
        new go.Binding('stroke', '', (m: AllDiagramSettings) => m.dependency.borderColor).ofModel(),
        new go.Binding('fill', '', (m: AllDiagramSettings) => m.dependency.borderColor).ofModel()),
      $(
        go.TextBlock, // the lane label
        {
          text: 'Dependency',
          editable: false,
          margin: new go.Margin(2, 10, 0, 5),
          font: 'normal 14px DIN Next LT Pro',
        },
      ),
    ),
  );
}

export function legendLinksTemplate(desiredSize: go.Size) {
  return $(
    go.Part, 'Table',
    { desiredSize },
    $(go.Panel, 'TableRow',
      { row: 1 },
      legendConnectionTemplate(),
      legendDependencyTemplate()),
  );
}
