<template>
  <q-btn
    flat
    dense
    no-caps
    size="xs"
    icon="settings_overscan"
  >
    <q-menu
      fit
      anchor="top left"
      self="bottom left"
      :offset="[0, 10]"
      @show="show"
      @hide="hide"
    >
      <div :id="overviewDivId" class="overview"></div>
    </q-menu>
  </q-btn>
</template>

<script>
import { createOverview, destroyOverview } from '@/bridge/overview/Overview';

export default {
  name: 'DiagramOverview',
  computed: {
    diagramDivId() {
      return this.$store.getters['diagramModule/diagramDivId'];
    },
  },
  data() {
    return {
      overviewDivId: 'diagramOverview',
    };
  },
  methods: {
    show() {
      this.$nextTick(() => {
        createOverview(this.diagramDivId, this.overviewDivId);
      });
    },
    hide() {
      destroyOverview(this.overviewDivId);
    },
  },
  beforeRouteLeave(to, from, next) {
    destroyOverview(this.overviewDivId);
    next();
  },
};
</script>

<style>
.overview {
  width: 250px;
  height: 150px;
  max-width: 300px;
}
</style>
