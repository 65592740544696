
































































import Vue, { PropType, VueConstructor } from 'vue';
import { FormWrapperRequest } from '@/types/form';
import { DataForm } from '@/types/dataForm';
import {
  Resource,
  ResourceWithData,
  AddResourceData,
} from '@/types/resource';
import formSubmit from '@/mixins/formSubmit';
import { transformRequest } from '@/views/resources/resourceData/transformRequest';
import { FormContentEditValueEvent } from '@/views/forms/formContentEvents';
import notify from '@/util/notify';
import ApiService from '@/services/api.service';
import SelectForm from '@/views/forms/SelectForm.vue';
import EditBtn from '@/components/buttons/EditBtn.vue';
import Btn from '@/components/buttons/Btn.vue';
import ApiError from '@/components/ApiError.vue';
import AppModal from '@/components/AppModal.vue';
import SelectModellingState from '@/views/projects/modellingState/SelectModellingState.vue';
import InfoBanner from '@/components/banners/InfoBanner.vue';
import ConfirmRemoveForm from '@/views/resources/resourceData/ConfirmRemoveForm.vue';
import ResourceFields from '@/views/resources/resourceData/ResourceFields.vue';

export default (Vue as VueConstructor<Vue & InstanceType<typeof formSubmit>>).extend({
  name: 'ResourceDataForm',
  components: {
    ResourceFields,
    ConfirmRemoveForm,
    InfoBanner,
    Btn,
    AppModal,
    ApiError,
    EditBtn,
    SelectForm,
    SelectModellingState,
  },
  mixins: [formSubmit],
  props: {
    // pass the asset prop if you want to add data
    resource: Object as PropType<Resource>,
    // pass the resourceData prop if you want to update data
    resourceData: Object as PropType<ResourceWithData>,
  },
  computed: {
    resourceId(): string {
      if (this.resource) {
        return this.resource.id;
      }
      if (this.resourceData) {
        return this.resourceData.asset.id;
      }
      return this.$route.params.id;
    },
    isEditForm(): boolean {
      return (this.resourceData && !!this.resourceData.asset);
    },
    title(): string {
      return this.isEditForm ? 'Edit data' : 'Add data';
    },
    request(): FormWrapperRequest {
      const { projectId } = this.$route.params;

      const req: FormWrapperRequest = {
        config: {
          data: this.requestData,
          method: 'post',
          url: `/project/${projectId}/asset/${this.resourceId}/data`,
        },
      };

      if (this.isEditForm && req.config) {
        req.config.method = 'put';
      }

      return req;
    },
  },
  data() {
    return {
      usingExistingState: false,
      selectedForms: [] as string[],
      existingForms: [] as string[],
      // we need an extra object that will
      // contain the transformed request
      requestData: {},
      model: {} as AddResourceData,
    };
  },
  methods: {
    onInput(e: FormContentEditValueEvent) {
      if (!this.model.forms) return;

      const form = this.model.forms[e.formId];
      if (!form || !form.groups) return;
      const group = form.groups[e.groupIndex];
      if (!group || !group.fields) return;
      const field = group.fields[e.fieldIndex];
      if (!field) return;
      field.value = e.value;
    },
    async open() {
      if (this.resourceData) {
        const forms: Record<string, DataForm> = {};
        this.resourceData.forms.forEach((form) => {
          forms[form.id] = form;
          this.existingForms.push(form.id);
        });

        this.model = {
          forms,
          modellingStateId: this.resourceData.modellingState.id,
          asset: { ...this.resourceData.asset },
          remove: [],
        };
        this.selectedForms = Object.keys(this.model.forms);
      } else {
        this.model = {
          forms: {},
          modellingStateId: '',
          asset: { ...this.resource },
          remove: [],
        };
      }

      (this.$refs.modal as any).open();
    },
    async onSubmit() {
      this.error = '';

      this.requestData = transformRequest(this.model);
      await this.submit(this.request, this.$refs.form);
      if (this.hasError) {
        const el = document.querySelector('.q-dialog .app-card');
        if (el) {
          el.scrollTo({ top: 0, behavior: 'smooth' });
        }

        return;
      }

      this.$emit('submit', this.model.modellingStateId);
      (this.$refs.modal as any).close();
      notify.success(`Data ${this.isEditForm ? 'edited' : 'added'}`);
    },
    resetModel() {
      this.model = {} as AddResourceData;
      this.requestData = {};
      this.selectedForms = [] as string[];
      this.usingExistingState = false;
    },
    async loadDataByModellingState(modellingStateId: string) {
      this.usingExistingState = false;

      const { projectId } = this.$route.params;
      const response = await ApiService
        .get(`/project/${projectId}/assets/${this.resourceId}/states/info`);
      if (response.results?.length) {
        const id = response.results
          .filter((ms: { id: string }) => ms.id === modellingStateId)[0]?.id;
        if (id) {
          const resourceData: any = await ApiService
            .get(`/project/${projectId}/assets/${this.resourceId}/states/${id}`);
          if (resourceData) {
            const forms: Record<string, DataForm> = {};
            resourceData.forms.forEach((form: DataForm) => {
              forms[form.id] = form;
            });

            this.model = {
              forms,
              modellingStateId: resourceData.modellingState.id,
              asset: { ...resourceData.asset },
              remove: [],
            };
            this.selectedForms = Object.keys(this.model.forms);
            this.usingExistingState = true;
          }
        }
      }
    },
    async onSelectForm(details: { index: number; value: string; }) {
      this.loading = true;

      const form = await ApiService.get(`/project/${this.$route.params.projectId}/forms/${details.value}`);
      const removeIndex = this.model.remove.indexOf(details.value);

      // In case you have a form, you remove it, then add it back
      if (removeIndex > -1) {
        this.model.remove.splice(removeIndex, 1);
      }

      if (!this.model.forms[form.id]) {
        this.$set(this.model.forms, form.id, form);
      }
      this.loading = false;
    },
    onRemoveForm(details: { index: number; value: string; }) {
      (this.$refs.confirmRemoveForm as any).open(details);
    },
    onConfirm(details: { index: number; value: string; }) {
      delete this.model.forms[details.value];
      this.$forceUpdate();
      if (this.isEditForm && this.existingForms.includes(details.value)) {
        this.model.remove.push(details.value);
      }
    },
    onCancel(details: { index: number; value: string; }) {
      this.selectedForms.splice(details.index, 1, details.value);
    },
  },
});
