import * as go from 'gojs';
import { layerUtils } from '@/bridge/base/layerInfo';
import { NodeCategory } from '@/bridge/enums/partCategories';
import { legendTextTemplate } from '@/bridge/legend/legendTextTemplate';
import { legendNodeTemplate } from '@/bridge/legend/legendNodeTemplate';
import { legendLinksTemplate } from '@/bridge/legend/legendLinkTemplate';
import { legendCalloutTemplate } from '@/bridge/legend/legendCalloutTemplate';
import { DiagramMode } from '@/bridge/enums/diagramOptions';

const $ = go.GraphObject.make;

export enum LegendCategory {
  TEXT = 'text',
  PRESET = 'preset',
  LINKS = 'links',
  CALLOUT = 'callout',
}

type LegendNodeData = {
  label: string;
  font?: string;
  category: LegendCategory|NodeCategory;
  [key: string]: any;
};

export function destroyLegend(legend: go.Diagram) {
  legend.div = null;
  legend.clear();
}

export default function createLegend(legendDivId: string) {
  const legend = $(
    go.Palette,
    legendDivId,
    {
      'animationManager.isEnabled': false,
      contentAlignment: go.Spot.TopLeft,
      padding: new go.Margin(10, 50),
      allowDragOut: false,
      allowZoom: false,
      allowSelect: false,
      initialAutoScale: go.Diagram.Uniform,
      layout: $(go.GridLayout,
        {
          alignment: go.GridLayout.LeftToRight,
          sorting: go.GridLayout.Ascending,
          wrappingColumn: 1,
        }),
    },
  );

  const legendWidth = 220;
  const nodeSize = new go.Size(legendWidth, 30);
  const legendSize = new go.Size(legendWidth, NaN);

  legend.nodeTemplateMap.add(NodeCategory.LAYER, legendNodeTemplate(nodeSize, true));
  legend.nodeTemplateMap.add(LegendCategory.PRESET, legendNodeTemplate(nodeSize));
  legend.nodeTemplateMap.add(LegendCategory.LINKS, legendLinksTemplate(legendSize));
  legend.nodeTemplateMap.add(LegendCategory.TEXT, legendTextTemplate(legendSize));
  legend.nodeTemplateMap.add(LegendCategory.CALLOUT, legendCalloutTemplate());

  return legend;
}

function getFontString(size = '14', weight: 'normal'|'bold' = 'normal', style: 'normal'|'italic' = 'normal') {
  return `${weight} ${style} ${size}px DIN Next LT Pro`;
}

export function addLegendModel(legend: go.Diagram, diagramDivId: string, mode: DiagramMode) {
  const diagram = go.Diagram.fromDiv(diagramDivId);
  if (!diagram) return;

  legend.clear();

  const titleStyle = {
    margin: new go.Margin(20, 0, 0, 0),
    font: getFontString('18', 'bold'),
    category: LegendCategory.TEXT,
  };

  const layers: LegendNodeData[] = layerUtils.layers.map((ln, index) => ({
    id: index,
    label: ln,
    font: getFontString(),
    category: NodeCategory.LAYER,
  }));

  const presets: LegendNodeData[] = [];

  const { nodePresets } = diagram.model.modelData;
  if (nodePresets) {
    Object.keys(nodePresets).forEach((presetId) => {
      const preset = nodePresets[presetId];
      if (!preset) return;

      const nodesUsingPreset = diagram.findNodesByExample({ preset: preset.id });

      if (nodesUsingPreset.count && preset.showOnLegend && preset.color) {
        presets.push({
          label: nodePresets[presetId].label,
          font: getFontString('14', 'normal'),
          category: LegendCategory.PRESET,
          preset: presetId,
        });
      }
    });
  }

  if (presets.length) {
    presets.unshift({
      ...titleStyle,
      label: 'Presets',
    });
  }

  let callouts: LegendNodeData[] = [];
  if (mode === DiagramMode.REVIEW) {
    callouts = [
      {
        ...titleStyle,
        label: 'Callouts',
      },
      {
        label: 'Your Callouts',
        color: 'myCallout',
        category: LegendCategory.CALLOUT,
      },
      {
        label: 'Other Callouts',
        color: 'active',
        category: LegendCategory.CALLOUT,
      },
      {
        label: 'Resolved Comments',
        color: 'approved',
        category: LegendCategory.CALLOUT,
      },
    ];
  }

  legend.model = $(go.GraphLinksModel, {
    modelData: diagram.model.modelData,
    nodeDataArray: [
      {
        label: 'Diagram Legend',
        font: getFontString('25', 'bold'),
        category: LegendCategory.TEXT,
      },
      {
        ...titleStyle,
        label: 'Layers',
      },
      ...layers,
      {
        ...titleStyle,
        label: 'Links',
      },
      {
        label: 'Connection',
        font: getFontString(),
        category: LegendCategory.LINKS,
      },
      ...presets,
      ...callouts,
    ],
  });
}

export function initLegend(legendDivId: string, diagramDivId: string, mode: DiagramMode) {
  const legend = createLegend(legendDivId);
  addLegendModel(legend, diagramDivId, mode);

  legend.scale = 1;

  return legend;
}
