










import Vue, { PropType } from 'vue';
import DeleteModal from '@/components/DeleteModal.vue';
import { ResourceType } from '@/types/resource';

export default Vue.extend({
  name: 'DeleteResource',
  components: { DeleteModal },
  props: {
    resourceType: {
      type: String as PropType<ResourceType>,
      required: true,
    },
  },
  computed: {
    orgId(): string {
      return this.$route.params.orgId;
    },
    projectId(): string {
      return this.$route.params.projectId;
    },
    url(): string {
      return `/project/${this.projectId}/asset/remove`;
    },
  },
  methods: {
    async onDelete() {
      let path = 'assets';
      if (this.resourceType === ResourceType.CONNECTION) {
        path = 'connections';
      }
      if (this.resourceType === ResourceType.DEPENDENCY) {
        path = 'dependencies';
      }

      await this.$router.replace(`/org/${this.orgId}/project/${this.projectId}/${path}`);
    },
  },
});
