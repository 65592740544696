<template>
  <q-btn flat icon="delete">
    <q-menu ref="menu">
      <app-card title="Are you sure?">
        <template #actions>
          <q-space/>
          <q-btn
            flat
            round
            dense
            size="sm"
            icon="close"
            @click="hideMenu"
          />
        </template>

        <div>
          <p v-if="!isField" class="text-italic">
            This will delete the group and any fields it might contain.
          </p>
          <small class="text-caption">This action cannot be undone</small>
        </div>

        <template #footer>
          <q-space/>
          <Btn
            flat
            label="Cancel"
            class="q-mr-md"
            @click="hideMenu"
          />
          <Btn
            color="primary"
            label="Delete"
            @click="confirmDelete"
          />
        </template>
      </app-card>
    </q-menu>
  </q-btn>
</template>

<script>
import Btn from '@/components/buttons/Btn.vue';
import AppCard from '@/components/card/AppCard.vue';

export default {
  name: 'DeleteFormElement',
  components: {
    Btn,
    AppCard,
  },
  props: {
    isField: Boolean,
  },
  methods: {
    hideMenu() {
      this.$refs.menu.hide();
    },
    confirmDelete() {
      this.$emit('delete');
      this.hideMenu();
    },
  },
};
</script>
