



























import Vue, { PropType } from 'vue';
import { ElementData, LinkData } from '@/bridge/types/diagramModel';
import LayerBadge from '@/components/LayerBadge.vue';
import ViewResourceModal from '@/views/resources/ViewResourceModal.vue';
import { LinkCategory, NodeCategory } from '@/bridge/enums/partCategories';

export default Vue.extend({
  name: 'PreviewDavResource',
  components: {
    ViewResourceModal,
    LayerBadge,
  },
  props: {
    withAvatar: Boolean,
    resource: {
      type: Object as PropType<ElementData|LinkData>,
      required: true,
    },
  },
  computed: {
    isElement(): boolean {
      return this.resource.category === NodeCategory.ELEMENT;
    },
    viewPath(): string {
      const { category, id } = this.resource;
      const { orgId, projectId } = this.$route.params;
      if (this.isElement) {
        return `/org/${orgId}/project/${projectId}/asset/${id}`;
      }
      return `/org/${orgId}/project/${projectId}/${category}/${id}`;
    },
    avatar(): { icon: string; color: string; } {
      if (this.resource.category === NodeCategory.ELEMENT) {
        return {
          icon: 'square',
          color: 'primary',
        };
      }

      if (this.resource.category === LinkCategory.DEPENDENCY) {
        return {
          icon: 'arrow_downward',
          color: 'red',
        };
      }

      return {
        icon: 'arrow_downward',
        color: 'primary',
      };
    },
  },
});
