import * as go from 'gojs';
import { ShapeCategory } from '@/bridge/enums/partCategories';
import { nodeStyle } from '@/bridge/node/nodeStyle';
import { stayOnDiagram } from '@/bridge/shapes/stayOnDiagram';
import { templateFn } from '@/bridge/types/diagramModel';
import {
  shapeMap,
  shapeColor,
  shapeSettings,
  getAvailableShapes,
} from '@/bridge/settings/shapeSettings';
import { toGoSize, toNodeSize } from '@/bridge/util/shared';
import { getTextBindings } from '@/bridge/util/textBindings';

const $ = go.GraphObject.make;

function getShapeTextBlock() {
  return $(go.TextBlock, getTextBindings());
}

function textTemplate(diagram: go.Diagram) {
  return $(
    go.Node,
    go.Panel.Viewbox,
    nodeStyle(diagram),
    {
      dragComputation: stayOnDiagram,
    },
    $(
      go.Panel,
      'Auto',
      {
        name: 'SHAPE',
        padding: 10,
      },
      new go.Binding('desiredSize', 'size', toGoSize).makeTwoWay(toNodeSize),
      new go.Binding(
        'background',
        '',
        (_, part) => shapeColor(part.part).backgroundColor,
      ).ofModel(),
      getShapeTextBlock(),
    ),
  );
}

function shapeTemplate(diagram: go.Diagram, shape: ShapeCategory) {
  return $(
    go.Node,
    'Vertical',
    nodeStyle(diagram),
    {
      dragComputation: stayOnDiagram,
    },
    $(
      go.Panel,
      'Auto',
      { name: 'SHAPE' },
      new go.Binding('desiredSize', 'size', toGoSize).makeTwoWay(toNodeSize),
      $(
        go.Shape,
        'Square',
        {
          margin: 5,
          geometry: go.Geometry.parse(shapeMap[shape].geometry as string, true),
        },
        new go.Binding(
          'fill',
          '',
          (_, part) => shapeColor(part.part).backgroundColor,
        ).ofModel(),
        new go.Binding(
          'stroke',
          '',
          (_, part) => shapeColor(part.part).borderColor,
        ).ofModel(),
        new go.Binding(
          'strokeWidth',
          '',
          (_, part) => shapeSettings(part.part).borderWidth,
        ),
      ),
    ),
    getShapeTextBlock(),
  );
}

const addTemplate: templateFn = (diagram: go.Diagram) => {
  getAvailableShapes().forEach((shape) => {
    if (shape === ShapeCategory.TEXT) {
      diagram.nodeTemplateMap.add(shape, textTemplate(diagram));
    } else {
      diagram.nodeTemplateMap.add(shape, shapeTemplate(diagram, shape));
    }
  });
};

export default {
  addTemplate,
};
