import * as go from 'gojs';
import LinkTemplateBuilder, { addPreferencesBindings } from '@/bridge/link/LinkTemplateBuilder';
import { LinkCategory, NodeCategory } from '@/bridge/enums/partCategories';
import { AllDiagramSettings, templateFn } from '@/bridge/types/diagramModel';
import { DiagramMode } from '@/bridge/enums/diagramOptions';
import { reverseDependency, createDependency } from '@/bridge/dependency/dependencyCommands';
import dependencyImg from '@/assets/images/dependency.png';

const $ = go.GraphObject.make;

function getFromTo(diagram: go.Diagram): go.Node[] {
  const nodes: go.Node[] = [];

  // Extract only elements from the selection.
  // This way, the users can select two elements and
  // all the layers or shapes, and they will still be able
  // to create a dependency.
  diagram.selection.each((p) => {
    if (p.category === NodeCategory.ELEMENT) {
      nodes.push(p as go.Node);
    }
  });

  // The selection can contain any number of nodes/links
  // but only two elements.
  if (nodes.length !== 2) return [];

  const [firstNode, lastNode] = nodes;

  const existingDependency = diagram.findLinksByExample({
    category: LinkCategory.DEPENDENCY,
    from: firstNode.key,
    to: lastNode.key,
  }).first();

  if (existingDependency) return [];

  return [firstNode, lastNode];
}

function maybeShowCreateDependencyBtn(diagram: go.Diagram) {
  const [from, to] = getFromTo(diagram);

  if (from && to) {
    const adornment = $(go.Adornment, 'Spot',
      $(go.Placeholder,
        // to allow this Placeholder to be "seen" by mouse events
        { background: 'transparent' }),
      $('Button',
        {
          alignment: new go.Spot(1, 0.5, 10),
          alignmentFocus: go.Spot.Left,
        },
        { click(e) { createDependency(e.diagram, getFromTo(e.diagram)); } },
        $(go.Picture, dependencyImg, { width: 20, height: 20 })));
    adornment.adornedObject = to;
    to.addAdornment('createDependency', adornment);
  }
}

function circleTemplate() {
  return $(
    go.Shape,
    {
      fromArrow: 'Circle',
      stroke: null,
      segmentOffset: new go.Point(-1, 0),
    },
    addPreferencesBindings(LinkCategory.DEPENDENCY, []),
  );
}

const addTemplate: templateFn = (diagram: go.Diagram, options) => {
  if (!options) return;

  const linkBuilder = new LinkTemplateBuilder(go.Link, options, true);
  const dependencyTemplate = linkBuilder
    .addOptions([
      {
        curve: go.Link.Bezier,
        reshapable: true,
        adjusting: go.Link.Scale,
        contextMenu: $('ContextMenu',
          $('ContextMenuButton',
            {
              padding: 10,
              click(e: go.InputEvent, obj) {
                const adornment = obj.part as go.Adornment;
                if (!adornment.adornedObject) return;

                reverseDependency(e.diagram, adornment.adornedPart?.data);
              },
            },
            $(go.TextBlock, 'Reverse',
              {
                font: 'bold 10pt sans-serif',
                desiredSize: new go.Size(65, 25),
                textAlign: 'center',
                verticalAlignment: go.Spot.Center,
              }))),
        click: (e: go.InputEvent, link: go.GraphObject) => {
          diagram.clearHighlighteds();

          if (options.mode !== DiagramMode.MODEL) {
            return;
          }

          const { fromNode, toNode } = link as go.Link;

          if (fromNode) {
            fromNode.isHighlighted = !fromNode.isHighlighted;
          }
          if (toNode) {
            toNode.isHighlighted = !toNode.isHighlighted;
          }
        },
      },
      new go.Binding('visible', '', (m: AllDiagramSettings) => m.dependency.visible).ofModel(),
    ])
    .addLine(
      LinkCategory.DEPENDENCY,
      [
        new go.Binding('stroke', '', (m: AllDiagramSettings, l: go.Part) => {
          if (l.part?.isHighlighted) {
            return m.diagram.highlightColor;
          }
          return m.dependency.borderColor;
        }).ofModel(),
      ],
    )
    .addParts([circleTemplate()])
    .addArrow(
      LinkCategory.DEPENDENCY,
      [
        new go.Binding('stroke', '', (m: AllDiagramSettings, l: go.Part) => {
          if (l.part?.isHighlighted) {
            return m.diagram.highlightColor;
          }
          return m.dependency.borderColor;
        }).ofModel(),
      ],
    )
    .get();

  diagram.linkTemplateMap.add(LinkCategory.DEPENDENCY, dependencyTemplate);
};

function createLinkData(from: go.Part, to: go.Part): go.ObjectData {
  return {
    fromSpot: go.Spot.stringify(go.Spot.Right),
    toSpot: go.Spot.stringify(go.Spot.Right),
    category: LinkCategory.DEPENDENCY,
    from: from.key,
    to: to.key,
  };
}

function createReverseLinkData(linkData: go.ObjectData): go.ObjectData {
  return {
    ...linkData,
    to: linkData.from,
    from: linkData.to,
  };
}

function draw(diagram: go.Diagram, visible = true): void {
  const [from, to]: go.Part[] = getFromTo(diagram);
  if (!from || !to) {
    return;
  }

  const linkData = createLinkData(from, to);

  diagram.model.commit((m) => {
    (m as go.GraphLinksModel).addLinkData(linkData);

    const link = diagram.findLinkForData(linkData);

    if (link) {
      link.visible = visible;
    }
    diagram.clearSelection();
  }, 'CreateDependency');
}

function reverse(diagram: go.Diagram, linkData: go.ObjectData): void {
  const link: go.Link | null = diagram.findLinkForData(linkData);
  if (!link || !link.fromNode || !link.toNode) {
    return;
  }

  const newLinkData = createReverseLinkData(linkData);

  diagram.model.commit((m) => {
    diagram.remove(link);
    (m as go.GraphLinksModel).addLinkData(newLinkData);
  }, 'ReverseDependency');
}

export default {
  addTemplate,
  draw,
  reverse,
  maybeShowCreateDependencyBtn,
};
