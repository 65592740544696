import * as go from 'gojs';
import { isTopLevelCategory, reLayoutDiagram } from '@/bridge/util/shared';
import { isObashiLayer } from '@/bridge/settings/layerSettings';

function isMovable(p: go.Part) {
  return !(p instanceof go.Link) && !isTopLevelCategory(p.category);
}

function getFirstMovable(diagram: go.Diagram) {
  return diagram.selection.filter(isMovable).first();
}

function isDifferentLayer(first: go.Part, current: go.Part) {
  if (!(first instanceof go.Node) || !(current instanceof go.Node)) {
    throw new Error('First and current should be of type go.Node');
  }

  const firstLayer = first.data.group;
  const currentLayer = current.data.group;

  // Don't compare shapes with elements
  if (!isObashiLayer(firstLayer) || !isObashiLayer(currentLayer)) {
    return false;
  }

  return firstLayer !== currentLayer;
}

function canAlignVertically(first: go.Part, current: go.Part) {
  if (current instanceof go.Link) {
    return false;
  }

  const differentLayer = isDifferentLayer(first, current);

  return !(isTopLevelCategory(current.category) || differentLayer);
}

function canAlignHorizontally(current: go.Part) {
  if (current instanceof go.Link) {
    return false;
  }

  return !(isTopLevelCategory(current.category));
}

function getInitialPasteOffset(instance: go.CommandHandler): go.Point {
  let x = 10;
  let y = 10;

  if (instance.diagram) {
    x = instance.diagram.grid.gridCellSize.width;
    y = instance.diagram.grid.gridCellSize.height;
  }

  return new go.Point(x, y);
}

export class DrawCommandHandler extends go.CommandHandler {
  private _arrowKeyBehavior = 'move';
  private _pasteOffset: go.Point = getInitialPasteOffset(this);
  private _lastPasteOffset: go.Point = new go.Point(0, 0);

  /**
   * Gets or sets the arrow key behavior. Possible values are "move", "select", and "scroll".
   *
   * The default value is "move".
   */
  get arrowKeyBehavior(): string { return this._arrowKeyBehavior; }
  set arrowKeyBehavior(val: string) {
    if (val !== 'move' && val !== 'select' && val !== 'scroll' && val !== 'none') {
      throw new Error(`DrawCommandHandler.arrowKeyBehavior must be either "move", "select", "scroll", or "none", not: ${val}`);
    }
    this._arrowKeyBehavior = val;
  }

  /**
   * Gets or sets the offset at which each repeated {@link #pasteSelection}
   * puts the new copied parts from the clipboard.
   */
  get pasteOffset(): go.Point { return this._pasteOffset; }
  set pasteOffset(val: go.Point) {
    if (!(val instanceof go.Point)) throw new Error(`DrawCommandHandler.pasteOffset must be a Point, not: ${val}`);
    this._pasteOffset.set(val);
  }

  /**
   * This controls whether the user can invoke the {@link #alignLeft}, {@link #alignRight},
   * {@link #alignTop}, {@link #alignBottom}, {@link #alignCenterX}, {@link #alignCenterY} commands.
   * @return {boolean} This returns true:
   *                   if the diagram is not {@link go.Diagram#isReadOnly},
   *                   if the model is not {@link go.Model#isReadOnly}, and
   *                   if there are at least two selected {@link go.Part}s.
   */
  public canAlignSelection(): boolean {
    const { diagram } = this;

    if (diagram.isReadOnly || diagram.isModelReadOnly) return false;
    return diagram.selection.count >= 2;
  }

  /**
   * Aligns selected parts along the left-most edge of the left-most part.
   */
  public alignLeft(): void {
    const { diagram } = this;
    const firstSelection = getFirstMovable(diagram);
    if (!firstSelection) return;

    diagram.startTransaction('aligning left');
    diagram.selection.each((current) => {
      if (!canAlignHorizontally(current) || current.key === firstSelection.key) {
        return;
      }
      current.move(new go.Point(firstSelection.position.x, current.position.y));
    });
    reLayoutDiagram(this.diagram);
    diagram.commitTransaction('aligning left');
  }

  /**
   * Aligns selected parts at the right-most edge of the right-most part.
   */
  public alignRight(): void {
    const { diagram } = this;
    const firstSelection = getFirstMovable(diagram);
    if (!firstSelection) return;

    const maxPosition = firstSelection.actualBounds.x + firstSelection.actualBounds.width;

    diagram.startTransaction('aligning right');
    diagram.selection.each((current) => {
      if (!canAlignHorizontally(current) || current.key === firstSelection.key) {
        return;
      }

      current.move(new go.Point(maxPosition - current.actualBounds.width, current.position.y));
    });
    reLayoutDiagram(this.diagram);
    diagram.commitTransaction('aligning right');
  }

  /**
   * Aligns selected parts at the top-most edge of the top-most part.
   */
  public alignTop(): void {
    const { diagram } = this;
    const firstSelection = getFirstMovable(diagram);
    if (!firstSelection) return;

    diagram.startTransaction('alignTop');
    const top = firstSelection.actualBounds.y;

    diagram.selection.each((current) => {
      if (!canAlignVertically(firstSelection, current)) {
        return;
      }

      current.move(new go.Point(current.actualBounds.x, top));
    });
    reLayoutDiagram(this.diagram);
    // this.diagram.commandHandler.scrollToPart(firstSelection);
    diagram.commitTransaction('alignTop');
  }

  /**
   * Aligns selected parts at the bottom-most edge of the bottom-most part.
   */
  public alignBottom(): void {
    const { diagram } = this;
    const firstSelection = getFirstMovable(diagram);
    if (!firstSelection) return;

    diagram.startTransaction('aligning bottom');
    const bottom = firstSelection.actualBounds.y + firstSelection.actualBounds.height;

    diagram.selection.each((current) => {
      if (!canAlignVertically(firstSelection, current)) {
        return;
      }

      const currentHeight = current.actualBounds.height;

      current.move(new go.Point(current.actualBounds.x, bottom - currentHeight));
    });
    reLayoutDiagram(this.diagram);
    // this.diagram.commandHandler.scrollToPart(firstSelection);
    diagram.commitTransaction('aligning bottom');
  }

  /**
   * Aligns selected parts at the x-value of the center point of the first selected part.
   */
  public alignCenterX(): void {
    const { diagram } = this;
    const firstSelection = getFirstMovable(diagram);
    if (!firstSelection) return;

    const centerX = firstSelection.actualBounds.x + firstSelection.actualBounds.width / 2;

    diagram.startTransaction('aligning Center X');
    diagram.selection.each((current) => {
      if (!canAlignHorizontally(current) || current.key === firstSelection.key) {
        return;
      }

      const currentWidth = current.actualBounds.width / 2;

      current.move(new go.Point(centerX - currentWidth, current.actualBounds.y));
    });
    reLayoutDiagram(this.diagram);
    diagram.commitTransaction('aligning Center X');
  }

  /**
   * Aligns selected parts at the y-value of the center point of the first selected part.
   */
  public alignCenterY(): void {
    const { diagram } = this;
    const firstSelection = getFirstMovable(diagram);
    if (!firstSelection) return;

    diagram.startTransaction('aligning Center Y');
    const centerY = firstSelection.actualBounds.y + firstSelection.actualBounds.height / 2;

    diagram.selection.each((current) => {
      if (!canAlignVertically(firstSelection, current)) {
        return;
      }

      const currentHeight = current.actualBounds.height / 2;
      current.move(new go.Point(current.actualBounds.x, centerY - currentHeight));
    });
    reLayoutDiagram(this.diagram);
    diagram.commitTransaction('aligning Center Y');
  }

  /**
   * Aligns selected parts top-to-bottom in order of the order selected.
   * Distance between parts can be specified. Default distance is 0.
   */
  public alignColumn(distance: number): void {
    const { diagram } = this;
    const firstSelection = getFirstMovable(diagram);
    if (!firstSelection) return;

    let dist = distance;
    diagram.startTransaction('align Column');
    if (dist === undefined) dist = 0; // for aligning edge to edge
    dist = parseFloat(dist.toString());
    const selectedParts: go.Part[] = [];
    diagram.selection.each((current) => {
      if (!canAlignVertically(firstSelection, current)) return;
      selectedParts.push(current);
    });

    for (let i = 0; i < selectedParts.length - 1; i += 1) {
      const current = selectedParts[i];
      // adds distance specified between parts
      const curBottomSideLoc = current.actualBounds.y + current.actualBounds.height + dist;
      const next = selectedParts[i + 1];
      next.move(new go.Point(current.actualBounds.x, curBottomSideLoc));
    }
    reLayoutDiagram(this.diagram);
    diagram.commitTransaction('align Column');
  }

  /**
   * Aligns selected parts left-to-right in order of the order selected.
   * Distance between parts can be specified. Default distance is 0.
   */
  public alignRow(distance: number): void {
    let dist = distance;
    if (dist === undefined) dist = 0; // for aligning edge to edge
    dist = parseFloat(dist.toString());
    const { diagram } = this;
    diagram.startTransaction('align Row');
    const selectedParts: go.Part[] = [];
    diagram.selection.each((current) => {
      if (!canAlignHorizontally(current)) return;

      selectedParts.push(current);
    });

    for (let i = 0; i < selectedParts.length - 1; i += 1) {
      const current = selectedParts[i];
      // adds distance specified between parts
      const curRightSideLoc = current.actualBounds.x + current.actualBounds.width + dist;
      const next = selectedParts[i + 1];
      next.move(new go.Point(curRightSideLoc, current.actualBounds.y));
    }
    reLayoutDiagram(this.diagram);
    diagram.commitTransaction('align Row');
  }

  /**
   * Change the z-ordering of selected parts to pull them forward, in front of all other parts
   * in their respective layers.
   * All unselected parts in each layer with a selected Part with a
   * non-numeric {@link go.Part#zOrder} will get a zOrder of zero.
   * @this {DrawCommandHandler}
   */
  public pullToFront(): void {
    const { diagram } = this;
    diagram.startTransaction('pullToFront');
    // find the affected Layers
    const layers = new go.Map<go.Layer, number>();
    diagram.selection.filter(isMovable).each((part) => {
      if (part.layer !== null) layers.set(part.layer, 0);
    });
    // find the maximum zOrder in each Layer
    layers.iteratorKeys.each((layer) => {
      let max = 0;
      layer.parts.each((part) => {
        if (part.isSelected) return;
        const z = part.zOrder;
        if (Number.isNaN(z)) {
          part.zOrder = 0;
        } else {
          max = Math.max(max, z);
        }
      });
      layers.set(layer, max);
    });
    // assign each selected Part.zOrder to the computed value for each Layer
    diagram.selection.each((part) => {
      const z = layers.get(part.layer as go.Layer) || 0;
      DrawCommandHandler._assignZOrder(part, z + 1);
    });
    reLayoutDiagram(this.diagram);
    diagram.commitTransaction('pullToFront');
  }

  /**
   * Change the z-ordering of selected parts to push them backward, behind of all other parts
   * in their respective layers.
   * All unselected parts in each layer with a selected Part with a
   * non-numeric {@link go.Part#zOrder} will get a zOrder of zero.
   * @this {DrawCommandHandler}
   */
  public pushToBack(): void {
    const { diagram } = this;
    diagram.startTransaction('pushToBack');
    // find the affected Layers
    const layers = new go.Map<go.Layer, number>();
    diagram.selection.filter(isMovable).each((part) => {
      if (part.layer !== null) layers.set(part.layer, 0);
    });
    // find the minimum zOrder in each Layer
    layers.iteratorKeys.each((layer) => {
      let min = 0;
      layer.parts.each((part) => {
        if (part.isSelected) return;
        const z = part.zOrder;
        if (Number.isNaN(z)) {
          part.zOrder = 0;
        } else {
          min = Math.min(min, z);
        }
      });
      layers.set(layer, min);
    });
    // assign each selected Part.zOrder to the computed value for each Layer
    diagram.selection.each((part) => {
      const z = layers.get(part.layer as go.Layer) || 0;
      DrawCommandHandler._assignZOrder(part,
        // make sure a group's nested nodes are also behind everything else
        z - 1 - DrawCommandHandler._findGroupDepth(part));
    });
    reLayoutDiagram(this.diagram);
    diagram.commitTransaction('pushToBack');
  }

  private static _assignZOrder(part: go.Part, z: number, root?: go.Part): void {
    let tmp = root;
    if (!tmp) tmp = part;
    if (part.layer === tmp.layer) part.zOrder = z;
    if (part instanceof go.Group) {
      part.memberParts.each((m) => {
        DrawCommandHandler._assignZOrder(m, z + 1, tmp);
      });
    }
  }

  private static _findGroupDepth(part: go.Part): number {
    if (part instanceof go.Group) {
      let d = 0;
      part.memberParts.each((m) => {
        d = Math.max(d, DrawCommandHandler._findGroupDepth(m));
      });
      return d + 1;
    }
    return 0;
  }

  /**
   * This implements custom behaviors for arrow key keyboard events.
   * Set {@link #arrowKeyBehavior} to
   * "select", "move" (the default), "scroll" (the standard behavior), or "none"
   * to affect the behavior when the user types an arrow key.
   */
  public doKeyDown(): void {
    const { diagram } = this;
    const e = diagram.lastInput;

    // determines the function of the arrow keys
    if (e.key === 'Up' || e.key === 'Down' || e.key === 'Left' || e.key === 'Right') {
      const behavior = this.arrowKeyBehavior;
      if (behavior === 'none') {
        // no-op
        return;
      }

      if (behavior === 'select') {
        this._arrowKeySelect();
        return;
      }

      if (behavior === 'move') {
        this._arrowKeyMove();
        return;
      }
      // otherwise, drop through to get the default scrolling behavior
    }

    // otherwise still does all standard commands
    super.doKeyDown();
  }

  /**
   * Collects in an Array all the non-Link Parts currently in the Diagram.
   */
  private _getAllParts(): Array<any> {
    const allParts: any[] = [];
    this.diagram.nodes.each((node) => { allParts.push(node); });
    this.diagram.parts.each((part) => { allParts.push(part); });
    // note that this ignores Links
    return allParts;
  }

  /**
   * To be called when arrow keys should move the Diagram.selection.
   */
  private _arrowKeyMove(): void {
    const { diagram } = this;
    const e = diagram.lastInput;
    // moves all selected parts in the specified direction
    let vDistance = 0;
    let hDistance = 0;
    // if control is being held down, move pixel by pixel. Else, moves by grid cell size
    if (e.control || e.meta) {
      vDistance = 1;
      hDistance = 1;
    } else if (diagram.grid !== null) {
      const cellSize = diagram.grid.gridCellSize;
      hDistance = cellSize.width;
      vDistance = cellSize.height;
    }
    diagram.startTransaction('arrowKeyMove');
    diagram.selection.each((part) => {
      if (!isMovable(part)) return;

      if (e.key === 'Up') {
        part.move(new go.Point(part.actualBounds.x, part.actualBounds.y - vDistance));
      } else if (e.key === 'Down') {
        part.move(new go.Point(part.actualBounds.x, part.actualBounds.y + vDistance));
      } else if (e.key === 'Left') {
        part.move(new go.Point(part.actualBounds.x - hDistance, part.actualBounds.y));
      } else if (e.key === 'Right') {
        part.move(new go.Point(part.actualBounds.x + hDistance, part.actualBounds.y));
      }
    });
    reLayoutDiagram(this.diagram);
    diagram.commitTransaction('arrowKeyMove');
  }

  /**
   * To be called when arrow keys should change selection.
   */
  private _arrowKeySelect(): void {
    const { diagram } = this;
    const e = diagram.lastInput;
    // with a part selected, arrow keys change the selection
    // arrow keys + shift selects the additional part in the specified direction
    // arrow keys + control toggles the selection of the additional part
    let nextPart = null;
    if (e.key === 'Up') {
      nextPart = this._findNearestPartTowards(270);
    } else if (e.key === 'Down') {
      nextPart = this._findNearestPartTowards(90);
    } else if (e.key === 'Left') {
      nextPart = this._findNearestPartTowards(180);
    } else if (e.key === 'Right') {
      nextPart = this._findNearestPartTowards(0);
    }
    if (nextPart !== null) {
      if (e.shift) {
        nextPart.isSelected = true;
      } else if (e.control || e.meta) {
        nextPart.isSelected = !nextPart.isSelected;
      } else {
        diagram.select(nextPart);
      }
    }
  }

  /**
   * Finds the nearest Part in the specified direction, based on their center points.
   * if it doesn't find anything, it just returns the current Part.
   * @param {number} dir the direction, in degrees
   * @return {go.Part} the closest Part found in the given direction
   */
  private _findNearestPartTowards(dir: number): go.Part | null {
    const originalPart = this.diagram.selection.first();
    if (originalPart === null) return null;
    const originalPoint = originalPart.actualBounds.center;
    const allParts = this._getAllParts();
    let closestDistance = Infinity;
    let closest = originalPart; // if no parts meet the criteria, the same part remains selected

    for (let i = 0; i < allParts.length; i += 1) {
      const nextPart = allParts[i];
      if (nextPart !== originalPart) {
        const nextPoint = nextPart.actualBounds.center;
        const angle = originalPoint.directionPoint(nextPoint);
        const angleDiff = DrawCommandHandler._angleCloseness(angle, dir);
        if (angleDiff <= 45) { // if this part's center is within the desired direction's sector,
          let distance = originalPoint.distanceSquaredPoint(nextPoint);
          // the more different from the intended angle, the further it is
          distance *= 1 + Math.sin(angleDiff * (Math.PI / 180));
          if (distance < closestDistance) { // and if it's closer than any other part,
            closestDistance = distance; // remember it as a better choice
            closest = nextPart;
          }
        }
      } // skips over currently selected part
    }
    return closest;
  }

  private static _angleCloseness(a: number, dir: number): number {
    return Math.min(Math.abs(dir - a), Math.min(Math.abs(dir + 360 - a), Math.abs(dir - 360 - a)));
  }

  /**
   * Reset the last offset for pasting.
   * @param {Iterable.<go.Part>} coll a collection of {@link go.Part}s.
   */
  public copyToClipboard(coll: go.Iterable<go.Part>): void {
    super.copyToClipboard(coll);
    this._lastPasteOffset.set(this.pasteOffset);
  }

  /**
   * Paste from the clipboard with an offset incremented on each paste, and reset when copied.
   * @return {Set.<go.Part>} a collection of newly pasted {@link go.Part}s
   */
  public pasteFromClipboard(): go.Set<go.Part> {
    const coll = super.pasteFromClipboard();
    this.diagram.moveParts(coll, this._lastPasteOffset, false);
    this._lastPasteOffset.add(this.pasteOffset);

    reLayoutDiagram(this.diagram);
    return coll;
  }

  public undo() {
    super.undo();
    reLayoutDiagram(this.diagram);
  }

  public redo() {
    super.redo();
    reLayoutDiagram(this.diagram);
  }
}
