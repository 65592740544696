







































import Vue, { PropType } from 'vue';
import { AllDiagramSettings } from '@/bridge/types/diagramModel';
import Btn from '@/components/buttons/Btn.vue';
import UseProjectSettingsWarning from '@/views/diagram/UseProjectSettingsWarning.vue';

export default Vue.extend({
  name: 'UpdateDiagramSettingsBanner',
  components: { UseProjectSettingsWarning, Btn },
  props: {
    diagramSettings: {
      type: Object as PropType<AllDiagramSettings>,
      required: true,
    },
    projectSettings: {
      type: Object as PropType<AllDiagramSettings>,
      required: true,
    },
  },
  computed: {
    displayBanner(): boolean {
      // The user clicked on 'Don't show this again'
      if (!this.diagramSettings.meta.showUpdateNotification) {
        return false;
      }

      const { meta: dm } = this.diagramSettings;
      const { meta: pm } = this.projectSettings;
      const sameVersion = dm.globalSettingsVersion === pm.globalSettingsVersion;

      // same globalSettingsVersion - nothing to show
      if (sameVersion) {
        return false;
      }

      // different globalSettingsVersion and the user was notified
      // about another globalSettingsVersion
      return dm.skipVersion !== pm.globalSettingsVersion;
    },
  },
  methods: {
    openConfirmModal() {
      if (this.$refs.useProjectSettingsWarning) {
        (this.$refs.useProjectSettingsWarning as any).open();
      }
    },
    neverShow() {
      this.emitInput({
        ...this.diagramSettings,
        meta: {
          ...this.diagramSettings.meta,
          showUpdateNotification: false,
        },
      });
    },
    skipUpdate() {
      this.emitInput({
        ...this.diagramSettings,
        meta: {
          ...this.diagramSettings.meta,
          skipVersion: this.projectSettings.meta.globalSettingsVersion,
        },
      });
    },
    overwrite() {
      this.emitInput({
        ...this.projectSettings,
        nodePresets: {
          ...this.diagramSettings.nodePresets,
          ...this.projectSettings.nodePresets,
        },
        meta: {
          hasLocalSettings: false,
          skipVersion: this.projectSettings.meta.globalSettingsVersion,
          showUpdateNotification: true,
          globalSettingsVersion: this.projectSettings.meta.globalSettingsVersion,
        },
      });
    },
    emitInput(s: AllDiagramSettings) {
      this.$emit('input', s);
    },
  },
});
